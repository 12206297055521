import { useEffect, useReducer } from 'react'
import axios from 'axios'

import config from '../../../config'
import Project from '../../../lib/Project'
import { s3Keys } from '../../../lib/Utils'

export type ProjectDownloadUrl = {
  project: Project
  presignedUrl: string
  exists: boolean
}

export type State = {
  loading: boolean
  message: {
    isError: boolean
    text: string
  }
  jobsCount: {
    total: number
    translated: number
  }
  projectDownloadUrl: ProjectDownloadUrl | null
}

export type Action =
  | { type: 'SET_IS_LOADING'; payload: { loading: boolean } }
  | { type: 'SET_MESSAGE'; payload: { message: string; isError?: boolean } }
  | { type: 'SET_JOBS_COUNT'; payload: { total?: number; translated?: number } }
  | { type: 'SET_PROJECT_DOWNLOAD_URL'; payload: ProjectDownloadUrl }

export const initialState: State = {
  loading: false,
  message: {
    isError: false,
    text: ''
  },
  jobsCount: {
    total: 0,
    translated: 0
  },
  projectDownloadUrl: null
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_IS_LOADING': {
      return {
        ...state,
        loading: action.payload.loading
      }
    }
    case 'SET_MESSAGE': {
      return {
        ...state,
        message: {
          text: action.payload.message,
          isError: action.payload.isError ?? false
        }
      }
    }
    case 'SET_JOBS_COUNT': {
      return {
        ...state,
        jobsCount: {
          ...state.jobsCount,
          ...action.payload
        }
      }
    }
    case 'SET_PROJECT_DOWNLOAD_URL': {
      return {
        ...state,
        projectDownloadUrl: action.payload
      }
    }
    default: {
      return state
    }
  }
}

const useDownloadTranslatedFilesDialog = (
  project: Project
): { state: State; dispatch: React.Dispatch<Action> } => {
  const [state, dispatch] = useReducer(stateReducer, initialState)

  useEffect(() => {
    const initProjectDownloadUrl = async (): Promise<void> => {
      const objectKey = s3Keys.project.translatedZip(project)
      const presignedUrlResult = await axios.post<{ presignedUrl: string; exists: boolean }>(
        `${config[config.STAGE].endpoint}/api/v1/generateDownloadUrl`,
        {
          objectKey
        }
      )
      dispatch({
        type: 'SET_PROJECT_DOWNLOAD_URL',
        payload: {
          project,
          ...presignedUrlResult.data
        }
      })
    }
    initProjectDownloadUrl()
  }, [project])

  useEffect(() => {
    const initJobsCount = async (): Promise<void> => {
      const [jobsCount, translatedJobsCount] = await Promise.all([
        axios.get<{ count: number }>(
          `${config[config.STAGE].endpoint}/api/v1/jobs/count?projectId=${project._id}`
        ),
        axios.get<{ count: number }>(
          `${config[config.STAGE].endpoint}/api/v1/jobs/count?projectId=${
            project._id
          }&[translatedAt][$ne]=null`
        )
      ])
      dispatch({
        type: 'SET_JOBS_COUNT',
        payload: {
          total: jobsCount.data.count,
          translated: translatedJobsCount.data.count
        }
      })
    }
    initJobsCount()
  }, [project])

  return { state, dispatch }
}

export default useDownloadTranslatedFilesDialog
