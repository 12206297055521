import React, { useReducer } from 'react'

import User from '../../../lib/User'

export type State = {
  selectedUsers: User[]
  showAddUserDialog: boolean
  refreshUserList: boolean
}

export type Action =
  | { type: 'SET_SELECTED_USERS'; payload: { selectedUsers: User[] } }
  | { type: 'SET_SHOW_ADD_USER_DIALOG'; payload: { show: boolean } }
  | { type: 'SET_REFRESH_USER_LIST'; payload: { refreshUserList: boolean } }

export const initialState: State = {
  selectedUsers: [],
  refreshUserList: true,
  showAddUserDialog: false
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_SELECTED_USERS': {
      return {
        ...state,
        selectedUsers: action.payload.selectedUsers
      }
    }
    case 'SET_SHOW_ADD_USER_DIALOG': {
      return {
        ...state,
        showAddUserDialog: action.payload.show
      }
    }
    case 'SET_REFRESH_USER_LIST': {
      return {
        ...state,
        refreshUserList: action.payload.refreshUserList
      }
    }
    default: {
      return state
    }
  }
}

export const useUsersPage = (): {
  state: State
  dispatch: React.Dispatch<Action>
} => {
  const [state, dispatch] = useReducer(stateReducer, initialState)

  return { state, dispatch }
}
