import { ColDef } from '@ag-grid-community/core'

import { DisabledCellRenderer } from '../../lib/CellRenderers'
import {
  EmptyCellFormatter,
  IsEnabledFormatter,
  LanguageNameFormatter
} from '../../lib/ValueFormatters'

const useColumnDefs = (enableEdit?: boolean, showAllColumns?: boolean) => {
  const defaultColDef: ColDef = {
    flex: 1,
    lockVisible: true,
    minWidth: 100,
    resizable: true,
    sortable: false
  }

  const columnDefs: ColDef[] = [
    {
      headerName: '',
      field: '_id',
      checkboxSelection: true,
      maxWidth: 50,
      minWidth: 50,
      resizable: false,
      valueFormatter: EmptyCellFormatter,
      width: 50
    },
    {
      headerName: '翻訳会社名称',
      field: 'name',
      cellRenderer: enableEdit === true ? 'VendorNameRenderer' : DisabledCellRenderer
    },
    { field: 'ownerGroupId', hide: true },
    { headerName: '所属先名', field: 'ownerGroupName', hide: !showAllColumns },
    {
      headerName: 'ソース言語',
      field: 'srcLang',
      valueFormatter: LanguageNameFormatter,
      cellRenderer: DisabledCellRenderer
    },
    {
      headerName: 'ターゲット言語',
      field: 'tgtLang',
      valueFormatter: LanguageNameFormatter,
      cellRenderer: DisabledCellRenderer
    },
    { headerName: '標準単価', field: 'unitPrice', hide: !showAllColumns },
    { headerName: '通貨', field: 'currency', hide: !showAllColumns },
    { headerName: '', field: 'multipliersRepetitions', hide: true },
    { headerName: '', field: 'multipliersExact', hide: true },
    { headerName: '', field: 'multipliersOver95', hide: true },
    { headerName: '', field: 'multipliersOver85', hide: true },
    { headerName: '', field: 'multipliersOver75', hide: true },
    { headerName: '', field: 'multipliersNoMatch', hide: true },
    {
      headerName: '有効',
      field: 'isEnabled',
      hide: !showAllColumns,
      valueFormatter: IsEnabledFormatter
    }
  ]

  return { columnDefs, defaultColDef }
}

export default useColumnDefs
