import React, { createRef, forwardRef, useImperativeHandle, useState } from 'react'
import { ICellEditorParams } from 'ag-grid-community'

type Params = ICellEditorParams & {
  values: boolean[]
}

const ActiveInactiveCellEditor = forwardRef(
  (params: Params, ref): JSX.Element => {
    const selectRef = createRef<HTMLSelectElement>()

    const [value, setValue] = useState(params.value)

    useImperativeHandle(ref, () => {
      return {
        getValue: (): boolean => {
          return selectRef.current ? selectRef.current.value === 'true' : false
        }
      }
    })

    if (!params.data.editable) {
      return (
        <span className="ag-cell-value" style={{ textOverflow: 'hidden' }}>
          {params.formatValue(params.value)}
        </span>
      )
    }

    return (
      <select
        onChange={(e): void => {
          setValue(e.currentTarget.value)
        }}
        style={{
          width: '100%'
        }}
        value={value.toString()}
        ref={selectRef}
      >
        {params.values.map(value => {
          return (
            <option key={value.toString()} value={value.toString()}>
              {params.formatValue(value)}
            </option>
          )
        })}
      </select>
    )
  }
)
ActiveInactiveCellEditor.displayName = 'VendorCellEditor'

export default ActiveInactiveCellEditor
