import React from 'react'
import { ICellRendererParams } from 'ag-grid-community'

const TMEntryCellRenderer = (params: ICellRendererParams): JSX.Element => {
  return (
    <>
      {params.data && (
        <span style={params.data.editable === false ? { color: '#999' } : { color: '#000' }}>
          {params.formatValue(params.value)}
        </span>
      )}
    </>
  )
}

export default TMEntryCellRenderer
