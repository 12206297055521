import { Dispatch, useCallback, useReducer } from 'react'
import axios from 'axios'
import config from '../../../config'

export type State = {
  loading: boolean
  message: {
    isError: boolean
    text: string
  }
  selectedFiles: File[]
}

export type Action =
  | { type: 'SET_IS_LOADING'; payload: { isLoading: boolean } }
  | { type: 'SET_MESSAGE'; payload: { message: string; isError?: boolean } }
  | { type: 'SET_SELECTED_FILES'; payload: { selectedFiles: File[] } }

export const initialState: State = {
  loading: false,
  message: {
    isError: false,
    text: ''
  },
  selectedFiles: []
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_IS_LOADING': {
      return {
        ...state,
        loading: action.payload.isLoading
      }
    }
    case 'SET_MESSAGE': {
      return {
        ...state,
        message: {
          text: action.payload.message,
          isError: action.payload.isError ?? false
        }
      }
    }
    case 'SET_SELECTED_FILES': {
      return {
        ...state,
        selectedFiles: action.payload.selectedFiles
      }
    }
    default: {
      return state
    }
  }
}

type UseUploadTranslatedXliffsDialog = {
  (projectId: string): {
    state: State
    dispatch: Dispatch<Action>
    handleSubmit: () => Promise<void>
  }
}

export const useUploadTranslatedXliffsDialog: UseUploadTranslatedXliffsDialog = projectId => {
  const [state, dispatch] = useReducer(stateReducer, initialState)

  const handleSubmit = useCallback(async (): Promise<void> => {
    await Promise.all(
      state.selectedFiles.map(async file => {
        const uploadUrlResult = await axios.post<{
          presignedUrl: string
          objectKey: string
          contentType: string
        }>(`${config[config.STAGE].endpoint}/api/v1/generateUploadUrl`, {
          folder: 'dropzone/translated-xliff',
          filename: file.name
        })
        await axios.put(uploadUrlResult.data.presignedUrl, file, {
          headers: {
            'Content-Type': uploadUrlResult.data.contentType
          }
        })

        return axios.post(
          `${config[config.STAGE].endpoint}/api/v1/projects/${projectId}/confirmTranslations`,
          {
            objectKey: uploadUrlResult.data.objectKey
          }
        )
      })
    )
  }, [projectId, state.selectedFiles])

  return {
    state,
    dispatch,
    handleSubmit
  }
}
