import React from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import queryString from 'query-string'

import ToggleSortOrderButton from '../../../../components/ToggleSortOrderButton'
import useQueryString from '../../../../hooks/useQueryString'

type ContainerProps = RouteComponentProps & {
  showAddVendorDialog: () => void
}

type Props = ContainerProps & {
  onChangeSortBy: (value: string) => void
  toggleOrderByAsc: () => void
}

export const Component: React.FC<Props> = (props: Props) => {
  const { showAddVendorDialog, onChangeSortBy, toggleOrderByAsc } = props
  const { t } = useTranslation()
  const { sortBy, orderByAsc } = useQueryString(props.location.search)

  return (
    <div>
      <ButtonToolbar className="ml-3 mr-3 mb-3">
        <Button onClick={showAddVendorDialog} data-testid="add-vendor-button">
          {t('翻訳会社追加')}
        </Button>

        <select
          className="ml-auto"
          onChange={(e: React.FormEvent<HTMLSelectElement>): void =>
            onChangeSortBy(e.currentTarget.value)
          }
          value={sortBy ? sortBy : 'name'}
        >
          <option value="name">{t('翻訳会社名称')}</option>
          <option value="ownerGroup.name">{t('所属先名')}</option>
          <option value="srcLang">{t('ソース言語')}</option>
          <option value="tgtLang">{t('ターゲット言語')}</option>
        </select>
        <ToggleSortOrderButton
          onClick={toggleOrderByAsc}
          orderByAsc={orderByAsc ? orderByAsc : false}
        />
      </ButtonToolbar>
    </div>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { sortBy, orderByAsc } = useQueryString(props.location.search)

  const onChangeSortBy = (value: string): void => {
    const qs = props.location.search
    const queries = queryString.parse(qs)
    delete queries[`options[sort][${sortBy}]`]
    queries[`options[sort][${value}]`] = orderByAsc ? '1' : '-1'

    const options = queryString.stringify(queries)
    props.history.push('/admin/vendors?' + options)
  }

  const toggleOrderByAsc = (): void => {
    const qs = props.location.search
    const queries = queryString.parse(qs)
    queries[`options[sort][${sortBy ? sortBy : 'name'}]`] = !orderByAsc ? '1' : '-1'

    const options = queryString.stringify(queries)
    props.history.push('/admin/vendors?' + options)
  }

  return (
    <Component {...props} onChangeSortBy={onChangeSortBy} toggleOrderByAsc={toggleOrderByAsc} />
  )
}

Container.displayName = 'Toolbar'
export default withRouter(Container)
