import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { useLocation } from 'react-router'
import useAxios from 'axios-hooks'
import queryString from 'query-string'

import config from '../../config'
import TM from '../../lib/TM'
import { useLoginUser } from '../../hooks/useLoginUser'

type State = {
  inlineEdit: boolean
  refreshTMList: boolean
  selectedTMEntries: TM[]
  showEditTMDialog: boolean
  showImportDialog: boolean
  showExportDialog: boolean
  showDeleteTMEntriesDialog: boolean
  showSearchModeHelpDialog: boolean
  showToast: boolean
  excludeIds: string[]
  allChecked: boolean
  indeterminate: boolean
}

type Action =
  | { type: 'UPDATE'; payload: Partial<State> }
  | { type: 'REFRESH_TM_LIST' }
  | { type: 'TOGGLE_INLINE_EDIT' }
  | { type: 'TOGGLE_ALL_CHECKED' }
  | { type: 'ADD_EXCLUDE_IDS'; payload: { ids: string[] } }
  | { type: 'DELETE_EXCLUDE_IDS'; payload: { ids: string[] } }

const initialState: State = {
  inlineEdit: false,
  refreshTMList: true,
  selectedTMEntries: [],
  showEditTMDialog: false,
  showImportDialog: false,
  showExportDialog: false,
  showDeleteTMEntriesDialog: false,
  showSearchModeHelpDialog: false,
  showToast: false,
  excludeIds: [],
  allChecked: false,
  indeterminate: false
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, ...action.payload }
    case 'REFRESH_TM_LIST':
      return { ...state, refreshTMList: !state.refreshTMList }
    case 'TOGGLE_INLINE_EDIT':
      return { ...state, inlineEdit: !state.inlineEdit }
    case 'TOGGLE_ALL_CHECKED':
      return { ...state, allChecked: !state.allChecked }
    case 'ADD_EXCLUDE_IDS':
      return { ...state, excludeIds: [...state.excludeIds, ...action.payload.ids] }
    case 'DELETE_EXCLUDE_IDS': {
      const excludeIds = state.excludeIds
      action.payload.ids.forEach(id => {
        if (excludeIds.includes(id)) {
          excludeIds.splice(excludeIds.indexOf(id), 1)
        }
      })
      return { ...state, excludeIds }
    }
    default:
      return state
  }
}

const TMEntriesPageContext = createContext(
  {} as {
    state: State
    dispatch: React.Dispatch<Action>
  }
)

export const TMEntriesPageProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const location = useLocation()
  const loginUser = useLoginUser()

  const query = queryString.parse(location.search)

  if (loginUser?.organizationType === 'owner-group') {
    if (!Object.keys(query).includes('ownerGroupId') && loginUser?.organizationIds) {
      query['ownerGroupId'] = loginUser?.organizationIds
    }
  }

  const baseUrl = config[config.STAGE].endpoint
  const [{ data }] = useAxios<{ count: number }>(
    `${baseUrl}/api/v1/tmEntries/count?${queryString.stringify(query)}`
  )

  const editableEntriesCount = data?.count

  useEffect(() => {
    dispatch({
      type: 'UPDATE',
      payload: { selectedTMEntries: [], excludeIds: [], allChecked: false, indeterminate: false }
    })
  }, [location.search, state.refreshTMList])

  useEffect(() => {
    if (editableEntriesCount) {
      if (state.selectedTMEntries.length === 0) {
        dispatch({ type: 'UPDATE', payload: { indeterminate: false } })
      } else if (
        state.selectedTMEntries.length === editableEntriesCount ||
        (state.allChecked && state.excludeIds.length === 0)
      ) {
        dispatch({ type: 'UPDATE', payload: { allChecked: true, indeterminate: false } })
      } else if (
        0 < state.selectedTMEntries.length &&
        state.selectedTMEntries.length < editableEntriesCount
      ) {
        dispatch({ type: 'UPDATE', payload: { indeterminate: true } })
      }
    }
  }, [state.selectedTMEntries, editableEntriesCount, state.allChecked, state.excludeIds])

  useEffect(() => {
    if (state.allChecked && state.excludeIds.length === 0) {
      dispatch({ type: 'UPDATE', payload: { indeterminate: false } })
    }
  }, [state.allChecked, state.excludeIds.length])

  return (
    <TMEntriesPageContext.Provider value={{ state, dispatch }}>
      {children}
    </TMEntriesPageContext.Provider>
  )
}

export const TMEntriesPageConsumer = TMEntriesPageContext.Consumer

export const useTMEntriesPage = (): {
  state: State
  dispatch: React.Dispatch<Action>
} => {
  return useContext(TMEntriesPageContext)
}
