import queryString from 'query-string'

const useQueryString = (
  qs: string
): {
  sortBy?: string
  orderByAsc?: boolean
} => {
  const query = queryString.parse(qs)

  const filteredKeys = Object.keys(query).filter(key => {
    return key.match(/options\[sort\]/)
  })

  const sortBy =
    filteredKeys.length === 1
      ? filteredKeys[0].replace(/options\[sort\]\[([^\]]+)\]/, '$1')
      : undefined
  const orderByAsc = filteredKeys.length === 1 ? query[filteredKeys[0]] === '1' : undefined

  return { sortBy: sortBy, orderByAsc: orderByAsc }
}

export default useQueryString
