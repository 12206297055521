import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ColumnResizedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IDatasource,
  IGetRowsParams,
  RowSelectedEvent
} from '@ag-grid-community/core'
import axios from 'axios'
import queryString from 'query-string'

import Project from '../../lib/Project'
import RowData, { ProjectToRowData } from './RowData'
import useColumnDefs from './useColumnDefs'
import config from '../../config'
import CustomHeader from '../CustomHeader'

const useProjectList = ({
  updateSelection,
  qs,
  toggleRefresh
}: {
  updateSelection: (selected: Project[]) => void
  qs: string
  toggleRefresh?: boolean
}): { gridApi: GridApi | undefined; gridOptions: GridOptions } => {
  const { i18n } = useTranslation()

  const { columnDefs, defaultColDef } = useColumnDefs()

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)

  useEffect(() => {
    if (gridApi) {
      const updateData = (): void => {
        const dataSource: IDatasource = {
          rowCount: undefined,
          getRows: (params: IGetRowsParams) => {
            setTimeout(async () => {
              const query = queryString.parse(qs)
              const defaultSort =
                Object.keys(query).filter(key => {
                  return key.startsWith('options[sort]')
                }).length > 0
                  ? false
                  : true

              const sort: {
                [key: string]: number
              } = {}
              if (defaultSort) {
                sort['createdAt'] = -1
              } else {
                const sortBy = Object.keys(query)
                  .filter(key => {
                    return key.startsWith('options[sort]')
                  })
                  .map(key => {
                    return key.replace(/options\[sort\]\[([^\]]+)\]/, '$1')
                  })
                sortBy.forEach(key => {
                  if (Object.keys(sort).includes(key)) {
                    const sortOrder = query[`options[sort][${key}]`]
                    if (typeof sortOrder === 'string') {
                      sort[key] = parseInt(sortOrder)
                    }
                  }
                })
              }

              const projectsResponse = await axios.get<Project[]>(
                `${config[config.STAGE].endpoint}/api/v1/projects`,
                {
                  params: {
                    ...query,
                    options: {
                      limit: 100,
                      skip: params.startRow,
                      sort: sort
                    },
                    recalculate: true
                  }
                }
              )

              const projects = projectsResponse.data
              const rowsThisPage: RowData[] = projectsResponse.data.map(project =>
                ProjectToRowData(project)
              )

              const lastRow = projects.length < 100 ? params.startRow + projects.length : -1

              params.successCallback(rowsThisPage, lastRow)
            }, 500)
          }
        }
        gridApi.setDatasource(dataSource)
      }

      updateData()
    }
  }, [gridApi, qs, toggleRefresh, i18n.language])

  const onGridReady = (params: GridReadyEvent): void => {
    setGridApi(params.api)

    if (localStorage.getItem('projectListColumnWidths') !== null) {
      const columnWidthsString = localStorage.getItem('projectListColumnWidths')
      if (typeof columnWidthsString === 'string') {
        const columnWidths = JSON.parse(columnWidthsString)
        Object.keys(columnWidths).forEach(key => {
          params.columnApi.setColumnWidth(key, columnWidths[key])
        })
      }
    }
  }

  const onRowSelected = (params: RowSelectedEvent): void => {
    const selected = params.api.getSelectedRows()
    updateSelection(selected)
  }

  const onColumnResized = (params: ColumnResizedEvent): void => {
    if (localStorage.getItem('projectListColumnWidths') !== null) {
      const columnWidthsString = localStorage.getItem('projectListColumnWidths')
      if (typeof columnWidthsString === 'string') {
        const columnWidths = JSON.parse(columnWidthsString)
        if (columnWidths) {
          const key = params.column?.getColDef().colId
          if (key !== undefined) {
            columnWidths[key] = params.column?.getActualWidth()
            localStorage.setItem('projectListColumnWidths', JSON.stringify(columnWidths))
          }
        }
      }
    } else {
      const key = params.column?.getColDef().colId
      if (key !== undefined) {
        const columnWidths: Record<string, any> = {}
        columnWidths[key] = params.column?.getActualWidth()
        localStorage.setItem('projectListColumnWidths', JSON.stringify(columnWidths))
      }
    }
  }

  const gridOptions: GridOptions = {
    cacheOverflowSize: 5,
    enableCellTextSelection: true,
    columnDefs: columnDefs,
    defaultColDef: defaultColDef,
    frameworkComponents: { agColumnHeader: CustomHeader },
    infiniteInitialRowCount: 0,
    maxBlocksInCache: 10,
    maxConcurrentDatasourceRequests: 1,
    onColumnResized: onColumnResized,
    onGridReady: onGridReady,
    onRowSelected: onRowSelected,
    paginationPageSize: 100,
    rowBuffer: 0,
    rowModelType: 'infinite',
    rowMultiSelectWithClick: true,
    rowSelection: 'multiple'
  }

  return { gridApi, gridOptions }
}

export default useProjectList
