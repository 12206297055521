import React, { useEffect } from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import useAxios from 'axios-hooks'
import queryString from 'query-string'

import config from '../../../config'
import { OverlayTriggerButton } from '../../../components/common/OverlayTriggerButton'
import DeleteProjectsButton from './DeleteProjectsButton'
import ExportCsvButton from './ExportCsvButton'
import ToggleSortOrderButton from '../../../components/ToggleSortOrderButton'
import { useLoginUser } from '../../../hooks/useLoginUser'
import useQueryString from '../../../hooks/useQueryString'
import { State, Action } from '../useProjectsPage'

type ContainerProps = RouteComponentProps & {
  state: State
  dispatch: React.Dispatch<Action>
}

type Props = ContainerProps & {
  count: number
  totalCount: number
  clearFilters: () => void
  onChangeSortBy: (value: string) => void
  toggleOrderByAsc: () => void
}

export const Component: React.FC<Props> = (props: Props) => {
  const {
    state,
    dispatch,
    count,
    totalCount,
    clearFilters,
    onChangeSortBy,
    toggleOrderByAsc
  } = props
  const { t } = useTranslation()
  const loginUser = useLoginUser()
  const { sortBy, orderByAsc } = useQueryString(props.location.search)

  return (
    <div>
      <ButtonToolbar className="ml-3 mr-3 mb-3">
        {loginUser?.organizationType !== 'vendor' && (
          <>
            <DeleteProjectsButton
              onClick={(): void =>
                dispatch({ type: 'SET_SHOW_DELETE_PROJECT_DIALOG', payload: { show: true } })
              }
              projects={state.selectedProjects}
              data-testid="delete-projects-button"
            />
            <OverlayTriggerButton
              variant="secondary"
              buttonLabel={t('選択したプロジェクトを編集')}
              tooltipLabel={t('プロジェクトが選択されていません')}
              tooltipId="tooltip-edit-projects-button-disabled"
              isOverlayed={(): boolean => state.selectedProjects.length === 0}
              buttonClassName="ml-2"
              onClick={(): void =>
                dispatch({ type: 'SET_SHOW_EDIT_PROJECTS_DIALOG', payload: { show: true } })
              }
              data-testid="edit-projects-button"
            />
          </>
        )}
        <ExportCsvButton />
        <Button className="ml-2" disabled variant="link">
          {count} / {totalCount}
        </Button>
        <Button
          className="ml-2"
          onClick={(): void => {
            dispatch({ type: 'SET_SHOW_PROJECT_FILTERS_DIALOG', payload: { show: true } })
          }}
          variant="light"
          data-testid="project-filters-button"
        >
          {t('絞り込み')}
        </Button>
        <Button
          className="ml-2"
          onClick={clearFilters}
          variant="light"
          data-testid="clear-filters-button"
        >
          {t('絞り込み解除')}
        </Button>
        <select
          className="ml-2"
          onChange={(e: React.FormEvent<HTMLSelectElement>): void =>
            onChangeSortBy(e.currentTarget.value)
          }
          value={sortBy ? sortBy : 'createdAt'}
          data-testid="select-sort-by"
        >
          <option value="createdAt">{t('作成日時')}</option>
          <option value="updatedAt">{t('更新日時')}</option>
          <option value="name">{t('プロジェクト名')}</option>
          <option value="projectType">{t('プロジェクトタイプ')}</option>
          <option value="srcLang">{t('ソース言語')}</option>
          <option value="tgtLang">{t('ターゲット言語')}</option>
          <option value="vendorId">{t('発注先翻訳会社')}</option>
          <option value="ownerId">{t('担当者')}</option>
          <option value="ownerGroupId">{t('担当者所属')}</option>
        </select>
        <ToggleSortOrderButton
          onClick={toggleOrderByAsc}
          orderByAsc={orderByAsc ? orderByAsc : false}
          data-testid="toggle-sort-order-button"
        />
      </ButtonToolbar>
    </div>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { state, dispatch } = props
  const { sortBy, orderByAsc } = useQueryString(props.location.search)

  const [{ data: countData }, countRefetch] = useAxios(
    `${config[config.STAGE].endpoint}/api/v1/projects/count${props.location.search}`
  )
  const [{ data: totalData }, totalRefetch] = useAxios(
    `${config[config.STAGE].endpoint}/api/v1/projects/count`
  )

  const clearFilters = (): void => {
    const qs = props.location.search
    const query = queryString.parse(qs)
    Object.keys(query).forEach((key: string) => {
      if (!key.startsWith('options[sort]')) {
        delete query[key]
      }
    })

    props.history.push(
      `/projects?archivedAt=null${
        Object.keys(query).length > 0 ? '&' + queryString.stringify(query) : ''
      }`
    )
  }

  const onChangeSortBy = (value: string): void => {
    const qs = props.location.search
    const queries = queryString.parse(qs)
    delete queries[`options[sort][${sortBy}]`]
    queries[`options[sort][${value}]`] = orderByAsc ? '1' : '-1'

    const options = queryString.stringify(queries)
    props.history.push('/projects?' + options)
  }

  const toggleOrderByAsc = (): void => {
    const qs = props.location.search
    const queries = queryString.parse(qs)
    queries[`options[sort][${sortBy ? sortBy : 'createdAt'}]`] = !orderByAsc ? '1' : '-1'

    const options = queryString.stringify(queries)
    props.history.push('/projects?' + options)
  }

  useEffect(() => {
    countRefetch()
    totalRefetch()
  }, [countRefetch, totalRefetch, state.toggleRefresh])

  return (
    <Component
      {...props}
      state={state}
      dispatch={dispatch}
      count={countData?.count}
      totalCount={totalData?.count}
      clearFilters={clearFilters}
      onChangeSortBy={onChangeSortBy}
      toggleOrderByAsc={toggleOrderByAsc}
    />
  )
}
Container.displayName = 'Toolbar'

export default withRouter(Container)
