import { Dispatch, useCallback, useReducer } from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import config from '../../../config'
import { sleep } from '../../../lib/Utils'

export type State = {
  loading: boolean
  message: {
    isError: boolean
    text: string
  }
  selectedFiles: File[]
}

export type Action =
  | { type: 'SET_IS_LOADING'; payload: { isLoading: boolean } }
  | { type: 'SET_MESSAGE'; payload: { message: string; isError?: boolean } }
  | { type: 'SET_SELECTED_FILES'; payload: { selectedFiles: File[] } }

export const initialState: State = {
  loading: false,
  message: {
    isError: false,
    text: ''
  },
  selectedFiles: []
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_IS_LOADING': {
      return {
        ...state,
        loading: action.payload.isLoading
      }
    }

    case 'SET_MESSAGE': {
      return {
        ...state,
        message: {
          text: action.payload.message,
          isError: action.payload.isError ?? false
        }
      }
    }
    case 'SET_SELECTED_FILES': {
      return {
        ...state,
        selectedFiles: action.payload.selectedFiles
      }
    }
    default: {
      return state
    }
  }
}

export const useRegisterJobsDialog = (): {
  state: State
  dispatch: Dispatch<Action>
  handleSubmit: () => Promise<void>
} => {
  const [state, dispatch] = useReducer(stateReducer, initialState)
  const { t } = useTranslation()

  const hasValidFiles = useCallback((): boolean => {
    return state.selectedFiles.every(f => /\.tsv$/.test(f.name))
  }, [state.selectedFiles])

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (!hasValidFiles()) {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { message: t('ファイルをアップロードしています'), isError: true }
      })
      return
    }

    dispatch({ type: 'SET_IS_LOADING', payload: { isLoading: true } })
    dispatch({ type: 'SET_MESSAGE', payload: { message: t('ファイルをアップロードしています') } })

    await Promise.all(
      state.selectedFiles.map(async file => {
        const uploadUrlResult = await axios.post<{ presignedUrl: string; contentType: string }>(
          `${config[config.STAGE].endpoint}/api/v1/generateUploadUrl`,
          {
            folder: 'dropzone/source-mdm-tsv',
            filename: file.name
          }
        )
        const uploadFileResult = await axios.put(uploadUrlResult.data.presignedUrl, file, {
          headers: {
            'Content-Type': uploadUrlResult.data.contentType
          }
        })
        return uploadFileResult
      })
    )
    await sleep(3000)
  }, [hasValidFiles, state.selectedFiles, t])

  return {
    state,
    dispatch,
    handleSubmit
  }
}
