import React, { createRef, forwardRef, useImperativeHandle, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { ICellEditorParams } from 'ag-grid-community'
import { useTranslation } from 'react-i18next'

const ConfidenceCellEditor = forwardRef(
  (params: ICellEditorParams, ref): JSX.Element => {
    const { t } = useTranslation()
    const inputRef = createRef<HTMLInputElement>()

    const [value, setValue] = useState(params.value)

    const validate = (value: number | string): boolean => {
      switch (typeof value) {
        case 'number':
          return value <= 100 && value >= 0
        case 'string':
          return value.match(/^100$|^[0-9]{1,2}$/) === null ? false : true
        default:
          return false
      }
    }

    useImperativeHandle(ref, () => {
      return {
        getValue: (): string => {
          return inputRef.current ? inputRef.current.value : ''
        }
      }
    })

    return (
      <>
        {params.data.editable ? (
          <>
            {validate(value) === false && (
              <div
                style={{
                  backgroundColor: '#ccc',
                  border: '1px solid #000',
                  color: '#000',
                  position: 'absolute',
                  top: '0px',
                  left: '100px',
                  padding: '0 5px',
                  zIndex: 9999999
                }}
              >
                <FontAwesomeIcon icon={faExclamationTriangle} />
                {t('0〜100の整数で入力してください。')}
              </div>
            )}
            <div className="ag-cell-value">
              <input
                onChange={(e): void => {
                  setValue(e.currentTarget.value)
                }}
                value={value}
                style={{ width: '100%' }}
                ref={inputRef}
              />
            </div>
          </>
        ) : (
          <>{params.value}</>
        )}
      </>
    )
  }
)
ConfidenceCellEditor.displayName = 'ConfidenceCellEditor'

export default ConfidenceCellEditor
