import axios from 'axios'

import config from '../config'
import Project from './Project'
import Status from './Status'
import { s3Keys } from './Utils'

type Job = {
  _id: string
  projectId?: string
  name: string
  parentName?: string
  repositoryFileId?: string
  repositoryFolderId?: string
  status?: Status
  statistics?: { [key: string]: number }
  numberOfBytes?: number
  wordCount?: number
  weightedWordCount?: number
  unitPrice?: number
  currency?: string
  calculatedAmount?: number
  projectType?: string
  srcLang: string
  tgtLang: string
  dueAt?: Date
  orderedAt?: Date
  startReviewAt?: Date
  reviewDueAt?: Date
  completedAt?: Date
  vendorId?: string
  ownerId?: string
  ownerGroupId?: string
  createdAt?: Date
  updatedAt?: Date
  properties: { [key: string]: string }
  project?: Project
  contentType?: string
  externalId?: string
  externalParentId?: string
  origin?: string
  archivedAt?: Date
  unitStatistics?: {
    untranslatedCount: number
    totalCount: number
  }
  translatedAt?: Date
  errorDetails?: {
    code?: string
    details?: string
  }
}

type PresignedUrl = {
  presignedUrl: string
  exists: boolean
}

export const getProjectName = (job: Job): string => {
  if (!job.project) {
    return ''
  }
  return job.project.name || ''
}

export const getChildResourceName = (
  job: Job,
  field: 'vendor' | 'owner' | 'ownerGroup'
): string => {
  if (!job.project) {
    return ''
  }
  if (!job.project[field]) {
    return ''
  }
  if (field === 'owner') {
    const owner = job.project[field]
    return owner ? owner.username : ''
  }
  const vendorOrOwnerGroup = job.project[field]
  return vendorOrOwnerGroup ? vendorOrOwnerGroup.name : ''
}

export const getDownloadUrl = async (job: Job): Promise<PresignedUrl> => {
  const objectKey = s3Keys.job.translatedZip(job)
  const presignedUrlResult = await axios.post<PresignedUrl>(
    `${config[config.STAGE].endpoint}/api/v1/generateDownloadUrl`,
    {
      objectKey
    }
  )
  return presignedUrlResult.data
}

export default Job
