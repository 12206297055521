import { useCallback, useEffect, useReducer } from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import config from '../../../config'
import User from '../../../lib/User'

type FormData = {
  email: string
  username: string
  organizationType: string
  organizationIds: string[]
  role: string
  sendNotifications: boolean
  isEnabled: boolean
}

export type State = {
  loading: boolean
  message: {
    isError: boolean
    text: string
  }
  formData: FormData
}

export type Action =
  | { type: 'SET_IS_LOADING'; payload: { isLoading: boolean } }
  | { type: 'SET_MESSAGE'; payload: { message: string; isError?: boolean } }
  | { type: 'SET_FORM_VALUE'; payload: Partial<FormData> }

export const initialState: State = {
  loading: false,
  message: {
    isError: false,
    text: ''
  },
  formData: {
    email: '',
    username: '',
    organizationType: 'owner-group',
    organizationIds: [],
    role: 'user',
    sendNotifications: true,
    isEnabled: true
  }
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_IS_LOADING': {
      return {
        ...state,
        loading: action.payload.isLoading
      }
    }
    case 'SET_MESSAGE': {
      return {
        ...state,
        message: {
          text: action.payload.message,
          isError: action.payload.isError ?? false
        }
      }
    }
    case 'SET_FORM_VALUE': {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload
        }
      }
    }
    default:
      return state
  }
}

const useEditUserDialog = (
  user?: User
): {
  state: State
  dispatch: React.Dispatch<Action>
  handleSubmit: () => Promise<void>
} => {
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(stateReducer, initialState)

  useEffect(() => {
    if (user) {
      dispatch({
        type: 'SET_FORM_VALUE',
        payload: {
          email: user.email,
          username: user.username,
          organizationType: user.organizationType,
          organizationIds: user.organizationIds,
          role: user.role,
          sendNotifications: user.sendNotifications,
          isEnabled: user.isEnabled
        }
      })
    }
  }, [user])

  const handleSubmit = useCallback(async () => {
    if (user) {
      dispatch({ type: 'SET_MESSAGE', payload: { message: t('ユーザーを更新しています') } })
      await axios.patch(
        `${config[config.STAGE].endpoint}/api/v1/users/${user?._id}`,
        state.formData
      )
    } else {
      dispatch({ type: 'SET_MESSAGE', payload: { message: t('ユーザーを登録しています') } })
      await axios.post(`${config[config.STAGE].endpoint}/api/v1/users`, state.formData)
    }
  }, [user, t, state.formData])

  return { state, dispatch, handleSubmit }
}

export default useEditUserDialog
