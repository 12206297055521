import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'

import './SearchModeHelpDialog.css'

type Props = {
  onClose: () => void
}

const SearchModeHelpDialog: React.FC<Props> = (props: Props) => {
  const { onClose } = props
  const { t } = useTranslation()

  return (
    <Modal show={true} size="lg" id="search-mode-help-dialog" onHide={onClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <dl>
          <dt>{t('フレーズ検索')}</dt>
          <dd>
            <p>{t('検索モードヘルプ_フレーズ検索')}</p>
            <p>
              <span className="search-mode-help-note-header">{t('注意点')}:</span>
              {t('検索モードヘルプ_注意点')}
            </p>
          </dd>
          <dt>{t('完全一致検索')}</dt>
          <dd>
            <p>{t('検索モードヘルプ_完全一致')}</p>
            <p>
              <span className="search-mode-help-note-header">{t('注意点')}:</span>
              {t('検索モードヘルプ_注意点')}
            </p>
          </dd>
          <dt>{t('詳細検索')}</dt>
          <dd>
            <p>{t('検索モードヘルプ_詳細検索')}</p>
            <span className="search-mode-help-subtitle-header">{t('AND検索')}</span>
            <p>{t('検索モードヘルプ_詳細検索_AND')}</p>
            <p>
              <span className="search-mode-help-example-header">{t('例')}: </span>
              {t('検索モードヘルプ_詳細検索_AND_例')}
            </p>
            <pre>shaft +wire</pre>
            <span className="search-mode-help-subtitle-header">{t('OR検索')}</span>
            <p>{t('検索モードヘルプ_詳細検索_OR')}</p>
            <p>
              <span className="search-mode-help-example-header">{t('例')}:</span>
              {t('検索モードヘルプ_詳細検索_OR_例')}
            </p>
            <pre>shaft |wire</pre>
            <span className="search-mode-help-subtitle-header">{t('NOT検索')}</span>
            <p>{t('検索モードヘルプ_詳細検索_NOT')}</p>
            <p>
              <span className="search-mode-help-example-header">{t('例')}:</span>
              {t('検索モードヘルプ_詳細検索_NOT_例')}
            </p>
            <pre>shaft -wire</pre>
            <p>
              <span className="search-mode-help-note-header">{t('注意点')}:</span>
              {t('検索モードヘルプ_注意点2')}
            </p>
          </dd>
          <dt>{t('曖昧検索')}</dt>
          <dd>
            <p>{t('検索モードヘルプ_曖昧検索')}</p>
            <p>
              <span className="search-mode-help-note-header">{t('注意点')}:</span>
              {t('検索モードヘルプ_注意点')}
            </p>
          </dd>
        </dl>
      </Modal.Body>
    </Modal>
  )
}

export default SearchModeHelpDialog
