import { ColDef } from '@ag-grid-community/all-modules'

import {
  BooleanFormatter,
  DateTimeFormatter,
  EmptyCellFormatter,
  IsEnabledFormatter,
  OrganizationTypeFormatter,
  UserRoleFormatter
} from '../../lib/ValueFormatters'

const useColumnDefs = (): { columnDefs: ColDef[]; defaultColDef: ColDef } => {
  const defaultColDef: ColDef = {
    flex: 1,
    lockVisible: true,
    minWidth: 100,
    resizable: true,
    sortable: false
  }

  const columnDefs: ColDef[] = [
    {
      headerName: '',
      field: '_id',
      checkboxSelection: true,
      maxWidth: 50,
      minWidth: 50,
      resizable: false,
      valueFormatter: EmptyCellFormatter,
      width: 50
    },
    { headerName: 'メールアドレス', field: 'email', cellRenderer: 'EmailRenderer' },
    { headerName: 'ユーザー名', field: 'username' },
    {
      headerName: '所属先区分',
      field: 'organizationType',
      valueFormatter: OrganizationTypeFormatter
    },
    { headerName: '所属先名', field: 'organizationName' },
    { headerName: 'ロール', field: 'role', valueFormatter: UserRoleFormatter },
    { headerName: '通知メール', field: 'sendNotifications', valueFormatter: BooleanFormatter },
    { headerName: '有効', field: 'isEnabled', valueFormatter: IsEnabledFormatter },
    { headerName: '作成日時', field: 'createdAt', valueFormatter: DateTimeFormatter },
    { headerName: '更新日時', field: 'updatedAt', valueFormatter: DateTimeFormatter }
  ]

  return { columnDefs, defaultColDef }
}

export default useColumnDefs
