import React, { ReactNode, useState } from 'react'
import { Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'

type ContainerProps = {
  children?: ReactNode
  className?: string
  title?: string
}

type Props = ContainerProps & {
  isOpen: boolean
  toggle: () => void
}

export const Component: React.FC<Props> = (props: Props) => {
  const { isOpen, toggle } = props

  return (
    <Card className={props.className}>
      <Card.Header style={{ cursor: 'pointer', userSelect: 'none' }} onClick={toggle}>
        {isOpen ? (
          <FontAwesomeIcon icon={faChevronDown} data-testid="toggle-foldable-card-button" />
        ) : (
          <FontAwesomeIcon icon={faChevronRight} data-testid="toggle-foldable-card-button" />
        )}{' '}
        {props.title}
      </Card.Header>
      {isOpen ? <Card.Body>{props.children}</Card.Body> : <></>}
    </Card>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const [isOpen, setIsOpen] = useState(true)

  const toggle = (): void => {
    setIsOpen(!isOpen)
  }

  return <Component {...props} isOpen={isOpen} toggle={toggle} />
}

Container.displayName = 'FoldableCard'
export default Container
