import OwnerGroup from '../../lib/OwnerGroup'
import User from '../../lib/User'
import Vendor from '../../lib/Vendor'
import { OrganizationType } from '../../lib/Utils'

type RowData = {
  _id: string
  email: string
  username: string
  organizationType: string
  organizationIds: string[] | null
  organizationName?: string
  role: string
  sendNotifications: boolean
  isEnabled: boolean
  createdAt: Date
  updatedAt: Date
}

export const UserToRowData = (user: User, ownerGroups: OwnerGroup[]): RowData => {
  const organizationName =
    user.organizations !== undefined
      ? (user.organizations as Array<OwnerGroup | Vendor>)
          .map(organization => {
            if (user.organizationType === OrganizationType.VENDOR) {
              const ownerGroupId = (organization as Vendor).ownerGroupId
              const ownerGroupName = ownerGroups.filter((ownerGroup: OwnerGroup) => {
                return ownerGroup._id === ownerGroupId
              })[0].name
              return `${organization.name} (${ownerGroupName}) (${
                (organization as Vendor).srcLang
              } -> ${(organization as Vendor).tgtLang})`
            }
            return organization.name
          })
          .join(', ')
      : ''

  return {
    _id: user._id,
    username: user.username,
    email: user.email,
    organizationType: user.organizationType,
    organizationName: organizationName,
    organizationIds: user.organizationIds,
    role: user.role,
    sendNotifications: user.sendNotifications,
    isEnabled: user.isEnabled,
    createdAt: user.createdAt,
    updatedAt: user.updatedAt
  }
}

export default RowData
