import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import dateformat from 'dateformat'

import { useProjectDetailCard, EMPTY } from './useProjectDetailCard'
import Project from '../../../lib/Project'
import Vendor from '../../../lib/Vendor'
import { statusCodeToStatusName } from '../../../lib/Status'
import { projectTypeCodeToProjectTypeName } from '../../../lib/Utils'
import { useVendors } from '../../../hooks/useVendors'

type ContainerProps = {
  project: Project
}

type Props = ContainerProps & {
  onChangeStatus: (event: React.FormEvent<HTMLSelectElement>) => void
  onChangeProjectType: (event: React.FormEvent<HTMLSelectElement>) => void
  onChangeVendorId: (event: React.FormEvent<HTMLSelectElement>) => void
  vendors: Vendor[]
}

type Option = {
  label: string
  value: string
}

type ListItemProps = {
  label: string
  value?: string
  editable?: boolean
  options?: Option[]
  selectedValue?: string
  onChange?: (event: React.FormEvent<HTMLSelectElement>) => void
  selectElementTestId?: string
}

export const ListItem: React.FC<ListItemProps> = (props: ListItemProps) => {
  const { label, value, editable, options, selectedValue, onChange, selectElementTestId } = props
  const { state } = useProjectDetailCard()
  const { t } = useTranslation()

  if (editable && options && onChange && state.editing) {
    return (
      <li>
        {t(label)}:{' '}
        <select
          disabled={state.updating}
          value={selectedValue}
          onChange={onChange}
          data-testid={selectElementTestId}
        >
          {options.map(option => {
            return (
              <option value={option.value} key={option.value}>
                {t(option.label)}
              </option>
            )
          })}
        </select>
      </li>
    )
  }

  return (
    <li>
      {t(label)}: {value || ''}
    </li>
  )
}

export const Component: React.FC<Props> = (props: Props) => {
  const { project, onChangeStatus, onChangeProjectType, onChangeVendorId, vendors } = props
  const { state } = useProjectDetailCard()
  const { t } = useTranslation()

  const statuses: Option[] =
    project.sourceReturnedAt || project.cancelledAt || project.completedAt
      ? [
          { label: '(変更なし)', value: '' },
          { label: '準備処理完了', value: 'prepared' },
          { label: '見積依頼中', value: 'quote_requested' },
          { label: '見積提出済み', value: 'quote_ready' },
          { label: '発注確定', value: 'order_confirmed' },
          { label: '翻訳済み', value: 'translated' },
          { label: 'レビュー済み', value: 'reviewed' },
          { label: '完了', value: 'completed' }
        ]
      : [
          { label: '(変更なし)', value: '' },
          { label: '準備処理完了', value: 'prepared' },
          { label: '見積依頼中', value: 'quote_requested' },
          { label: '見積提出済み', value: 'quote_ready' },
          { label: '発注確定', value: 'order_confirmed' },
          { label: '翻訳済み', value: 'translated' },
          { label: 'レビュー済み', value: 'reviewed' }
        ]

  const projectTypes: Option[] = [
    { label: '(変更なし)', value: '' },
    { label: '人手翻訳', value: 'ht_only' },
    { label: '機械翻訳+ポストエディット', value: 'mt_and_postedit' },
    { label: '機械翻訳のみ', value: 'mt_only' }
  ]

  const vendorIds: Option[] = [{ _id: EMPTY, name: t('(なし)') }, ...vendors].map(vendor => {
    return { label: vendor.name, value: vendor._id }
  })

  return (
    <Row>
      <Col>
        <ul>
          <ListItem
            label="ステータス"
            value={t(statusCodeToStatusName(project.status || ''))}
            editable={true}
            options={statuses}
            selectedValue={state.formData.status}
            onChange={onChangeStatus}
            selectElementTestId="select-status"
          />
          <ListItem
            label="プロジェクトタイプ"
            value={t(
              project.projectType ? projectTypeCodeToProjectTypeName(project.projectType) : ''
            )}
            editable={true}
            options={projectTypes}
            selectedValue={state.formData.projectType}
            onChange={onChangeProjectType}
            selectElementTestId="select-project-type"
          />
          <ListItem label="ソース言語" value={project.srcLang} />
          <ListItem label="ターゲット言語" value={project.tgtLang} />
        </ul>
      </Col>
      <Col>
        <ul>
          <ListItem
            label="発注先翻訳会社"
            value={project.vendor?.name}
            editable={true}
            options={vendorIds}
            selectedValue={state.formData.vendorId}
            onChange={onChangeVendorId}
            selectElementTestId="select-vendor-id"
          />
          <ListItem label="担当者" value={project.owner?.username} />
          <ListItem label="担当者所属" value={project.ownerGroup?.name} />
          {props.project.approvedBy && <ListItem label="発注承認者" value={project.approvedBy} />}
          {props.project.approvedAt && (
            <ListItem
              label="発注承認日時"
              value={dateformat(project.approvedAt, 'yyyy-mm-dd HH:MM:ss')}
            />
          )}
          {props.project.cancelledBy && (
            <ListItem label="キャンセル実行者" value={project.cancelledBy} />
          )}
          {props.project.cancelledAt && (
            <ListItem
              label="キャンセル実行日時"
              value={dateformat(project.cancelledAt, 'yyyy-mm-dd HH:MM:ss')}
            />
          )}
        </ul>
      </Col>
      <Col>
        <ul>
          <ListItem label="合計ワード数" value={(project.wordCount || 0).toString()} />
          <ListItem label="合計加重ワード数" value={(project.weightedWordCount || 0).toString()} />
        </ul>
      </Col>
      <Col>
        <ul>
          <ListItem
            label="標準単価"
            value={`${(project.vendor?.unitPrice || 0).toString()}  ${project.vendor?.currency ||
              ''}`}
          />
          <ListItem
            label="算定金額"
            value={`${(project.calculatedAmount || 0).toString()} ${project.vendor?.currency ||
              ''}`}
          />
          <ListItem
            label="見積金額"
            value={`${(project.quotedAmount || 0).toString()} ${project.vendor?.currency || ''}`}
          />
          <ListItem
            label="発注金額"
            value={`${(project.orderAmount || 0).toString()} ${project.vendor?.currency || ''}`}
          />
        </ul>
      </Col>
    </Row>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { project } = props
  const { dispatch } = useProjectDetailCard()

  const onChangeStatus = (event: React.FormEvent<HTMLSelectElement>): void => {
    dispatch({ type: 'SET_FORM_VALUE', payload: { status: event.currentTarget.value } })
  }

  const onChangeProjectType = (event: React.FormEvent<HTMLSelectElement>): void => {
    dispatch({ type: 'SET_FORM_VALUE', payload: { projectType: event.currentTarget.value } })
  }

  const onChangeVendorId = (event: React.FormEvent<HTMLSelectElement>): void => {
    dispatch({ type: 'SET_FORM_VALUE', payload: { vendorId: event.currentTarget.value } })
  }

  const vendors = useVendors()
    ?.filter(vendor => vendor.isEnabled === true)
    .filter(vendor => vendor.srcLang === project.srcLang)
    .filter(vendor => vendor.tgtLang === project.tgtLang)
    .filter(vendor => vendor.ownerGroupId === project.ownerGroupId)

  useEffect(() => {
    dispatch({
      type: 'SET_FORM_VALUE',
      payload: {
        projectType: project.projectType,
        vendorId: project.vendorId || EMPTY
      }
    })
  }, [dispatch, project.projectType, project.vendorId, project])

  const childProps: Props = {
    ...props,
    onChangeStatus,
    onChangeProjectType,
    onChangeVendorId,
    vendors: vendors || []
  }

  return <Component {...childProps} />
}

Container.displayName = 'Details'
export default Container
