import React, { createContext, useReducer } from 'react'

import Job from '../../lib/Job'

export type State = {
  selectedJobs: Job[]
  canCreateTranslationKit: boolean | null
  downloadingTranslationKit: boolean
  showSelectJobsDialog: boolean
  showRemoveJobsDialog: boolean
  showCreateTranslationKitDialog: boolean
  showUploadTranslatedXliffDialog: boolean
  showDownloadTranslatedFilesDialog: boolean
  showSendToMdmDialog: boolean
  showJobFiltersDialog: boolean
  toggleRefresh: boolean
  error: { isError: boolean; message: string }
}

export type Action =
  | { type: 'SET_SELECTED_JOBS'; payload: { selected: Job[] } }
  | { type: 'SET_CAN_CREATE_TRANSLATION_KIT'; payload: { canCreateTranslationKit: boolean | null } }
  | { type: 'SET_DOWNLOADING_TRANSLATION_KIT'; payload: { downloading: boolean } }
  | { type: 'SET_SHOW_SELECT_JOBS_DIALOG'; payload: { show: boolean } }
  | { type: 'SET_SHOW_REMOVE_JOBS_DIALOG'; payload: { show: boolean } }
  | { type: 'SET_SHOW_CREATE_TRANSLATION_KIT_DIALOG'; payload: { show: boolean } }
  | { type: 'SET_SHOW_UPLOAD_TRANSLATED_XLIFF_DIALOG'; payload: { show: boolean } }
  | { type: 'SET_SHOW_DOWNLOAD_TRANSLATED_FILES_DIALOG'; payload: { show: boolean } }
  | { type: 'SET_SHOW_SEND_TO_MDM_DIALOG'; payload: { show: boolean } }
  | { type: 'SET_SHOW_JOB_FILTERS_DIALOG'; payload: { show: boolean } }
  | { type: 'TOGGLE_REFRESH' }
  | { type: 'SET_ERROR'; payload: { isError: boolean; message: string } }

export const initialState: State = {
  selectedJobs: [],
  canCreateTranslationKit: null,
  downloadingTranslationKit: false,
  showSelectJobsDialog: false,
  showRemoveJobsDialog: false,
  showCreateTranslationKitDialog: false,
  showUploadTranslatedXliffDialog: false,
  showDownloadTranslatedFilesDialog: false,
  showSendToMdmDialog: false,
  showJobFiltersDialog: false,
  toggleRefresh: true,
  error: { isError: false, message: '' }
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_SELECTED_JOBS':
      return { ...state, selectedJobs: action.payload.selected }
    case 'SET_CAN_CREATE_TRANSLATION_KIT':
      return { ...state, canCreateTranslationKit: action.payload.canCreateTranslationKit }
    case 'SET_DOWNLOADING_TRANSLATION_KIT':
      return { ...state, downloadingTranslationKit: action.payload.downloading }
    case 'SET_SHOW_SELECT_JOBS_DIALOG':
      return { ...state, showSelectJobsDialog: action.payload.show }
    case 'SET_SHOW_REMOVE_JOBS_DIALOG':
      return { ...state, showRemoveJobsDialog: action.payload.show }
    case 'SET_SHOW_CREATE_TRANSLATION_KIT_DIALOG':
      return { ...state, showCreateTranslationKitDialog: action.payload.show }
    case 'SET_SHOW_UPLOAD_TRANSLATED_XLIFF_DIALOG':
      return { ...state, showUploadTranslatedXliffDialog: action.payload.show }
    case 'SET_SHOW_DOWNLOAD_TRANSLATED_FILES_DIALOG':
      return { ...state, showDownloadTranslatedFilesDialog: action.payload.show }
    case 'SET_SHOW_SEND_TO_MDM_DIALOG':
      return { ...state, showSendToMdmDialog: action.payload.show }
    case 'SET_SHOW_JOB_FILTERS_DIALOG':
      return { ...state, showJobFiltersDialog: action.payload.show }
    case 'TOGGLE_REFRESH':
      return { ...state, toggleRefresh: !state.toggleRefresh }
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload
      }
    default:
      return state
  }
}

export const useProjectDetailPage = (): {
  state: State
  dispatch: React.Dispatch<Action>
} => {
  const [state, dispatch] = useReducer(stateReducer, initialState)

  return { state, dispatch }
}

export const ProjectDetailPageContext = createContext(
  {} as {
    state: State
    dispatch: React.Dispatch<Action>
  }
)
