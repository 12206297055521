import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { ICellRendererParams } from 'ag-grid-community'

import { useLoginUser } from '../../../hooks/useLoginUser'
import EditTMDialog from '../../dialogs/EditTMDialog'

const EditTMButtonRenderer = (params: ICellRendererParams): JSX.Element => {
  const [showEditTMDialog, setShowEditTMDialog] = useState(false)

  const loginUser = useLoginUser()

  return (
    <>
      {!params.data && <img src="loading.gif" alt="Loading..." />}
      {params.data &&
        loginUser &&
        (loginUser.organizationType === 'cistate' ||
          loginUser.organizationId === params.data.ownerGroupId ||
          loginUser.organizationIds.includes(params.data.ownerGroupId)) && (
          <span>
            <a
              href="#"
              onClick={(e: React.MouseEvent): void => {
                e.preventDefault()
                setShowEditTMDialog(true)
              }}
              data-testid="edit-tm-button"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </a>
          </span>
        )}
      {showEditTMDialog && (
        <EditTMDialog
          close={(updated: boolean): void => {
            setShowEditTMDialog(false)
            if (updated) {
              window.location.reload()
            }
          }}
          tm={{
            _id: params.data._id,
            srcTmx: params.data.srcTmx,
            tgtTmx: params.data.tgtTmx,
            srcLang: params.data.srcLang,
            tgtLang: params.data.tgtLang,
            confidence: params.data.confidence,
            ownerGroupId: params.data.ownerGroupId,
            vendorId: params.data.vendorId,
            properties: {
              category: params.data.category
            },
            active: params.data.active
          }}
        />
      )}
    </>
  )
}

export default EditTMButtonRenderer
