import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'

import Project from '../../lib/Project'
import ProjectList from '../../components/ProjectList'
import Toolbar from './Toolbar'
import DeleteProjectsDialog from '../../components/dialogs/DeleteProjectsDialog'
import EditProjectsDialog from '../../components/dialogs/EditProjectDialog'
import ProjectFiltersDialog from '../../components/dialogs/ProjectFiltersDialog'
import { useProjectsPage, State, Action } from './useProjectsPage'

type ContainerProps = RouteComponentProps & {
  className?: string
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  updateSelection: (selected: Project[]) => void
}

export const Component: React.FC<Props> = (props: Props) => {
  const { state, dispatch, updateSelection } = props
  const { t } = useTranslation()

  return (
    <div className={props.className || 'Page'}>
      <Breadcrumb>
        <Breadcrumb.Item active data-testid="breadcrumb-project-list">
          {t('プロジェクト一覧')}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Toolbar state={state} dispatch={dispatch} />
      <ProjectList updateSelection={updateSelection} toggleRefresh={state.toggleRefresh} />
      {state.showDeleteProjectDialog && (
        <DeleteProjectsDialog
          close={(updated: boolean): void => {
            dispatch({ type: 'SET_SHOW_DELETE_PROJECT_DIALOG', payload: { show: false } })
            if (updated) {
              dispatch({ type: 'TOGGLE_REFRESH' })
              dispatch({ type: 'SET_SELECTED_PROJECTS', payload: { selectedProjects: [] } })
            }
          }}
          projects={state.selectedProjects}
        />
      )}
      {state.showEditProjectsDialog && (
        <EditProjectsDialog
          close={(updated: boolean): void => {
            dispatch({ type: 'SET_SHOW_EDIT_PROJECTS_DIALOG', payload: { show: false } })
            if (updated) {
              dispatch({ type: 'TOGGLE_REFRESH' })
              dispatch({ type: 'SET_SELECTED_PROJECTS', payload: { selectedProjects: [] } })
            }
          }}
          projects={state.selectedProjects}
        />
      )}
      {state.showProjectFiltersDialog && (
        <ProjectFiltersDialog
          close={(): void =>
            dispatch({ type: 'SET_SHOW_PROJECT_FILTERS_DIALOG', payload: { show: false } })
          }
          data-testid="project-filters-dialog"
        />
      )}
    </div>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { state, dispatch } = useProjectsPage()

  const updateSelection = (selected: Project[]): void => {
    dispatch({ type: 'SET_SELECTED_PROJECTS', payload: { selectedProjects: selected } })
  }

  return (
    <>
      <Component {...props} state={state} dispatch={dispatch} updateSelection={updateSelection} />
    </>
  )
}

Container.displayName = 'Projecs'
export default Container
