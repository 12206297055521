import React, { useReducer } from 'react'

import Project from '../../lib/Project'

export type State = {
  selectedProjects: Project[]
  showDeleteProjectDialog: boolean
  showEditProjectsDialog: boolean
  showProjectFiltersDialog: boolean
  toggleRefresh: boolean
}

export type Action =
  | { type: 'SET_SELECTED_PROJECTS'; payload: { selectedProjects: Project[] } }
  | { type: 'SET_SHOW_DELETE_PROJECT_DIALOG'; payload: { show: boolean } }
  | { type: 'SET_SHOW_EDIT_PROJECTS_DIALOG'; payload: { show: boolean } }
  | { type: 'SET_SHOW_PROJECT_FILTERS_DIALOG'; payload: { show: boolean } }
  | { type: 'TOGGLE_REFRESH' }

export const initialState: State = {
  selectedProjects: [],
  showDeleteProjectDialog: false,
  showEditProjectsDialog: false,
  showProjectFiltersDialog: false,
  toggleRefresh: true
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_SELECTED_PROJECTS': {
      return {
        ...state,
        selectedProjects: action.payload.selectedProjects
      }
    }
    case 'SET_SHOW_DELETE_PROJECT_DIALOG': {
      return {
        ...state,
        showDeleteProjectDialog: action.payload.show
      }
    }
    case 'SET_SHOW_EDIT_PROJECTS_DIALOG': {
      return {
        ...state,
        showEditProjectsDialog: action.payload.show
      }
    }
    case 'SET_SHOW_PROJECT_FILTERS_DIALOG': {
      return {
        ...state,
        showProjectFiltersDialog: action.payload.show
      }
    }
    case 'TOGGLE_REFRESH': {
      return {
        ...state,
        toggleRefresh: !state.toggleRefresh
      }
    }
    default: {
      return state
    }
  }
}

export const useProjectsPage = (): {
  state: State
  dispatch: React.Dispatch<Action>
} => {
  const [state, dispatch] = useReducer(stateReducer, initialState)

  return { state, dispatch }
}
