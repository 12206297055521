import React, { useReducer } from 'react'

export type State = {
  loading: boolean
  message: {
    isError: boolean
    text: string
  }
}

export type Action =
  | { type: 'SET_IS_LOADING'; payload: { isLoading: boolean } }
  | { type: 'SET_MESSAGE'; payload: { message: string; isError?: boolean } }

export const initialState: State = {
  loading: false,
  message: {
    isError: false,
    text: ''
  }
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_IS_LOADING': {
      return {
        ...state,
        loading: action.payload.isLoading
      }
    }
    case 'SET_MESSAGE': {
      return {
        ...state,
        message: {
          text: action.payload.message,
          isError: action.payload.isError ?? false
        }
      }
    }
    default: {
      return state
    }
  }
}

const useOKCancelDialog = (): {
  state: State
  dispatch: React.Dispatch<Action>
} => {
  const [state, dispatch] = useReducer(stateReducer, initialState)

  return {
    state,
    dispatch
  }
}

export default useOKCancelDialog
