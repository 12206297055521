import React from 'react'
import { Button, ButtonProps } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

type Props = ButtonProps & {
  onClick: () => void
  orderByAsc: boolean
  disabled?: boolean
}

export const ToggleSortOrderButton: React.FC<Props> = (props: Props) => {
  const { onClick, orderByAsc, disabled, size } = props
  const { t } = useTranslation()

  return (
    <Button
      className="ml-2"
      onClick={onClick}
      size={size}
      variant="light"
      style={{ pointerEvents: disabled ? 'none' : undefined }}
      disabled={disabled}
      data-testid="toggle-sort-order-button"
    >
      {orderByAsc ? t('昇順') : t('降順')}
    </Button>
  )
}

export default ToggleSortOrderButton
