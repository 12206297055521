import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AllCommunityModules } from '@ag-grid-community/all-modules'
import { AgGridReact } from '@ag-grid-community/react'

import useProjectList from './useProjectList'
import Project from '../../lib/Project'

type Props = RouteComponentProps & {
  updateSelection: (selected: Project[]) => void
  toggleRefresh?: boolean
}

const ProjectList: React.FC<Props> = (props: Props) => {
  const { updateSelection, toggleRefresh } = props
  const qs = props.location.search
  const { gridOptions } = useProjectList({
    updateSelection,
    toggleRefresh,
    qs
  })

  return (
    <div className="ag-theme-balham" style={{ height: '100%', width: '100%' }}>
      <AgGridReact gridOptions={gridOptions} modules={AllCommunityModules} />
    </div>
  )
}

export default withRouter(ProjectList)
