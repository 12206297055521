import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

type State = {
  srcLang: string
  tgtLang: string
  srcTmx: string
  tgtTmx: string
  ignoreCase: boolean
  mode: string
  confidence: string
  ownerGroupId: string
  vendorId: string
  category: string
  tmName: string
  tmType: string
  createdAtFrom: Date | null
  createdAtTo: Date | null
  active: boolean
  updatedAtFrom: Date | null
  updatedAtTo: Date | null
  showDetails: boolean
  createdBy: string
  updatedBy: string
}

type Action =
  | { type: 'UPDATE'; payload: Partial<State> }
  | { type: 'CLEAR' }
  | { type: 'TOGGLE_SHOW_DETAILS' }

const initialState: State = {
  srcLang: '',
  tgtLang: '',
  srcTmx: '',
  tgtTmx: '',
  ignoreCase: true,
  mode: 'phrase',
  confidence: '',
  ownerGroupId: '',
  vendorId: '',
  category: '',
  tmName: '',
  tmType: '',
  createdAtFrom: null,
  createdAtTo: null,
  active: false,
  updatedAtFrom: null,
  updatedAtTo: null,
  showDetails: false,
  createdBy: '',
  updatedBy: ''
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, ...action.payload }
    case 'CLEAR':
      return initialState
    case 'TOGGLE_SHOW_DETAILS':
      return { ...state, showDetails: !state.showDetails }
    default:
      return state
  }
}

const FiltersContext = createContext(
  {} as {
    state: State
    dispatch: React.Dispatch<Action>
    handleSubmit: () => void
    clearFilters: () => void
    countDetailedConditions: () => number
  }
)

export const FiltersProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const history = useHistory()
  const location = useLocation()

  const handleSubmit = (): void => {
    const query: { [key: string]: string | string[] | null | undefined | boolean } = {
      srcLang: state.srcLang,
      tgtLang: state.tgtLang,
      confidence: state.confidence?.toString(),
      ownerGroupId: state.ownerGroupId,
      vendorId: state.vendorId,
      '[properties.category]': state.category,
      '[properties.tmName][$regex]': state.tmName,
      '[properties.tmType][$regex]': state.tmType,
      'createdAt[$gte]': state.createdAtFrom?.toISOString(),
      'createdAt[$lte]': state.createdAtTo?.toISOString(),
      'updatedAt[$gte]': state.updatedAtFrom?.toISOString(),
      'updatedAt[$lte]': state.updatedAtTo?.toISOString(),
      active: state.active === true ? 'true' : undefined,
      'options[ignoreCase]': state.ignoreCase,
      createdBy: state.createdBy,
      updatedBy: state.updatedBy
    }

    if (['phrase', 'fuzzy', 'advanced'].includes(state.mode)) {
      query['[srcTmx][$regex]'] = state.srcTmx
      query['[tgtTmx][$regex]'] = state.tgtTmx
      query['options[mode]'] = state.mode
    }

    if (state.mode === 'perfect') {
      query['srcTmx.keyword'] = state.srcTmx
      query['tgtTmx.keyword'] = state.tgtTmx
    }

    Object.keys(query).forEach(key => {
      if (query[key] === '') {
        delete query[key]
      }
      return
    })

    const oldQuery = queryString.parse(location.search)
    if (Object.keys(oldQuery).length > 0) {
      Object.keys(oldQuery).forEach((key: string) => {
        if (key.startsWith('options[sort]')) {
          query[key] = oldQuery[key]
        }
      })
    }
    const qs = queryString.stringify(query)

    history.push(`/tm-entries${qs.length > 0 ? '?' : ''}${qs}`)
    return
  }

  const clearFilters = (): void => {
    dispatch({
      type: 'CLEAR'
    })
    localStorage.removeItem('TMListFilters')
    if (location.search.length > 0) {
      history.push('/tm-entries')
    }
    return
  }

  const countDetailedConditions = (): number => {
    const detailedConditionKeys = [
      'confidence',
      'ownerGroupId',
      'vendorId',
      '[properties.category]',
      '[properties.tmName][$regex]',
      '[properties.tmType][$regex]',
      'createdAt[$gte]',
      'createdAt[$lte]',
      'createdBy',
      'updatedAt[$gte]',
      'updatedAt[$lte]',
      'updatedBy',
      'active'
    ]

    const query = queryString.parse(location.search)

    const queryKeys = Object.keys(query)

    return queryKeys
      .filter(key => {
        return detailedConditionKeys.includes(key)
      })
      .reduce((acc: string[], val) => {
        if (val.match(/createdAt/)) {
          if (acc.includes('createdAt[$gte]') || acc.includes('createdAt[$lte]')) {
            return acc
          }
          return [...acc, val]
        }
        if (val.match(/updatedAt/)) {
          if (acc.includes('updatedAt[$gte]') || acc.includes('updatedAt[$lte]')) {
            return acc
          }
          return [...acc, val]
        }
        return [...acc, val]
      }, []).length
  }

  useEffect(() => {
    const query = queryString.parse(location.search)

    const newState: Partial<State> = {}

    if (typeof query['srcLang'] === 'string') {
      newState['srcLang'] = query['srcLang']
    }
    if (typeof query['[srcTmx][$regex]'] === 'string') {
      newState['srcTmx'] = query['[srcTmx][$regex]']
    }
    if (typeof query['srcTmx.keyword'] === 'string') {
      newState['srcTmx'] = query['srcTmx.keyword']
      newState['mode'] = 'perfect'
    }
    if (typeof query['tgtLang'] === 'string') {
      newState['tgtLang'] = query['tgtLang']
    }
    if (typeof query['[tgtTmx][$regex]'] === 'string') {
      newState['tgtTmx'] = query['[tgtTmx][$regex]']
    }
    if (typeof query['tgtTmx.keyword'] === 'string') {
      newState['tgtTmx'] = query['tgtTmx.keyword']
      newState['mode'] = 'perfect'
    }
    if (typeof query['options[ignoreCase]'] === 'string') {
      switch (query['options[ignoreCase]']) {
        case 'true':
          newState['ignoreCase'] = true
          break
        case 'false':
          newState['ignoreCase'] = false
          break
        default:
          break
      }
    }
    if (typeof query['options[mode]'] === 'string') {
      switch (query['options[mode]']) {
        case 'fuzzy':
          newState['mode'] = 'fuzzy'
          break
        case 'advanced':
          newState['mode'] = 'advanced'
          break
        default:
          break
      }
    }
    if (typeof query['confidence'] === 'string') {
      newState['confidence'] = query['confidence']
    }
    if (typeof query['ownerGroupId'] === 'string') {
      newState['ownerGroupId'] = query['ownerGroupId']
    }
    if (typeof query['vendorId'] === 'string') {
      newState['vendorId'] = query['vendorId']
    }
    if (typeof query['[properties.category]'] === 'string') {
      newState['category'] = query['[properties.category]']
    }
    if (typeof query['[properties.tmName][$regex]'] === 'string') {
      newState['tmName'] = query['[properties.tmName][$regex]']
    }
    if (typeof query['[properties.tmType][$regex]'] === 'string') {
      newState['tmType'] = query['[properties.tmType][$regex]']
    }
    if (query['active'] === 'true') {
      newState['active'] = true
    }
    if (typeof query['createdAt[$gte]'] === 'string') {
      newState['createdAtFrom'] = new Date(query['createdAt[$gte]'])
    }
    if (typeof query['createdAt[$lte]'] === 'string') {
      newState['createdAtTo'] = new Date(query['createdAt[$lte]'])
    }
    if (typeof query['createdBy'] === 'string') {
      newState['createdBy'] = query['createdBy']
    }
    if (typeof query['updatedAt[$gte]'] === 'string') {
      newState['updatedAtFrom'] = new Date(query['updatedAt[$gte]'])
    }
    if (typeof query['updatedAt[$lte]'] === 'string') {
      newState['updatedAtTo'] = new Date(query['updatedAt[$lte]'])
    }
    if (typeof query['updatedBy'] === 'string') {
      newState['updatedBy'] = query['updatedBy']
    }

    dispatch({
      type: 'UPDATE',
      payload: newState
    })
  }, [location])

  return (
    <FiltersContext.Provider
      value={{ state, dispatch, handleSubmit, clearFilters, countDetailedConditions }}
    >
      {children}
    </FiltersContext.Provider>
  )
}

export const FiltersConsumer = FiltersContext.Consumer

export const useFilters = (): {
  state: State
  dispatch: React.Dispatch<Action>
  handleSubmit: () => void
  clearFilters: () => void
  countDetailedConditions: () => number
} => {
  return useContext(FiltersContext)
}
