import React, { useState } from 'react'
import { ICellRendererParams } from 'ag-grid-community'

import EditUserDialog from '../dialogs/EditUserDialog'

const EmailRenderer = (params: ICellRendererParams): JSX.Element => {
  const [showEditUserDialog, setShowEditUserDialog] = useState(false)

  return (
    <>
      <span>
        <a
          href="#"
          onClick={(e: React.MouseEvent): void => {
            e.preventDefault()
            setShowEditUserDialog(true)
          }}
        >
          {params.value}
        </a>
      </span>
      {showEditUserDialog && (
        <EditUserDialog
          close={(updated): void => {
            setShowEditUserDialog(false)
            if (updated) {
              window.location.reload()
            }
          }}
          user={{
            _id: params.data._id,
            email: params.data.email,
            username: params.data.username,
            organizationType: params.data.organizationType,
            organizationIds: params.data.organizationIds,
            sendNotifications: params.data.sendNotifications,
            role: params.data.role,
            isEnabled: params.data.isEnabled,
            isApprover: params.data.isApprover,
            createdAt: params.data.createdAt,
            updatedAt: params.data.updatedAt
          }}
        />
      )}
    </>
  )
}

export default EmailRenderer
