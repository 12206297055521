import React from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

import config from '../../../config'
import Project from '../../../lib/Project'
import { useProjectDetailCard, EMPTY } from './useProjectDetailCard'
import { OverlayTriggerButton } from '../../../components/common/OverlayTriggerButton'
import { useLoginUser } from '../../../hooks/useLoginUser'

type ContainerProps = {
  project: Project
  refreshJobList: () => void
}

type Props = ContainerProps & {
  startEditing: () => void
  onFinishEditing: () => Promise<void>
  onCancelEditing: () => void
  showReturnSourceToMdmDialog: () => void
  showCancelProjectDialog: () => void
  showApproveOrderDialog: () => void
  showQuotedAmountDialog: () => void
  hasOrderCancelled: () => boolean
  hasOrderApproved: () => boolean
}

type RequestBody = {
  projectType?: string
  status?: string
  vendorId?: string | null // "なし" は null で表現
}

export const Component: React.FC<Props> = (props: Props) => {
  const {
    startEditing,
    onFinishEditing,
    onCancelEditing,
    showReturnSourceToMdmDialog,
    showCancelProjectDialog,
    showApproveOrderDialog,
    showQuotedAmountDialog,
    hasOrderCancelled,
    hasOrderApproved,
    project
  } = props
  const { state } = useProjectDetailCard()
  const loginUser = useLoginUser()
  const { t } = useTranslation()

  // vendorユーザーには[見積金額入力]ボタンのみ表示する
  if (loginUser?.organizationType === 'vendor') {
    return (
      <ButtonToolbar className="mb-3">
        {loginUser?.organizationType === 'vendor' && (
          <Button
            onClick={showQuotedAmountDialog}
            variant="secondary"
            data-testid="show-quoted-amount-dialog-button"
          >
            {t('見積金額入力')}
          </Button>
        )}
      </ButtonToolbar>
    )
  }

  if (state.editing) {
    return (
      <ButtonToolbar className="mb-3">
        <OverlayTriggerButton
          tooltipLabel={t('処理中')}
          buttonLabel={t('編集完了')}
          isOverlayed={(): boolean => state.updating}
          isLoading={(): boolean => state.updating}
          tooltipId="tooltip-editing-project-in-progress"
          variant="primary"
          onClick={onFinishEditing}
          data-testid="finish-editing-button"
        />
        <Button
          disabled={state.updating}
          className="ml-2"
          onClick={onCancelEditing}
          style={state.updating ? { pointerEvents: 'none' } : {}}
          variant="secondary"
          data-testid="cancel-editing-button"
        >
          {t('キャンセル')}
        </Button>
      </ButtonToolbar>
    )
  }

  return (
    <ButtonToolbar className="mb-3">
      <Button onClick={startEditing} variant="secondary" data-testid="edit-project-button">
        {t('プロジェクト編集')}
      </Button>
      <Button
        className="ml-2"
        disabled={hasOrderCancelled() || project.sourceReturnedAt != undefined}
        variant="danger"
        onClick={showReturnSourceToMdmDialog}
        style={hasOrderCancelled() || project.sourceReturnedAt ? { pointerEvents: 'none' } : {}}
        data-testid="return-source-to-mdm-button"
      >
        {t('ソース戻し')}
      </Button>
      <Button
        className="ml-2"
        disabled={hasOrderCancelled() || hasOrderApproved()}
        onClick={showCancelProjectDialog}
        variant="danger"
        style={hasOrderCancelled() || hasOrderApproved() ? { pointerEvents: 'none' } : {}}
        data-testid="cancel-project-button"
      >
        {t('発注キャンセル')}
      </Button>
      <Button
        className="ml-2"
        disabled={hasOrderCancelled() || hasOrderApproved()}
        onClick={showApproveOrderDialog}
        variant="secondary"
        style={hasOrderCancelled() || hasOrderApproved() ? { pointerEvents: 'none' } : {}}
        data-testid="approve-order-button"
      >
        {t('発注承認')}
      </Button>
    </ButtonToolbar>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { project, refreshJobList } = props
  const { state, dispatch } = useProjectDetailCard()

  const startEditing = (): void => {
    dispatch({ type: 'SET_EDITING', payload: { editing: true } })
  }

  const onFinishEditing = async (): Promise<void> => {
    dispatch({ type: 'SET_UPDATING', payload: { updating: true } })

    const requestBody: RequestBody = {}

    if (state.formData.status !== project.status && state.formData.status !== '') {
      requestBody.status = state.formData.status
    }

    if (state.formData.projectType !== project.projectType && state.formData.projectType !== '') {
      requestBody.projectType = state.formData.projectType
    }

    if (state.formData.vendorId !== project.vendorId) {
      requestBody.vendorId = state.formData.vendorId === EMPTY ? null : state.formData.vendorId
    }

    if (Object.keys(requestBody).length > 0) {
      await axios.patch(
        `${config[config.STAGE].endpoint}/api/v1/projects/${project._id}`,
        requestBody
      )
      dispatch({ type: 'SET_UPDATING', payload: { updating: false } })
      dispatch({ type: 'SET_EDITING', payload: { editing: false } })
      refreshJobList()
    } else {
      dispatch({ type: 'SET_UPDATING', payload: { updating: false } })
      dispatch({ type: 'SET_EDITING', payload: { editing: false } })
    }
  }

  const onCancelEditing = (): void => {
    dispatch({ type: 'SET_EDITING', payload: { editing: false } })
    dispatch({
      type: 'SET_FORM_VALUE',
      payload: {
        status: project.status ? project.status : '',
        projectType: project.projectType ? project.projectType : '',
        vendorId: project.vendorId ? project.vendorId : EMPTY
      }
    })
  }

  const showReturnSourceToMdmDialog = (): void => {
    dispatch({
      type: 'SET_SHOW_RETURN_SOURCE_TO_MDM_DIALOG',
      payload: { show: true }
    })
  }

  const showCancelProjectDialog = (): void => {
    dispatch({
      type: 'SET_SHOW_CANCEL_PROJECT_DIALOG',
      payload: { show: true }
    })
  }

  const showApproveOrderDialog = (): void => {
    dispatch({
      type: 'SET_SHOW_APPROVE_ORDER_DIALOG',
      payload: { show: true }
    })
  }

  const showQuotedAmountDialog = (): void => {
    dispatch({
      type: 'SET_SHOW_QUOTED_AMOUNT_DIALOG',
      payload: { show: true }
    })
  }

  const hasOrderCancelled = (): boolean => {
    if (project.cancelledAt || project.cancelledBy) {
      return true
    }
    return false
  }

  const hasOrderApproved = (): boolean => {
    if (project.approvedAt || project.approvedBy) {
      return true
    }
    return false
  }

  const childProps: Props = {
    ...props,
    startEditing,
    onFinishEditing,
    onCancelEditing,
    showReturnSourceToMdmDialog,
    showCancelProjectDialog,
    showApproveOrderDialog,
    showQuotedAmountDialog,
    hasOrderCancelled,
    hasOrderApproved
  }

  return <Component {...childProps} />
}

Container.displayName = 'Buttons'
export default Container
