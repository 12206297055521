import React from 'react'
import { Spinner, Container, Row } from 'react-bootstrap'

export const CenteredSpinner: React.FC = () => {
  return (
    <Container className="d-flex h-100 justify-content-center">
      <Row className="align-self-center">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Row>
    </Container>
  )
}
