import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { AllCommunityModules } from '@ag-grid-community/all-modules'
import { AgGridReact } from '@ag-grid-community/react'

import Job from '../../lib/Job'
import useJobList from './useJobList'

type Props = RouteComponentProps & {
  options?: string
  showDownloadButtonColumn?: boolean
  showOpenEditorButtonColumn?: boolean
  showProjectInfoColumns?: boolean
  showUnitStatisticsColumns?: boolean
  updateSelection?: (selected: Job[]) => void
  showJobNameAsLink?: boolean
  toggleRefresh?: boolean
}

const JobList: React.FC<Props> = (props: Props) => {
  const {
    updateSelection,
    showJobNameAsLink,
    showDownloadButtonColumn,
    showOpenEditorButtonColumn,
    showProjectInfoColumns,
    showUnitStatisticsColumns,
    options,
    toggleRefresh
  } = props
  const qs = props.location.search
  const pathname = props.location.pathname
  const showCheckBoxes = updateSelection ? true : false

  const { gridOptions } = useJobList({
    showCheckBoxes,
    showJobNameAsLink,
    showDownloadButtonColumn,
    showOpenEditorButtonColumn,
    showProjectInfoColumns,
    showUnitStatisticsColumns,
    qs,
    pathname,
    options,
    toggleRefresh,
    updateSelection
  })

  return (
    <div
      data-testid="job-list"
      className="ag-theme-balham"
      style={{ height: '100%', width: '100%' }}
    >
      <AgGridReact gridOptions={gridOptions} modules={AllCommunityModules} />
    </div>
  )
}

export default withRouter(JobList)
