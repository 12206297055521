import React from 'react'
import { Toast } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useTMEntriesPage } from './useTMEntriesPage'

const ErrorMessageToast: React.FC = () => {
  const { t } = useTranslation()

  const { state, dispatch } = useTMEntriesPage()

  return (
    <Toast
      onClose={(): void => {
        dispatch({
          type: 'UPDATE',
          payload: {
            ...state,
            showToast: false
          }
        })
      }}
      show={state.showToast}
      style={{
        position: 'absolute',
        top: 10,
        left: 10
      }}
    >
      <Toast.Header>
        <span className="mr-auto">{t('エラー')}</span>
      </Toast.Header>
      <Toast.Body>{t('編集結果を保存できませんでした。')}</Toast.Body>
    </Toast>
  )
}

export default ErrorMessageToast
