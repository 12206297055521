import React from 'react'
import { Button, Form, Modal, Row, Col } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'

import path from 'path'

import Job from '../../../lib/Job'
import { OverlayTriggerButton } from '../../common/OverlayTriggerButton'
import { BackendError } from '../../../lib/BackendError'
import useEditJobsDialog, { State, Action } from './useEditJobsDialog'

type ContainerProps = {
  close: (updated: boolean) => void
  jobs: Job[]
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  onKeyPress: (e: React.KeyboardEvent<HTMLFormElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
}

export const Component: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { close, handleSubmit, jobs, state, dispatch } = props

  const canExecute = (): boolean =>
    state.formData.status !== '' ||
    !state.dueAtDisabled ||
    !state.startReviewAtDisabled ||
    !state.reviewDueAtDisabled
  let runButton
  if (canExecute()) {
    runButton = (
      <OverlayTriggerButton
        tooltipLabel={t('処理中')}
        buttonLabel={t('Button_完了')}
        isOverlayed={(): boolean => state.loading}
        isLoading={(): boolean => state.loading}
        tooltipId="tooltip-editing-jobs-in-progress"
        variant="primary"
        type="submit"
        data-testid="edit-jobs-button"
      />
    )
  } else {
    runButton = (
      <OverlayTriggerButton
        tooltipLabel={t('少なくとも1つのフィールドに値を入力してください')}
        buttonLabel={t('Button_完了')}
        isOverlayed={(): boolean => true}
        tooltipId="tooltip-cannot-execute"
        variant="primary"
        data-testid="edit-jobs-button"
      />
    )
  }

  return (
    <Modal onHide={(): void => close(false)} show={true} size="lg">
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>{t('ジョブ編集')}</Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} controlId="jobs">
            <Form.Label column sm={4}>
              {t('編集するジョブ')} ({jobs.length}):
            </Form.Label>
            <Col sm={8}>
              <Form.Control readOnly as="select" multiple data-testid="selected-jobs-list">
                {props.jobs.map(job => (
                  <option key={job._id}>{path.basename(job.name)}</option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="status">
            <Form.Label column sm={4}>
              {t('ステータス')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                disabled={state.loading}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: {
                      status: e.currentTarget.value
                    }
                  })
                }
                data-testid="select-status"
              >
                <option value="">{t('(変更なし)')}</option>
                <option value="prepared">{t('準備処理完了')}</option>
                <option value="order_confirmed">{t('発注確定')}</option>
                <option value="translated">{t('翻訳済み')}</option>
                <option value="reviewed">{t('レビュー済み')}</option>
                <option value="completed">{t('完了')}</option>
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="dueAt">
            <Form.Label column sm={4}>
              {t('希望完了日時')}:
            </Form.Label>
            <Col sm="auto">
              <DatePicker
                dateFormat="yyyy-MM-dd HH:mm"
                disabled={state.loading || state.dueAtDisabled}
                onChange={(date): void => {
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: {
                      dueAt: date
                    }
                  })
                }}
                selected={state.formData.dueAt}
                showTimeSelect
                className="form-control"
                isClearable
                placeholderText={t('なし')}
                customInput={<input data-testid="due-date-picker" />}
              />
            </Col>
            <Col sm="auto">
              <Form.Check
                id="due-at-readonly-checkbox"
                label={t('変更なし')}
                className="mt-2"
                checked={state.dueAtDisabled}
                onChange={(): void => {
                  dispatch({
                    type: 'TOGGLE_DUE_AT_DISABLED'
                  })
                }}
                data-testid="keep-due-date-checkbox"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="startReviewAt">
            <Form.Label column sm={4}>
              {t('レビュー予定日')}:
            </Form.Label>
            <Col sm="auto">
              <DatePicker
                dateFormat="yyyy-MM-dd HH:mm"
                disabled={state.loading || state.startReviewAtDisabled}
                onChange={(date): void => {
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: {
                      startReviewAt: date
                    }
                  })
                }}
                selected={state.formData.startReviewAt}
                showTimeSelect
                className="form-control"
                isClearable
                placeholderText={t('なし')}
                customInput={<input data-testid="review-date-picker" type="text" />}
              />
            </Col>
            <Col sm="auto">
              <Form.Check
                id="start-review-at-readonly-checkbox"
                label={t('変更なし')}
                className="mt-2"
                checked={state.startReviewAtDisabled}
                onChange={(): void => {
                  dispatch({
                    type: 'TOGGLE_START_REVIEW_AT_DISABLED'
                  })
                }}
                data-testid="keep-review-date-checkbox"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="reviewDueAt">
            <Form.Label column sm={4}>
              {t('レビューFB予定日')}:
            </Form.Label>
            <Col sm="auto">
              <DatePicker
                dateFormat="yyyy-MM-dd HH:mm"
                disabled={state.loading || state.reviewDueAtDisabled}
                onChange={(date): void => {
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: {
                      reviewDueAt: date
                    }
                  })
                }}
                selected={state.formData.reviewDueAt}
                showTimeSelect
                className="form-control"
                isClearable
                placeholderText={t('なし')}
                customInput={<input data-testid="review-fb-date-picker" type="text" />}
              />
            </Col>
            <Col sm="auto">
              <Form.Check
                id="review-due-at-readonly-checkbox"
                label={t('変更なし')}
                className="mt-2"
                checked={state.reviewDueAtDisabled}
                onChange={(): void => {
                  dispatch({
                    type: 'TOGGLE_REVIEW_DUE_AT_DISABLED'
                  })
                }}
                data-testid="keep-review-fb-date-checkbox"
              />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {state.message.isError ? (
            <span style={{ color: 'red' }}>{state.message.text}</span>
          ) : (
            <span>{state.message.text}</span>
          )}
          {runButton}
          <Button
            disabled={state.loading}
            onClick={(): void => props.close(false)}
            style={state.loading ? { pointerEvents: 'none' } : {}}
            variant="secondary"
          >
            {t('キャンセル')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { jobs } = props
  const { state, dispatch, handleSubmit: updateJobs } = useEditJobsDialog(jobs)

  const close = (updated: boolean): void => {
    if (!state.loading) {
      props.close(updated)
    }
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' /* Enter */) {
      e.preventDefault()
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    try {
      await updateJobs()
      close(true)
    } catch (error) {
      if (error.response) {
        const errorInfo: BackendError = error.response.data
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: errorInfo.message, isError: true }
        })
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: error.message, isError: true }
        })
      }
    } finally {
      dispatch({ type: 'SET_IS_LOADING', payload: { loading: false } })
    }
  }

  return (
    <Component
      {...props}
      close={close}
      state={state}
      dispatch={dispatch}
      onKeyPress={onKeyPress}
      handleSubmit={handleSubmit}
    />
  )
}

Container.displayName = 'EditJobsDialog'
export default Container
