import React from 'react'

import { useTMEntriesPage } from './useTMEntriesPage'
import EditTMDialog from '../../components/dialogs/EditTMDialog'
import ImportTmxDialog from '../../components/dialogs/ImportDialog'
import ExportDialog from '../../components/dialogs/ExportDialog'
import DeleteTMEntriesDialog from '../../components/dialogs/DeleteTMEntriesDialog'
import SearchModeHelpDialog from '../../components/dialogs/SearchModeHelpDialog'
import { useLoginUser } from '../../hooks/useLoginUser'

const Dialogs: React.FC = () => {
  const { state, dispatch } = useTMEntriesPage()
  const loginUser = useLoginUser()

  return (
    <>
      {state.showEditTMDialog && (
        <EditTMDialog
          close={(updated: boolean): void => {
            dispatch({ type: 'UPDATE', payload: { showEditTMDialog: false } })
            if (updated) {
              dispatch({ type: 'REFRESH_TM_LIST' })
            }
          }}
        />
      )}
      {state.showImportDialog && (
        <ImportTmxDialog
          close={(): void => {
            dispatch({ type: 'UPDATE', payload: { showImportDialog: false } })
          }}
        />
      )}
      {state.showExportDialog && (
        <ExportDialog
          close={(): void => {
            dispatch({ type: 'UPDATE', payload: { showExportDialog: false } })
          }}
        />
      )}
      {state.showDeleteTMEntriesDialog && loginUser?.role === 'admin' && (
        <DeleteTMEntriesDialog
          allChecked={state.allChecked}
          excludeIds={state.excludeIds}
          selectedTMEntries={state.selectedTMEntries}
          onClose={(updated: boolean): void => {
            dispatch({ type: 'UPDATE', payload: { showDeleteTMEntriesDialog: false } })
            if (updated) {
              dispatch({ type: 'REFRESH_TM_LIST' })
            }
          }}
        />
      )}
      {state.showSearchModeHelpDialog && (
        <SearchModeHelpDialog
          onClose={(): void => {
            dispatch({ type: 'UPDATE', payload: { showSearchModeHelpDialog: false } })
          }}
        />
      )}
    </>
  )
}

export default Dialogs
