import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useUsersPage, State, Action } from './useUsersPage'
import User from '../../../lib/User'
import Toolbar from './Toolbar'
import UserList from '../../../components/UserList'
import EditUserDialog from '../../../components/dialogs/EditUserDialog'

type Props = {
  state: State
  dispatch: React.Dispatch<Action>
  updateSelection: (selected: User[]) => void
}

export const Component: React.FC<Props> = (props: Props) => {
  const { state, dispatch, updateSelection } = props
  const { t } = useTranslation()

  return (
    <div className="Page">
      <Breadcrumb>
        <Breadcrumb.Item active>{t('ユーザー一覧')}</Breadcrumb.Item>
      </Breadcrumb>
      <Toolbar
        selectedUsers={state.selectedUsers}
        showAddUserDialog={(): void => {
          dispatch({
            type: 'SET_SHOW_ADD_USER_DIALOG',
            payload: { show: true }
          })
        }}
      />
      <UserList updateSelection={updateSelection} toggleRefresh={state.refreshUserList} />
      {state.showAddUserDialog && (
        <EditUserDialog
          close={(updated: boolean): void => {
            dispatch({
              type: 'SET_SHOW_ADD_USER_DIALOG',
              payload: { show: false }
            })
            if (updated) {
              dispatch({
                type: 'SET_REFRESH_USER_LIST',
                payload: { refreshUserList: !state.refreshUserList }
              })
              dispatch({
                type: 'SET_SELECTED_USERS',
                payload: { selectedUsers: [] }
              })
            }
          }}
        />
      )}
    </div>
  )
}

const Container: React.FC = () => {
  const { state, dispatch } = useUsersPage()

  const updateSelection = (selected: User[]): void => {
    dispatch({
      type: 'SET_SELECTED_USERS',
      payload: {
        selectedUsers: selected
      }
    })
  }

  return <Component state={state} dispatch={dispatch} updateSelection={updateSelection} />
}

Container.displayName = 'Users'
export default Container
