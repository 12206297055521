import React from 'react'
import { useTranslation } from 'react-i18next'
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap'

import { TMEntriesPageProvider, useTMEntriesPage } from './useTMEntriesPage'
import Toolbar from './Toolbar'
import Filters from './Filters'
import TMList from '../../components/TMList'
import Dialogs from './Dialogs'
import ErrorMessageToast from './ErrorMessageToast'

const Component: React.FC = () => {
  const { state, dispatch } = useTMEntriesPage()
  const { t } = useTranslation()

  return (
    <div className={'Page'}>
      <Breadcrumb>
        <Breadcrumb.Item active>{t('TMエントリ一覧')}</Breadcrumb.Item>
      </Breadcrumb>
      <Container fluid>
        <Row>
          <Col sm={2}>
            <Filters />
          </Col>
          <Col sm={10}>
            <Toolbar />
            <div style={{ height: 'calc(100vh - 56px - 48px - 58px - 36px - 12px - 16px)' }}>
              <TMList
                toggleRefresh={state.refreshTMList}
                inlineEditMode={state.inlineEdit}
                updateSelection={(selected): void => {
                  dispatch({ type: 'UPDATE', payload: { selectedTMEntries: selected } })
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Dialogs />
      <ErrorMessageToast />
    </div>
  )
}

const Wrapper: React.FC = () => {
  return (
    <TMEntriesPageProvider>
      <Component />
    </TMEntriesPageProvider>
  )
}

Wrapper.displayName = 'TMEntries'
export default Wrapper
