import { useCallback, useReducer } from 'react'
import axios from 'axios'

import config from '../../../config'
import Project from '../../../lib/Project'

export type FormData = {
  status: string
}

export type State = {
  loading: boolean
  message: {
    isError: boolean
    text: string
  }
  formData: FormData
}

export type Action =
  | { type: 'SET_IS_LOADING'; payload: { loading: boolean } }
  | { type: 'SET_MESSAGE'; payload: { message: string; isError?: boolean } }
  | { type: 'SET_FORM_VALUE'; payload: { status: string } }

export const UNCHANGED = 'local-unchanged'

export const initialState = {
  loading: false,
  message: {
    isError: false,
    text: ''
  },
  formData: {
    status: UNCHANGED
  }
}

type RequestBody = {
  status?: string
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_IS_LOADING': {
      return {
        ...state,
        loading: action.payload.loading
      }
    }
    case 'SET_MESSAGE': {
      return {
        ...state,
        message: {
          text: action.payload.message,
          isError: action.payload.isError ?? false
        }
      }
    }
    case 'SET_FORM_VALUE': {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload
        }
      }
    }
    default: {
      return state
    }
  }
}

const useEditProjectDialog = (
  projects: Project[]
): { state: State; dispatch: React.Dispatch<Action>; handleSubmit: () => Promise<void> } => {
  const [state, dispatch] = useReducer(stateReducer, initialState)

  const handleSubmit = useCallback(async () => {
    dispatch({ type: 'SET_IS_LOADING', payload: { loading: true } })
    dispatch({ type: 'SET_MESSAGE', payload: { message: '', isError: false } })

    const requestBody: RequestBody = {}

    if (state.formData.status !== UNCHANGED) {
      requestBody.status = state.formData.status
    }

    await Promise.all(
      projects.map(project => {
        return axios.patch(
          `${config[config.STAGE].endpoint}/api/v1/projects/${project._id}`,
          requestBody
        )
      })
    )
  }, [projects, state.formData])

  return { state, dispatch, handleSubmit }
}

export default useEditProjectDialog
