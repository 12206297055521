import React from 'react'
import { useTranslation } from 'react-i18next'

import { ProjectDetailCardProvider } from './useProjectDetailCard'
import Project from '../../../lib/Project'
import FoldableCard from '../../../components/common/FoldableCard'
import Buttons from './Buttons'
import Details from './Details'
import Dialogs from './Dialogs'

type Props = {
  project: Project
  refreshJobList: () => void
}

const ProjectDetailCard: React.FC<Props> = (props: Props) => {
  const { project, refreshJobList } = props
  const { t } = useTranslation()

  return (
    <ProjectDetailCardProvider>
      <FoldableCard title={t('プロジェクト情報')}>
        <Buttons project={project} refreshJobList={refreshJobList} />
        <Details project={project} />
        <Dialogs project={project} refreshJobList={refreshJobList} />
      </FoldableCard>
    </ProjectDetailCardProvider>
  )
}

export default ProjectDetailCard
