import React, { useContext } from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { JobsPageContext } from '../useJobsPage'
import RegisterProjectButton from './RegisterProjectButton'
import ExportCsvButton from './ExportCsvButton'
import FilterAndSort from './FilterAndSort'
import { useLoginUser } from '../../../hooks/useLoginUser'
import { OverlayTriggerButton } from '../../../components/common/OverlayTriggerButton'

type Props = {
  areProjectIdsOfSelectedJobsNotSet: () => boolean
  areOriginsOfSelectedJobsDashboard: () => boolean
}

export const Component: React.FC<Props> = (props: Props) => {
  const { areProjectIdsOfSelectedJobsNotSet, areOriginsOfSelectedJobsDashboard } = props
  const { state, dispatch } = useContext(JobsPageContext)
  const { t } = useTranslation()
  const loginUser = useLoginUser()

  return (
    <div>
      <ButtonToolbar className="ml-3 mr-3 mb-3">
        <RegisterProjectButton />
        <Button
          className="ml-2"
          onClick={(): void =>
            dispatch({ type: 'SET_SHOW_REGISTER_JOBS_DIALOG', payload: { show: true } })
          }
          variant="primary"
          data-testid="register-jobs-button"
        >
          {t('ジョブ登録')}
        </Button>
        <OverlayTriggerButton
          buttonClassName="ml-2"
          variant="secondary"
          isOverlayed={(): boolean => {
            return state.selectedJobs.length === 0
          }}
          buttonLabel={t('選択したジョブを編集')}
          tooltipId="tooltip-editjobs-disabled"
          tooltipLabel={t('ジョブが選択されていません')}
          onClick={(): void =>
            dispatch({ type: 'SET_SHOW_EDIT_JOBS_DIALOG', payload: { show: true } })
          }
          data-testid="edit-selected-jobs-button"
        />
        {loginUser?.organizationType === 'cistate' && loginUser?.role === 'admin' && (
          <OverlayTriggerButton
            buttonClassName="ml-2"
            buttonLabel={t('選択したジョブを削除')}
            isOverlayed={(): boolean => {
              return !(
                (state.selectedJobs.length === 0 ? false : true) &&
                areOriginsOfSelectedJobsDashboard() &&
                areProjectIdsOfSelectedJobsNotSet()
              )
            }}
            onClick={(): void =>
              dispatch({ type: 'SET_SHOW_DELETE_JOBS_DIALOG', payload: { show: true } })
            }
            tooltipId="delete-jobs-button-tooltip"
            tooltipLabel={
              state.selectedJobs.length === 0
                ? t('ジョブが選択されていません')
                : '削除できないジョブが含まれています'
            }
            variant="danger"
            data-testid="delete-selected-jobs-button"
          />
        )}
        <ExportCsvButton />
        <FilterAndSort />
      </ButtonToolbar>
    </div>
  )
}

const Container: React.FC = () => {
  const { state } = useContext(JobsPageContext)

  const areProjectIdsOfSelectedJobsNotSet = (): boolean => {
    const projectIdTypes = state.selectedJobs.map(job => {
      return typeof job.projectId
    })

    return projectIdTypes.indexOf('string') === -1 ? true : false
  }

  const areOriginsOfSelectedJobsDashboard = (): boolean => {
    return (
      state.selectedJobs.map(job => job.origin).filter(origin => origin !== 'dashboard').length ===
      0
    )
  }

  return (
    <Component
      areProjectIdsOfSelectedJobsNotSet={areProjectIdsOfSelectedJobsNotSet}
      areOriginsOfSelectedJobsDashboard={areOriginsOfSelectedJobsDashboard}
    />
  )
}

Container.displayName = 'Toolbar'
export default Container
