import { Dispatch, useCallback, useEffect, useReducer, useState } from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import qs from 'query-string'

import config from '../../../config'

type FormData = {
  queryString: string
  exportFormat: 'xlsx' | 'tmx'
}

export type State = {
  loading: boolean
  message: {
    isError: boolean
    text: string
  }
  formData: FormData
}

export type Action =
  | { type: 'SET_IS_LOADING'; payload: { loading: boolean } }
  | { type: 'SET_MESSAGE'; payload: { message: string; isError?: boolean } }
  | { type: 'SET_FORM_VALUE'; payload: Partial<FormData> }

export const initialState: State = {
  loading: false,
  message: {
    isError: false,
    text: ''
  },
  formData: {
    queryString: '',
    exportFormat: 'xlsx'
  }
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_IS_LOADING': {
      return {
        ...state,
        loading: action.payload.loading
      }
    }
    case 'SET_MESSAGE': {
      return {
        ...state,
        message: {
          text: action.payload.message,
          isError: action.payload.isError ?? false
        }
      }
    }
    case 'SET_FORM_VALUE': {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload
        }
      }
    }
    default: {
      return state
    }
  }
}

type UseExportDialog = {
  (queryString: string): {
    state: State
    dispatch: Dispatch<Action>
    handleSubmit: () => Promise<void>
  }
}

export const useExportDialog: UseExportDialog = queryString => {
  const [state, dispatch] = useReducer(stateReducer, initialState)
  const [searchCount, setSearchCount] = useState(0)
  const xlsxMaxRows = 500000
  const { t } = useTranslation()

  useEffect(() => {
    axios
      .get(`${config[config.STAGE].endpoint}/api/v1/tmEntries/count${queryString}`)
      .then(result => setSearchCount(result.data.count))
      .catch(error => {
        console.log({ error })
        setSearchCount(0)
      })
  }, [queryString])

  useEffect(() => {
    dispatch({
      type: 'SET_FORM_VALUE',
      payload: {
        queryString
      }
    })
    const queries = qs.parse(queryString)
    if (state.formData.exportFormat === 'tmx' && !Object.keys(queries).includes('srcLang')) {
      dispatch({
        type: 'SET_MESSAGE',
        payload: {
          message: t('ソース言語が指定されていません。'),
          isError: true
        }
      })
    } else if (state.formData.exportFormat === 'xlsx' && searchCount > xlsxMaxRows) {
      dispatch({
        type: 'SET_MESSAGE',
        payload: {
          message: t('XLSX最大レコード数警告', {
            currentCount: searchCount,
            maxRows: xlsxMaxRows
          }),
          isError: false
        }
      })
    } else {
      dispatch({
        type: 'SET_MESSAGE',
        payload: {
          message: '',
          isError: false
        }
      })
    }
  }, [searchCount, queryString, state.formData.exportFormat, t])

  const handleSubmit = useCallback(async (): Promise<void> => {
    const { queryString, exportFormat } = state.formData

    await axios.post(`${config[config.STAGE].endpoint}/api/v1/tmEntries/export${queryString}`, {
      exportFormat
    })
  }, [state.formData])

  return {
    state,
    dispatch,
    handleSubmit
  }
}
