import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AllCommunityModules } from '@ag-grid-community/all-modules'
import { AgGridReact } from '@ag-grid-community/react'

import useVendorList from './useVendorList'
import Vendor from '../../lib/Vendor'

type Props = RouteComponentProps & {
  enableEdit?: boolean
  options?: string
  selected?: string[]
  showAllColumns?: boolean
  showOwnerGroupNameInNameColumn?: boolean
  updateSelection?: (selected: Vendor[]) => void
  visible?: boolean
  toggleRefresh?: boolean
}

const VendorList: React.FC<Props> = (props: Props) => {
  const {
    options,
    updateSelection,
    selected,
    enableEdit,
    showAllColumns,
    showOwnerGroupNameInNameColumn,
    toggleRefresh
  } = props
  const qs = props.location.search

  const { gridOptions } = useVendorList({
    qs,
    options,
    updateSelection,
    selected,
    enableEdit,
    showAllColumns,
    showOwnerGroupNameInNameColumn,
    toggleRefresh
  })

  return (
    <div
      data-testid="vendor-list"
      className="ag-theme-balham"
      style={{
        height: '100%',
        width: '100%',
        display: props.visible === false ? 'none' : undefined
      }}
    >
      <AgGridReact gridOptions={gridOptions} modules={AllCommunityModules} />
    </div>
  )
}

export default withRouter(VendorList)
