import Vendor from '../../lib/Vendor'

type RowData = {
  _id: string
  name: string
  ownerGroupId: string | undefined
  ownerGroupName: string
  srcLang: string
  tgtLang: string
  unitPrice: number
  currency: string
  multipliersRepetitions: number
  multipliersExact: number
  multipliersOver95: number
  multipliersOver85: number
  multipliersOver75: number
  multipliersNoMatch: number
  isEnabled: boolean
}

export const VendorToRowData = (
  vendor: Vendor,
  enableEdit: boolean,
  showOwnerGroupNameInNameColumn: boolean
): RowData => {
  return {
    _id: vendor._id,
    name: showOwnerGroupNameInNameColumn
      ? `${vendor.name} (${vendor.ownerGroup?.name})`
      : enableEdit === true
      ? vendor.name
      : `${vendor.name} (${vendor.ownerGroup?.name})`,
    ownerGroupId: vendor.ownerGroupId,
    ownerGroupName: vendor.ownerGroup ? vendor.ownerGroup.name : '',
    srcLang: vendor.srcLang,
    tgtLang: vendor.tgtLang,
    unitPrice: vendor.unitPrice,
    currency: vendor.currency,
    multipliersRepetitions: vendor.multipliers && vendor.multipliers.repetitions,
    multipliersExact: vendor.multipliers && vendor.multipliers.exact,
    multipliersOver95: vendor.multipliers && vendor.multipliers.over95,
    multipliersOver85: vendor.multipliers && vendor.multipliers.over85,
    multipliersOver75: vendor.multipliers && vendor.multipliers.over75,
    multipliersNoMatch: vendor.multipliers && vendor.multipliers.noMatch,
    isEnabled: vendor.isEnabled
  }
}

export default RowData
