import axios from 'axios'
import config from '../config'
import Project from '../lib/Project'
import { useState, useEffect } from 'react'

type useRecalculateOptions = {
  projectId: string
  toggleRefresh?: boolean
}
type useRecalculate = (
  options: useRecalculateOptions
) => { project: Project | null; loading: boolean; error: Error | null }

export const useRecalculate: useRecalculate = ({ projectId, toggleRefresh }) => {
  const [project, setProject] = useState<Project | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const load = async (): Promise<void> => {
      setLoading(true)
      try {
        const projectResponse = await axios.post<Project>(
          `${config[config.STAGE].endpoint}/api/v1/projects/${projectId}/recalculate`
        )
        setProject(projectResponse.data)
        setError(null)
      } catch (error) {
        console.log(JSON.stringify(error, null, 2))
        setError(error as Error)
      }
      setLoading(false)
    }
    load()
  }, [projectId, toggleRefresh])

  return { project, loading, error }
}
