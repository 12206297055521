import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useVendorsPage, State, Action } from './useVendorsPage'
import Vendor from '../../../lib/Vendor'
import Toolbar from './Toolbar'
import VendorList from '../../../components/VendorList'
import EditVendorDialog from '../../../components/dialogs/EditVendorDialog'

type Props = {
  state: State
  dispatch: React.Dispatch<Action>
  updateSelection: (selected: Vendor[]) => void
}

export const Component: React.FC<Props> = (props: Props) => {
  const { state, dispatch, updateSelection } = props
  const { t } = useTranslation()

  return (
    <div className="Page">
      <Breadcrumb>
        <Breadcrumb.Item active>{t('翻訳会社一覧')}</Breadcrumb.Item>
      </Breadcrumb>
      <Toolbar
        showAddVendorDialog={(): void => {
          dispatch({ type: 'SET_SHOW_ADD_VENDOR_DIALOG', payload: { show: true } })
        }}
      />
      <VendorList
        enableEdit={true}
        showAllColumns
        updateSelection={updateSelection}
        toggleRefresh={state.refreshVendorList}
      />
      {state.showAddVendorDialog && (
        <EditVendorDialog
          close={(updated: boolean): void => {
            dispatch({ type: 'SET_SHOW_ADD_VENDOR_DIALOG', payload: { show: false } })
            if (updated) {
              dispatch({
                type: 'SET_REFRESH_VENDOR_LIST',
                payload: { refreshVendorList: !state.refreshVendorList }
              })
              dispatch({ type: 'SET_SELECTED_VENDORS', payload: { selectedVendors: [] } })
            }
          }}
        />
      )}
    </div>
  )
}

const Container: React.FC = () => {
  const { state, dispatch } = useVendorsPage()

  const updateSelection = (selected: Vendor[]): void => {
    dispatch({ type: 'SET_SELECTED_VENDORS', payload: { selectedVendors: selected } })
  }

  return <Component state={state} dispatch={dispatch} updateSelection={updateSelection} />
}

Container.displayName = 'Vendors'
export default Container
