import React from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

import config from '../../../config'
import { useProjectDetailCard } from './useProjectDetailCard'
import { useLoginUser } from '../../../hooks/useLoginUser'
import OKCancelDialog from '../../../components/dialogs/OKCancelDialog'
import QuotedAmountDialog from '../../../components/dialogs/QuotedAmountDialog'
import Project from '../../../lib/Project'

type ContainerProps = {
  project: Project
  refreshJobList: () => void
}

type Props = ContainerProps & {
  closeReturnSourceToMdmDialog: (updated: boolean) => void
  closeCancelProjectDialog: (updated: boolean) => void
  closeApproveOrderDialog: (updated: boolean) => void
  closeQuotedAmountDialog: (updated: boolean) => void
  returnSourceToMdm: () => Promise<void>
  cancelProject: () => Promise<void>
  approveOrder: () => Promise<void>
}

export const Component: React.FC<Props> = (props: Props) => {
  const {
    project,
    closeReturnSourceToMdmDialog,
    closeCancelProjectDialog,
    closeApproveOrderDialog,
    closeQuotedAmountDialog,
    returnSourceToMdm,
    cancelProject,
    approveOrder
  } = props
  const { state } = useProjectDetailCard()
  const { t } = useTranslation()
  const loginUser = useLoginUser()

  return (
    <>
      {state.showReturnSourceToMdmDialog && (
        <OKCancelDialog
          body={
            <>
              <p>{t('ソース戻しを行う際は、以下の点にご注意ください。')}</p>
              <ul>
                <li>{t('MDM側のデータが翻訳前のデータに上書きされます。')}</li>
                <li>
                  {t('MDM側に編集したデータがある場合は、ソース戻し実行前に退避させて下さい。')}
                </li>
              </ul>
              <p>
                {t('ソース戻し実行者')}: {loginUser?.email}
              </p>
            </>
          }
          close={closeReturnSourceToMdmDialog}
          onClickOK={returnSourceToMdm}
          title={t('ソース戻し')}
        />
      )}
      {state.showCancelProjectDialog && (
        <OKCancelDialog
          body={
            <>
              <p>{t('発注キャンセルを行う際は、以下の点にご注意ください。')}</p>
              <ul>
                <li>
                  {t(
                    'MDM側では該当プロジェクトの削除しかできなくなります。再度公開作業をするには、案件作成からやり直す必要があります。'
                  )}
                </li>
                <li>{t('MTDでの束ね解除ができなくなります。')}</li>
              </ul>
              <p>
                {t('キャンセル実行者')}: {loginUser?.email}
              </p>
            </>
          }
          close={closeCancelProjectDialog}
          onClickOK={cancelProject}
          title={t('発注キャンセル')}
        />
      )}
      {state.showApproveOrderDialog && (
        <OKCancelDialog
          body={
            <>
              <p>{t('発注承認を行う際は、以下の点にご注意ください。')}</p>
              <ul>
                <li>{t('発注承認を行うと、発注キャンセルができなくなります。')}</li>
              </ul>
              <p>
                {t('発注承認者')}: {loginUser?.email}
              </p>
            </>
          }
          close={closeApproveOrderDialog}
          onClickOK={approveOrder}
          title={t('発注承認')}
        />
      )}
      {state.showQuotedAmountDialog && (
        <QuotedAmountDialog close={closeQuotedAmountDialog} project={project} />
      )}
    </>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { project, refreshJobList } = props
  const { dispatch } = useProjectDetailCard()

  const returnSourceToMdm = async (): Promise<void> => {
    await axios.post(
      `${config[config.STAGE].endpoint}/api/v1/projects/${project._id}/returnSourceToMdm`
    )
  }

  const closeReturnSourceToMdmDialog = (updated: boolean): void => {
    dispatch({
      type: 'SET_SHOW_RETURN_SOURCE_TO_MDM_DIALOG',
      payload: { show: false }
    })
    if (updated) {
      refreshJobList()
    }
  }

  const cancelProject = async (): Promise<void> => {
    await axios.post(
      `${config[config.STAGE].endpoint}/api/v1/projects/${project._id}/cancelProject`
    )
  }

  const closeCancelProjectDialog = (updated: boolean): void => {
    dispatch({
      type: 'SET_SHOW_CANCEL_PROJECT_DIALOG',
      payload: { show: false }
    })
    if (updated) {
      refreshJobList()
    }
  }

  const approveOrder = async (): Promise<void> => {
    await axios.post(`${config[config.STAGE].endpoint}/api/v1/projects/${project._id}/approveOrder`)
  }

  const closeApproveOrderDialog = (updated: boolean): void => {
    dispatch({
      type: 'SET_SHOW_APPROVE_ORDER_DIALOG',
      payload: { show: false }
    })
    if (updated) {
      refreshJobList()
    }
  }

  const closeQuotedAmountDialog = (updated: boolean): void => {
    dispatch({
      type: 'SET_SHOW_QUOTED_AMOUNT_DIALOG',
      payload: { show: false }
    })
    if (updated) {
      refreshJobList()
    }
  }

  const childProps: Props = {
    ...props,
    closeReturnSourceToMdmDialog,
    closeCancelProjectDialog,
    closeApproveOrderDialog,
    closeQuotedAmountDialog,
    returnSourceToMdm,
    cancelProject,
    approveOrder
  }

  return <Component {...childProps} />
}

Container.displayName = 'Dialog'
export default Container
