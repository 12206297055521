import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

import config from '../../config'
import { JobsPageContext } from './useJobsPage'
import EditJobsDialog from '../../components/dialogs/EditJobsDialog'
import JobFiltersDialog from '../../components/dialogs/JobFiltersDialog'
import RegisterProjectDialog from '../../components/dialogs/RegisterProjectDialog'
import RegisterJobsDialog from '../../components/dialogs/RegisterJobsDialog'
import OKCancelDialog from '../../components/dialogs/OKCancelDialog'

type ContainerProps = {}

type Props = ContainerProps & {
  deleteJobs: () => Promise<void>
}

const Component: React.FC<Props> = (props: Props) => {
  const { state, dispatch } = useContext(JobsPageContext)
  const { deleteJobs } = props
  const { t } = useTranslation()

  return (
    <>
      {state.showRegisterProjectDialog && (
        <RegisterProjectDialog
          close={(): void => {
            dispatch({ type: 'SET_SHOW_REGISTER_PROJECT_DIALOG', payload: { show: false } })
          }}
          jobs={state.selectedJobs}
          title={t('プロジェクト登録')}
        />
      )}
      {state.showRegisterJobsDialog && (
        <RegisterJobsDialog
          close={(updated: boolean): void => {
            dispatch({ type: 'SET_SHOW_REGISTER_JOBS_DIALOG', payload: { show: false } })
            if (updated) {
              dispatch({ type: 'TOGGLE_REFRESH' })
              dispatch({ type: 'SET_SELECTED_JOBS', payload: { selectedJobs: [] } })
            }
          }}
          title={t('ジョブ登録')}
        />
      )}
      {state.showEditJobsDialog && (
        <EditJobsDialog
          close={(updated: boolean): void => {
            dispatch({ type: 'SET_SHOW_EDIT_JOBS_DIALOG', payload: { show: false } })
            if (updated) {
              dispatch({ type: 'TOGGLE_REFRESH' })
              dispatch({ type: 'SET_SELECTED_JOBS', payload: { selectedJobs: [] } })
            }
          }}
          jobs={state.selectedJobs}
        />
      )}
      {state.showDeleteJobsDialog && (
        <OKCancelDialog
          body={
            <>
              <p>{t('以下のジョブを削除しますか？')}</p>
              <ul>
                {state.selectedJobs.map(job => {
                  return <li key="job._id">{job.name}</li>
                })}
              </ul>
            </>
          }
          buttonVariant="danger"
          close={(updated: boolean): void => {
            dispatch({ type: 'SET_SHOW_DELETE_JOBS_DIALOG', payload: { show: false } })
            if (updated) {
              dispatch({ type: 'TOGGLE_REFRESH' })
              dispatch({ type: 'SET_SELECTED_JOBS', payload: { selectedJobs: [] } })
            }
          }}
          onClickOK={deleteJobs}
          title=""
        />
      )}
      {state.showJobFiltersDialog && (
        <JobFiltersDialog
          close={(): void =>
            dispatch({ type: 'SET_SHOW_JOB_FILTERS_DIALOG', payload: { show: false } })
          }
        />
      )}
    </>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { state } = useContext(JobsPageContext)

  const deleteJobs = async (): Promise<void> => {
    await Promise.all(
      state.selectedJobs.map(job => {
        return axios.delete(`${config[config.STAGE].endpoint}/api/v1/jobs/${job._id}`)
      })
    )
  }

  return <Component {...props} deleteJobs={deleteJobs} />
}

Container.displayName = 'Dialogs'
export default Container
