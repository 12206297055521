import { createContext, useContext } from 'react'

export type LoginUser = {
  _id: string
  email: string
  organizationId: string | null
  organizationIds: string[]
  organizationType: string
  role: string
  username: string
  organizations: Organization[] | null // organizationTypeがcistateの場合はnull
  createdAt: Date
  updatedAt: Date
  sendNotifications: boolean
  isApprover: boolean
  isEnabled: boolean
}

export type Organization = {
  _id: string
  name: string
  repositoryFolderId: string
  vendorNotSelectedFolderId: string
}

const LoginUserContext = createContext<LoginUser | null>(null)

export const LoginUserProvider = LoginUserContext.Provider

export const LoginUserConsumer = LoginUserContext.Consumer

export const useLoginUser = (): LoginUser | null => {
  return useContext(LoginUserContext)
}
