import React, { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import queryString from 'query-string'

import config from '../../../config'

type Props = RouteComponentProps & {
  isLoading: boolean
  exportCsv: () => Promise<void>
}

export const Component: React.FC<Props> = (props: Props) => {
  const { isLoading, exportCsv } = props
  const { t } = useTranslation()

  return (
    <Button
      className="ml-auto"
      disabled={isLoading}
      onClick={exportCsv}
      variant="secondary"
      data-testid="export-csv-button"
    >
      {isLoading && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          className="mr-1"
        />
      )}
      {t('CSV')} <FontAwesomeIcon icon={faArrowDown} />
    </Button>
  )
}

const Container: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const exportCsv = async (): Promise<void> => {
    setIsLoading(true)
    const qs = props.location.search
    const query = queryString.parse(qs)

    const defaultSort =
      Object.keys(query).filter(key => {
        return key.startsWith('options')
      }).length > 0
        ? false
        : true

    const sort: { [key: string]: any } = {}
    if (defaultSort) {
      sort['createdAt'] = -1
    }

    const response = await axios.get(`${config[config.STAGE].endpoint}/api/v1/projects/download`, {
      params: {
        ...query,
        options: {
          sort
        }
      }
    })

    window.open(response.data.presignedUrl, '_blank')
    setIsLoading(false)
  }

  return <Component {...props} isLoading={isLoading} exportCsv={exportCsv} />
}

export default withRouter(Container)
