import { useCallback, useEffect, useReducer } from 'react'
import axios from 'axios'

import config from '../../../config'
import Job from '../../../lib/Job'
import Project from '../../../lib/Project'

export type State = {
  loading: boolean
  message: {
    isError: boolean
    text: string
  }
  selectedJobs: Job[]
  jobsCount: {
    total: number
    translated: number
  }
}

export type Action =
  | { type: 'SET_IS_LOADING'; payload: { loading: boolean } }
  | { type: 'SET_MESSAGE'; payload: { message: string; isError?: boolean } }
  | { type: 'SET_SELECTED_JOBS'; payload: { selectedJobs: Job[] } }
  | { type: 'SET_JOBS_COUNT'; payload: { total?: number; translated?: number } }

export const initialState: State = {
  loading: false,
  message: {
    isError: false,
    text: ''
  },
  selectedJobs: [],
  jobsCount: {
    total: 0,
    translated: 0
  }
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_IS_LOADING': {
      return {
        ...state,
        loading: action.payload.loading
      }
    }
    case 'SET_MESSAGE': {
      return {
        ...state,
        message: {
          text: action.payload.message,
          isError: action.payload.isError ?? false
        }
      }
    }
    case 'SET_SELECTED_JOBS': {
      return {
        ...state,
        selectedJobs: action.payload.selectedJobs
      }
    }
    case 'SET_JOBS_COUNT': {
      return {
        ...state,
        jobsCount: {
          ...state.jobsCount,
          ...action.payload
        }
      }
    }
    default: {
      return state
    }
  }
}

const useSendToMdmDialog = (
  project: Project
): { state: State; dispatch: React.Dispatch<Action>; handleSubmit: () => Promise<void> } => {
  const [state, dispatch] = useReducer(stateReducer, initialState)

  useEffect(() => {
    const initJobsCount = async (): Promise<void> => {
      const [jobsCount, translatedJobsCount] = await Promise.all([
        axios.get<{ count: number }>(`${config[config.STAGE].endpoint}/api/v1/jobs/count`, {
          params: { projectId: project._id }
        }),
        axios.get<{ count: number }>(`${config[config.STAGE].endpoint}/api/v1/jobs/count`, {
          params: { projectId: project._id, '[translatedAt][$ne]': 'null' }
        })
      ])
      dispatch({
        type: 'SET_JOBS_COUNT',
        payload: {
          total: jobsCount.data.count,
          translated: translatedJobsCount.data.count
        }
      })
    }
    initJobsCount()
  }, [project])

  const handleSubmit = useCallback(async () => {
    dispatch({ type: 'SET_IS_LOADING', payload: { loading: true } })
    dispatch({ type: 'SET_MESSAGE', payload: { message: '', isError: false } })

    await Promise.all(
      state.selectedJobs.map(job => {
        return axios.post(
          `${config[config.STAGE].endpoint}/api/v1/jobs/${job._id}/returnTranslatedJobToMdm`
        )
      })
    )
  }, [state.selectedJobs])

  return { state, dispatch, handleSubmit }
}

export default useSendToMdmDialog
