import { ColDef } from '@ag-grid-community/core'

import { ProjectNameCellRenderer } from '../../lib/CellRenderers'
import {
  DateTimeFormatter,
  EmptyCellFormatter,
  LanguageNameFormatter,
  ProjectTypeFormatter,
  StatusNameFormatter
} from '../../lib/ValueFormatters'

const useColumnDefs = (): { columnDefs: ColDef[]; defaultColDef: ColDef } => {
  const defaultColDef: ColDef = {
    lockVisible: true,
    minWidth: 50,
    resizable: true,
    sortable: false,
    width: 100
  }

  const columnDefs: ColDef[] = [
    {
      colId: '_id',
      headerName: '',
      field: '_id',
      checkboxSelection: true,
      minWidth: 50,
      maxWidth: 50,
      resizable: false,
      valueFormatter: EmptyCellFormatter,
      width: 50
    },
    {
      colId: 'name',
      headerName: 'プロジェクト名',
      field: 'name',
      cellRenderer: ProjectNameCellRenderer,
      width: 300
    },
    { colId: 'jobsCount', headerName: 'ジョブ数', field: 'jobsCount' },
    { colId: 'wordCount', headerName: 'ワード数', field: 'wordCount' },
    { colId: 'weightedWordCount', headerName: '加重ワード数', field: 'weightedWordCount' },
    { colId: 'calculatedAmount', headerName: '算定金額', field: 'calculatedAmount' },
    { colId: 'quotedAmount', headerName: '見積金額', field: 'quotedAmount' },
    { colId: 'orderAmount', headerName: '発注金額', field: 'orderAmount' },
    { colId: 'currency', headerName: '通貨', field: 'currency' },
    {
      colId: 'status',
      headerName: 'ステータス',
      field: 'status',
      valueFormatter: StatusNameFormatter
    },
    {
      colId: 'projectType',
      headerName: 'プロジェクトタイプ',
      field: 'projectType',
      valueFormatter: ProjectTypeFormatter
    },
    {
      colId: 'srcLang',
      headerName: 'ソース言語',
      field: 'srcLang',
      valueFormatter: LanguageNameFormatter
    },
    {
      colId: 'tgtLang',
      headerName: 'ターゲット言語',
      field: 'tgtLang',
      valueFormatter: LanguageNameFormatter
    },
    {
      colId: 'dueAt',
      headerName: '希望完了日時',
      field: 'dueAt',
      width: 150,
      valueFormatter: DateTimeFormatter
    },
    {
      colId: 'completedAt',
      headerName: '実際完了日時',
      field: 'completedAt',
      width: 150,
      valueFormatter: DateTimeFormatter
    },
    { colId: 'vendor', headerName: '発注先翻訳会社', field: 'vendor' },
    { colId: 'owner', headerName: '担当者', field: 'owner' },
    { colId: 'ownerGroup', headerName: '担当者所属', field: 'ownerGroup' },
    {
      colId: 'createdAt',
      headerName: '作成日時',
      field: 'createdAt',
      width: 150,
      valueFormatter: DateTimeFormatter
    },
    {
      colId: 'updatedAt',
      headerName: '更新日時',
      field: 'updatedAt',
      width: 150,
      valueFormatter: DateTimeFormatter
    },
    {
      colId: 'archivedAt',
      headerName: 'アーカイブ日時',
      field: 'archivedAt',
      width: 150,
      valueFormatter: DateTimeFormatter
    },
    {
      colId: 'approvedAt',
      field: 'approvedAt',
      hide: true
    },
    {
      colId: 'cancelledAt',
      field: 'cancelledAt',
      hide: true
    },
    { colId: 'mdmJobsCount', field: 'mdmJobsCount', hide: true }
  ]
  return { columnDefs, defaultColDef }
}

export default useColumnDefs
