import { useEffect, useState } from 'react'
import {
  GridApi,
  GridOptions,
  GridReadyEvent,
  IDatasource,
  IGetRowsParams,
  RowDataChangedEvent,
  RowSelectedEvent
} from '@ag-grid-community/core'
import axios from 'axios'
import queryString from 'query-string'

import config from '../../config'
import OwnerGroup from '../../lib/OwnerGroup'
import { DisabledCellRenderer } from '../../lib/CellRenderers'
import VendorNameRenderer from '../VendorList/VendorNameRenderer'
import CustomHeader from '../CustomHeader'
import { useLoginUser } from '../../hooks/useLoginUser'
import RowData from './RowData'
import useColumnDefs from './useColumnDefs'

const useOwnerGroupList = ({
  qs,
  updateSelection,
  options,
  selected
}: {
  qs: string
  updateSelection?: (selected: OwnerGroup[]) => void
  options?: string
  selected?: string[]
  enableEdit?: boolean
}): { gridOptions: GridOptions } => {
  const loginUser = useLoginUser()
  const { defaultColDef, columnDefs } = useColumnDefs()

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)
  const [ownerGroupsLoaded, setOwnerGroupsLoaded] = useState(false)

  useEffect(() => {
    if (gridApi) {
      const updateData = (): void => {
        const dataSource: IDatasource = {
          rowCount: undefined,
          getRows: (params: IGetRowsParams) => {
            setTimeout(async () => {
              const query = queryString.parse(qs)
              const defaultSort =
                Object.keys(query).filter(key => {
                  return key.startsWith('options')
                }).length > 0
                  ? false
                  : true

              const sort: {
                [key: string]: number
              } = {}
              if (defaultSort) {
                sort['name'] = -1
              } else {
                const sortBy = Object.keys(query)
                  .filter(key => {
                    return key.startsWith('options[sort]')
                  })
                  .map(key => {
                    return key.replace(/options\[sort\]\[([^\]]+)\]/, '$1')
                  })
                sortBy.forEach(key => {
                  if (Object.keys(sort).includes(key)) {
                    const sortOrder = query[`options[sort][${key}]`]
                    if (typeof sortOrder === 'string') {
                      sort[key] = parseInt(sortOrder)
                    }
                  }
                })
              }

              const ownerGroupsResponse = await axios.get(
                `${config[config.STAGE].endpoint}/api/v1/ownerGroups`,
                {
                  params: {
                    ...query,
                    showAll: true,
                    options: {
                      limit: 100,
                      skip: params.startRow,
                      sort: sort
                    }
                  }
                }
              )

              const ownerGroups = ownerGroupsResponse.data

              const rowsThisPage: RowData[] = await Promise.all(
                ownerGroups.map(
                  async (ownerGroup: OwnerGroup): Promise<RowData> => {
                    return {
                      _id: ownerGroup._id,
                      name: ownerGroup.name
                    }
                  }
                )
              )
              const lastRow = ownerGroups.length < 100 ? params.startRow + ownerGroups.length : -1

              params.successCallback(rowsThisPage, lastRow)
              if (lastRow !== -1) {
                setOwnerGroupsLoaded(true)
              }
            }, 500)
          }
        }
        gridApi.setDatasource(dataSource)
      }

      updateData()
    }
  }, [gridApi, options, qs])

  useEffect(() => {
    if (gridApi && ownerGroupsLoaded) {
      gridApi.forEachNode(rowNode => {
        if (rowNode.data && selected && selected.includes(rowNode.data._id)) {
          rowNode.setSelected(true)
        }
      })
    }
  }, [gridApi, selected, ownerGroupsLoaded])

  const onGridReady = (params: GridReadyEvent): void => {
    setGridApi(params.api)
  }

  const onRowDataChanged = (params: RowDataChangedEvent): void => {
    params.columnApi.autoSizeAllColumns(false)
  }

  const onRowSelected = (params: RowSelectedEvent): void => {
    const selected = params.api.getSelectedRows()
    if (updateSelection) {
      updateSelection(selected)
    }
  }

  const gridOptions: GridOptions = {
    cacheOverflowSize: 5,
    enableCellTextSelection: true,
    columnDefs: columnDefs,
    defaultColDef: defaultColDef,
    frameworkComponents: {
      agColumnHeader: CustomHeader,
      DisabledCellRenderer: DisabledCellRenderer,
      VendorNameRenderer: VendorNameRenderer
    },
    infiniteInitialRowCount: -1,
    isRowSelectable: node => {
      if (loginUser?.organizationType === 'cistate') {
        return true
      }
      if (node.data && loginUser?.organizationIds?.includes(node.data._id)) {
        return true
      }
      return false
    },
    maxBlocksInCache: 10,
    maxConcurrentDatasourceRequests: 1,
    onGridReady: onGridReady,
    onRowDataChanged: onRowDataChanged,
    onRowSelected: onRowSelected,
    paginationPageSize: 100,
    rowBuffer: 0,
    rowModelType: 'infinite',
    rowMultiSelectWithClick: true,
    rowSelection: 'multiple'
  }

  return { gridOptions }
}

export default useOwnerGroupList
