import useAxios from 'axios-hooks'

import config from '../config'
import CategoryMapping from '../lib/CategoryMapping'

export const useCategoryMappings = (): CategoryMapping[] | null => {
  const [categoryMappings] = useAxios({
    url: `${config[config.STAGE].endpoint}/api/v1/categoryMappings`
  })

  return categoryMappings.data
}
