import { useCallback, useReducer } from 'react'
import axios from 'axios'

import config from '../../../config'
import Job from '../../../lib/Job'

export type State = {
  loading: boolean
  message: {
    isError: boolean
    text: string
  }
}

export type Action =
  | { type: 'SET_IS_LOADING'; payload: { loading: boolean } }
  | { type: 'SET_MESSAGE'; payload: { message: string; isError?: boolean } }

export const initialState: State = {
  loading: false,
  message: {
    isError: false,
    text: ''
  }
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return {
        ...state,
        loading: action.payload.loading
      }
    case 'SET_MESSAGE':
      return {
        ...state,
        message: {
          text: action.payload.message,
          isError: action.payload.isError ?? false
        }
      }
    default:
      return state
  }
}

const useRemoveJobsDialog = (
  jobs: Job[]
): {
  state: State
  dispatch: React.Dispatch<Action>
  handleSubmit: () => Promise<void>
} => {
  const [state, dispatch] = useReducer(stateReducer, initialState)

  const handleSubmit = useCallback(async () => {
    dispatch({ type: 'SET_IS_LOADING', payload: { loading: true } })
    dispatch({ type: 'SET_MESSAGE', payload: { message: '', isError: false } })

    await Promise.all(
      jobs.map(async (job: Job) => {
        return axios.patch(`${config[config.STAGE].endpoint}/api/v1/jobs/${job._id}`, {
          projectId: null
        })
      })
    )
  }, [jobs])

  return { state, dispatch, handleSubmit }
}

export default useRemoveJobsDialog
