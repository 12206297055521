import React from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import BasicConditions from './BasicConditions'
import DetailedConditions from './DetailedConditions'
import { FiltersProvider, useFilters } from './useFilters'

const Component: React.FC = () => {
  const { t } = useTranslation()
  const { state, dispatch, handleSubmit, clearFilters, countDetailedConditions } = useFilters()

  return (
    <div className="FiltersPane">
      <form onSubmit={handleSubmit}>
        <div className="FiltersPane-body">
          <div>
            <BasicConditions />
            <hr />
            <Button
              className="mb-2"
              variant="link"
              size="sm"
              onClick={(): void => dispatch({ type: 'TOGGLE_SHOW_DETAILS' })}
            >
              {state.showDetails ? (
                <FontAwesomeIcon icon={faChevronDown} />
              ) : (
                <FontAwesomeIcon icon={faChevronRight} />
              )}
              {t('詳細条件')} ({countDetailedConditions()})
            </Button>
          </div>
          {state.showDetails && <DetailedConditions />}
        </div>
        <ButtonToolbar>
          <Button size="sm" type="submit">
            {t('検索')}
          </Button>
          <Button
            size="sm"
            variant="secondary"
            style={{ marginLeft: '0.5rem' }}
            onClick={clearFilters}
          >
            {t('絞り込み解除')}
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  )
}

const Container: React.FC = () => {
  return (
    <FiltersProvider>
      <Component />
    </FiltersProvider>
  )
}

Container.displayName = 'Filters'
export default Container
