import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Button, Form, Modal, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import path from 'path'

import Job from '../../../lib/Job'
import User from '../../../lib/User'
import { langCodeToLangName } from '../../../lib/Utils'
import { useLoginUser } from '../../../hooks/useLoginUser'
import { OverlayTriggerButton } from '../../common/OverlayTriggerButton'
import { Action, State, useRegisterProjectDialog } from './useRegisterProjectDialog'

type ContainerProps = RouteComponentProps & {
  jobs: Job[]
  close: () => void
  title?: string
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  onKeyPress: (e: React.KeyboardEvent<HTMLFormElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
}

// TODO: このダイアログはVendorユーザーには表示させないようにする
export const Component: React.FC<Props> = (props: Props) => {
  const { close: closeDialog, handleSubmit, onKeyPress, state, dispatch } = props
  const { t } = useTranslation()

  return (
    <Modal onHide={closeDialog} show={true} size="lg" data-testid="register-project-dialog">
      <Form onKeyPress={onKeyPress} onSubmit={handleSubmit}>
        <Modal.Header closeButton>{props.title ? props.title : t('プロジェクト登録')}</Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} controlId="jobs">
            <Form.Label column sm={4}>
              {t('登録するジョブ')} ({props.jobs.length}):
            </Form.Label>
            <Col sm={8}>
              <Form.Control readOnly as="select" multiple>
                {props.jobs.map(job => (
                  <option key={job._id}>{path.basename(job.name)}</option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              {t('プロジェクト名')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                disabled={state.loading}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: { name: e.currentTarget.value }
                  })
                }
                placeholder={t('プロジェクト名を入力')}
                type="text"
                value={state.formData.name}
                required
                data-testid="projectName"
                pattern="[^\/]+"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              {t('プロジェクトタイプ')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                required
                disabled={state.loading}
                data-testid="projectType"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: { projectType: e.currentTarget.value }
                  })
                }
              >
                <option value="ht_only">{t('人手翻訳')}</option>
                <option value="mt_and_postedit">{t('機械翻訳+ポストエディット')}</option>
                <option value="mt_only">{t('機械翻訳のみ')}</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              {t('ソース言語')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                readOnly
                required
                data-testid="srcLang"
                value={langCodeToLangName(state.formData.srcLang)}
              ></Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              {t('ターゲット言語')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                readOnly
                required
                data-testid="tgtLang"
                value={langCodeToLangName(state.formData.tgtLang)}
              ></Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              {t('担当者所属')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                disabled={state.loading}
                required
                data-testid="ownerGroups"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: { ownerGroupId: e.currentTarget.value }
                  })
                }}
                value={state.formData.ownerGroupId ?? ''}
              >
                {state.ownerGroups.map(ownerGroup => {
                  return (
                    <option key={ownerGroup._id} value={ownerGroup._id}>
                      {ownerGroup.name}
                    </option>
                  )
                })}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              {t('担当者')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                disabled={state.loading}
                required
                data-testid="owners"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: { ownerId: e.currentTarget.value }
                  })
                }}
                value={state.formData.ownerId ?? ''}
              >
                {state.users.map((user: User) => {
                  return (
                    <option key={user._id} value={user._id}>
                      {user.username}
                    </option>
                  )
                })}
              </Form.Control>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <span style={state.message.isError ? { color: 'red' } : undefined}>
            {state.message.text}
          </span>
          <OverlayTriggerButton
            tooltipLabel={t('登録中')}
            buttonLabel={t('登録')}
            isOverlayed={(): boolean => state.loading}
            isLoading={(): boolean => state.loading}
            variant="primary"
            tooltipId="tooltip-registering-project"
            type="submit"
            data-testid="register-project-button"
          />
          <Button
            disabled={state.loading}
            onClick={closeDialog}
            style={state.loading ? { pointerEvents: 'none' } : undefined}
            variant="secondary"
          >
            {t('キャンセル')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { jobs } = props
  const loginUser = useLoginUser()
  const { state, dispatch, handleSubmit: registerProject } = useRegisterProjectDialog(
    loginUser,
    jobs
  )

  const onKeyPress = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' /* Enter */) {
      e.preventDefault()
    }
  }

  const close = (): void => {
    if (!state.loading) {
      props.close()
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    const project = await registerProject()
    if (project) {
      props.history.push(`/projects/${project._id}`)
    }
  }

  return (
    <Component
      {...props}
      close={close}
      state={state}
      dispatch={dispatch}
      handleSubmit={handleSubmit}
      onKeyPress={onKeyPress}
    />
  )
}

Container.displayName = 'RegisterProjectDialog'
export default withRouter(Container)
