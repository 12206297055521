import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import useAxios from 'axios-hooks'
import { format } from 'date-fns'
import queryString from 'query-string'

import config from '../../../config'
import useQueryString from '../../../hooks/useQueryString'
import { langCodeToLangName, lookupValue } from '../../../lib/Utils'
import { useOwnerGroups } from '../../../hooks/useOwnerGroups'
import { useVendors } from '../../../hooks/useVendors'
import { useCategoryMappings } from '../../../hooks/useCategoryMappings'

type useToolbar = {
  count?: number
  totalCount?: number
  sortBy?: string
  orderByAsc?: boolean
  onChangeSortBy: (value: string) => void
  toggleOrderByAsc: () => void
  conditionStrings: string
}

export const useToolbar = (): useToolbar => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()

  const [conditionStrings, setConditionStrings] = useState('')

  const ownerGroups = useOwnerGroups()
  const vendors = useVendors()
  const categoryMappings = useCategoryMappings()

  const ownerGroupMappings =
    ownerGroups?.reduce((acc: { [key: string]: any }, val) => {
      const result = acc
      result[val._id] = val.name
      return result
    }, {}) || {}

  const vendorMappings =
    vendors?.reduce((acc: { [key: string]: any }, val) => {
      const result = acc
      result[val._id] = `${val.name} (${val.srcLang} -> ${val.tgtLang})`
      return result
    }, {}) || {}

  const categoryMappingMappings =
    categoryMappings?.reduce(
      (acc: { [key: string]: any }, val) => {
        const result = acc
        result[val.index] = val.name
        return result
      },
      {
        null: t('カテゴリなし'),
        M: 'M',
        T: 'T',
        P: 'P',
        E: 'E',
        K: 'K'
      }
    ) || {}

  const baseUrl = config[config.STAGE].endpoint

  const [{ data: countData }, countRefetch] = useAxios<{ count: number }>(
    `${baseUrl}/api/v1/tmEntries/count${location.search}`
  )
  const [{ data: totalData }, totalRefetch] = useAxios<{ count: number }>(
    `${baseUrl}/api/v1/tmEntries/count`
  )

  const { sortBy, orderByAsc } = useQueryString(location.search)

  const onChangeSortBy = (value: string): void => {
    const qs = location.search
    const queries = queryString.parse(qs)
    delete queries[`options[sort][${sortBy}]`]
    queries[`options[sort][${value}]`] = value === '_fuzzyScore' ? '-1' : orderByAsc ? '1' : '-1'

    const options = queryString.stringify(queries)
    history.push('/tm-entries?' + options)
  }

  const toggleOrderByAsc = (): void => {
    const qs = location.search
    const queries = queryString.parse(qs)
    queries[`options[sort][${sortBy ? sortBy : 'createdAt'}]`] = !orderByAsc ? '1' : '-1'

    const options = queryString.stringify(queries)
    history.push('/tm-entries?' + options)
  }

  useEffect(() => {
    if (countRefetch && totalRefetch) {
      countRefetch()
      totalRefetch()
    }
  }, [countRefetch, totalRefetch, location.search])

  useEffect(() => {
    const query = queryString.parse(location.search)
    const strings = []
    if (typeof query['srcLang'] === 'string') {
      strings.push(`${t('ソース言語')}: ${langCodeToLangName(query['srcLang'])}`)
    }
    if (typeof query['[srcTmx][$regex]'] === 'string') {
      strings.push(`${t('原文')}: ${query['[srcTmx][$regex]']}`)
    }
    if (typeof query['tgtLang'] === 'string') {
      strings.push(`${t('ターゲット言語')}: ${langCodeToLangName(query['tgtLang'])}`)
    }
    if (typeof query['[tgtTmx][$regex]'] === 'string') {
      strings.push(`${t('訳文')}: ${query['[tgtTmx][$regex]']}`)
    }
    if (typeof query['confidence'] === 'string') {
      strings.push(`${t('信頼度')}: ${query['confidence']}`)
    }
    if (typeof query['ownerGroupId'] === 'string') {
      strings.push(
        `${t('所属先名')}: ${
          lookupValue(ownerGroupMappings, query['ownerGroupId']) !== undefined
            ? lookupValue(ownerGroupMappings, query['ownerGroupId'])
            : ''
        }`
      )
    }
    if (typeof query['vendorId'] === 'string') {
      strings.push(
        `${t('翻訳会社名')}: ${
          lookupValue(vendorMappings, query['vendorId']) !== undefined
            ? lookupValue(vendorMappings, query['vendorId'])
            : ''
        }`
      )
    }
    if (typeof query['[properties.category]'] === 'string') {
      strings.push(
        `${t('カテゴリ')}: ${
          lookupValue(categoryMappingMappings, query['[properties.category]']) !== undefined
            ? lookupValue(categoryMappingMappings, query['[properties.category]'])
            : ''
        }`
      )
    }
    if (typeof query['[properties.tmName][$regex]'] === 'string') {
      strings.push(`${t('インポート元TM名')}: ${query['[properties.tmName][$regex]']}`)
    }
    if (typeof query['[properties.tmType][$regex]'] === 'string') {
      strings.push(`${t('インポート先TM種別')}: ${query['[properties.tmType][$regex]']}`)
    }
    if (typeof query['active'] === 'string' && query['active'] === 'true') {
      strings.push(`${t('有効のみ表示')}`)
    }
    if (query['createdAt[$gte]'] || query['createdAt[$lte]']) {
      strings.push(
        `${t('作成日時')}: ${
          typeof query['createdAt[$gte]'] === 'string'
            ? format(new Date(query['createdAt[$gte]']), 'yyyy-MM-dd HH:mm:SS')
            : ''
        } 〜 ${
          typeof query['createdAt[$lte]'] === 'string'
            ? format(new Date(query['createdAt[$lte]']), 'yyyy-MM-dd HH:mm:SS')
            : ''
        }`
      )
    }
    if (query['createdBy']) {
      strings.push(`${t('作成者')}: ${query['createdBy']}`)
    }
    if (query['updatedAt[$gte]'] || query['updatedAt[$lte]']) {
      strings.push(
        `${t('更新日時')}: ${
          typeof query['updatedAt[$gte]'] === 'string'
            ? format(new Date(query['updatedAt[$gte]']), 'yyyy-MM-dd HH:mm:SS')
            : ''
        } 〜 ${
          typeof query['updatedAt[$lte]'] === 'string'
            ? format(new Date(query['updatedAt[$lte]']), 'yyyy-MM-dd HH:mm:SS')
            : ''
        }`
      )
    }
    if (query['updatedBy']) {
      strings.push(`${t('更新者')}: ${query['updatedBy']}`)
    }
    setConditionStrings(strings.join(', '))
  }, [location.search, ownerGroupMappings, t, vendorMappings, categoryMappingMappings])

  return {
    count: countData?.count,
    totalCount: totalData?.count,
    sortBy,
    orderByAsc,
    onChangeSortBy,
    toggleOrderByAsc,
    conditionStrings
  }
}
