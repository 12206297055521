import React, { createContext, ReactNode, useContext, useReducer } from 'react'

type FormData = {
  status: string
  projectType: string
  vendorId: string
}

export type State = {
  editing: boolean
  updating: boolean
  showReturnSourceToMdmDialog: boolean
  showCancelProjectDialog: boolean
  showApproveOrderDialog: boolean
  showQuotedAmountDialog: boolean
  formData: FormData
}

export type Action =
  | { type: 'SET_EDITING'; payload: { editing: boolean } }
  | { type: 'SET_UPDATING'; payload: { updating: boolean } }
  | { type: 'SET_SHOW_RETURN_SOURCE_TO_MDM_DIALOG'; payload: { show: boolean } }
  | { type: 'SET_SHOW_CANCEL_PROJECT_DIALOG'; payload: { show: boolean } }
  | { type: 'SET_SHOW_APPROVE_ORDER_DIALOG'; payload: { show: boolean } }
  | { type: 'SET_SHOW_QUOTED_AMOUNT_DIALOG'; payload: { show: boolean } }
  | { type: 'SET_FORM_VALUE'; payload: Partial<FormData> }

// "なし"を表す vendorId。selectのoptionとして使用
export const EMPTY = 'local-empty'

export const initialState: State = {
  editing: false,
  updating: false,
  showReturnSourceToMdmDialog: false,
  showCancelProjectDialog: false,
  showApproveOrderDialog: false,
  showQuotedAmountDialog: false,
  formData: {
    status: '',
    projectType: '',
    vendorId: EMPTY
  }
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_EDITING':
      return { ...state, editing: action.payload.editing }
    case 'SET_UPDATING':
      return { ...state, updating: action.payload.updating }
    case 'SET_SHOW_RETURN_SOURCE_TO_MDM_DIALOG':
      return { ...state, showReturnSourceToMdmDialog: action.payload.show }
    case 'SET_SHOW_CANCEL_PROJECT_DIALOG':
      return { ...state, showCancelProjectDialog: action.payload.show }
    case 'SET_SHOW_APPROVE_ORDER_DIALOG':
      return { ...state, showApproveOrderDialog: action.payload.show }
    case 'SET_SHOW_QUOTED_AMOUNT_DIALOG':
      return { ...state, showQuotedAmountDialog: action.payload.show }
    case 'SET_FORM_VALUE':
      return { ...state, formData: { ...state.formData, ...action.payload } }
    default:
      return state
  }
}

export const ProjectDetailCardContext = createContext(
  {} as {
    state: State
    dispatch: React.Dispatch<Action>
  }
)

export const ProjectDetailCardProvider: React.FC<{ children: ReactNode }> = ({
  children
}: {
  children: ReactNode
}) => {
  const [state, dispatch] = useReducer(stateReducer, initialState)
  return (
    <ProjectDetailCardContext.Provider value={{ state, dispatch }}>
      {children}
    </ProjectDetailCardContext.Provider>
  )
}

export const ProjectDetailCardConsumer = ProjectDetailCardContext.Consumer

export const useProjectDetailCard = (): { state: State; dispatch: React.Dispatch<Action> } => {
  return useContext(ProjectDetailCardContext)
}
