import React, { useContext, useEffect } from 'react'
import { ButtonToolbar } from 'react-bootstrap'

import Project from '../../../lib/Project'
import { BatchStatus } from '../../../lib/Utils'
import { useBatchStatus } from '../../../hooks/useBatchStatus'
import { ProjectDetailPageContext } from '../useProjectDetailPage'
import Actions from './Actions'
import FilterAndSort from './FilterAndSort'

type ContainerProps = {
  project: Project
}

type Props = ContainerProps & {}

export const Component: React.FC<Props> = (props: Props) => {
  const { project } = props

  return (
    <div>
      <Actions project={project} />
      <ButtonToolbar className="ml-3 mr-3 mb-3">
        <FilterAndSort project={project} />
      </ButtonToolbar>
    </div>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { project } = props
  const { dispatch } = useContext(ProjectDetailPageContext)

  // 翻訳キット生成のバッチステータスをポーリングで取得
  const { batchStatus } = useBatchStatus({
    resourceId: project._id,
    batchName: 'create-translation-kit',
    polling: true
  })

  useEffect(() => {
    // - projectにjobが存在しない場合は null を設定
    // - projectにjobが存在し、かつAWS Batchのステータスが下記の場合は生成可能(true)
    // -- 空文字 (過去に実行した履歴がない)
    // -- SUCCEEDED/FAILED (成功また失敗済み)
    // - それ以外はBatchが実行中なので生成不可(false)
    if (project.statistics && project.statistics.jobsCount === 0) {
      dispatch({
        type: 'SET_CAN_CREATE_TRANSLATION_KIT',
        payload: { canCreateTranslationKit: null }
      })
    } else if (
      [BatchStatus.NONE, BatchStatus.SUCCEEDED, BatchStatus.FAILED].includes(batchStatus)
    ) {
      dispatch({
        type: 'SET_CAN_CREATE_TRANSLATION_KIT',
        payload: { canCreateTranslationKit: true }
      })
    } else {
      dispatch({
        type: 'SET_CAN_CREATE_TRANSLATION_KIT',
        payload: { canCreateTranslationKit: false }
      })
    }
  }, [batchStatus, dispatch, project.statistics])

  return <Component {...props} />
}

Container.displayName = 'Toolbar'
export default Container
