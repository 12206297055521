import React from 'react'
import { Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import { useFilters } from './useFilters'
import { useCategoryMappings } from '../../../hooks/useCategoryMappings'
import { useOwnerGroups } from '../../../hooks/useOwnerGroups'
import { useVendors } from '../../../hooks/useVendors'

const DetailedConditions: React.FC = () => {
  const { t } = useTranslation()

  const { state, dispatch } = useFilters()
  const categoryMappings = useCategoryMappings()
  const ownerGroups = useOwnerGroups()
  const vendors = useVendors()

  const categories = Array.from(
    new Map(
      [
        { value: '', label: `(${t('指定なし')})` },
        { value: 'null', label: t('カテゴリなし') },
        { value: 'E', label: 'E' },
        { value: 'M', label: 'M' },
        { value: 'T', label: 'T' },
        { value: 'P', label: 'P' },
        { value: 'K', label: 'K' },
        ...(categoryMappings?.map(categoryMapping => {
          return { value: categoryMapping.index, label: categoryMapping.name }
        }) ?? [])
      ].map(category => [category.value, category])
    ).values()
  )

  const ownerGroupDicts = ownerGroups
    ? [
        { value: '', label: `(${t('指定なし')})` },
        ...ownerGroups.map(ownerGroup => {
          return { value: ownerGroup._id, label: ownerGroup.name }
        })
      ]
    : [{ value: '', label: `(${t('指定なし')})` }]

  const vendorDicts = vendors
    ? [
        { value: '', label: `(${t('指定なし')})` },
        ...vendors.map(vendor => {
          return {
            value: vendor._id,
            label: `${vendor.name} (${vendor.srcLang} -> ${vendor.tgtLang})`
          }
        })
      ]
    : [{ value: '', label: `(${t('指定なし')})` }]

  const valueToCategoryName = (value: string): string => {
    const result = categories.find(category => {
      return category.value === value
    })
    return result?.label || value
  }

  const valueToOwnerGroupName = (value: string): string => {
    const result = ownerGroupDicts.find(ownerGroup => {
      return ownerGroup.value === value
    })
    return result?.label || value
  }

  const valueToVendorName = (value: string): string => {
    const result = vendorDicts.find(vendor => {
      return vendor.value === value
    })
    return result?.label || value
  }
  return (
    <div style={{ maxHeight: '100%' }}>
      <Form.Group controlId="confidence">
        <Form.Label>{t('信頼度')}:</Form.Label>
        <Form.Control
          size="sm"
          pattern="^100$|^[0-9]{1,2}$"
          placeholder={t('0~100')}
          value={state.confidence}
          onChange={(e): void => {
            dispatch({ type: 'UPDATE', payload: { confidence: e.currentTarget.value } })
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('所属先名')}:</Form.Label>
        <Select
          options={ownerGroupDicts}
          value={{
            value: state.ownerGroupId,
            label: valueToOwnerGroupName(state.ownerGroupId || '')
          }}
          onChange={(e): void => {
            dispatch({ type: 'UPDATE', payload: { ownerGroupId: e?.value } })
          }}
          filterOption={({ label }, inputValue): boolean => {
            return label.toLowerCase().includes(inputValue.toLowerCase())
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('翻訳会社')}:</Form.Label>
        <Select
          options={vendorDicts}
          value={{
            value: state.vendorId,
            label: valueToVendorName(state.vendorId || '')
          }}
          onChange={(e): void => {
            dispatch({ type: 'UPDATE', payload: { vendorId: e?.value } })
          }}
          filterOption={({ label }, inputValue): boolean => {
            return label.toLowerCase().includes(inputValue.toLowerCase())
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('カテゴリ')}:</Form.Label>
        <Select
          options={categories}
          value={{
            value: state.category,
            label: valueToCategoryName(state.category || '')
          }}
          onChange={(e): void => {
            dispatch({ type: 'UPDATE', payload: { category: e?.value } })
          }}
          filterOption={({ label }, inputValue): boolean => {
            return label.toLowerCase().includes(inputValue.toLowerCase())
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('インポート元TM名')}:</Form.Label>
        <Form.Control
          size="sm"
          value={state.tmName}
          onChange={(e): void => {
            dispatch({ type: 'UPDATE', payload: { tmName: e.currentTarget.value } })
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('インポート先TM種別')}:</Form.Label>
        <Form.Control
          size="sm"
          value={state.tmType}
          onChange={(e): void => {
            dispatch({ type: 'UPDATE', payload: { tmType: e.currentTarget.value } })
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('有効のみ表示')}:</Form.Label>
        <Form.Check
          inline
          checked={state.active}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            dispatch({
              type: 'UPDATE',
              payload: { active: e.currentTarget.checked }
            })
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('作成日時')}:</Form.Label>
        <DatePicker
          className="form-control"
          dateFormat="yyyy-MM-dd HH:mm"
          onChange={(date): void =>
            dispatch({
              type: 'UPDATE',
              payload: { createdAtFrom: date }
            })
          }
          selected={state.createdAtFrom}
          showTimeSelect
          placeholderText={t('開始')}
        />
        <DatePicker
          className="form-control"
          dateFormat="yyyy-MM-dd HH:mm"
          onChange={(date): void =>
            dispatch({
              type: 'UPDATE',
              payload: { createdAtTo: date }
            })
          }
          selected={state.createdAtTo}
          showTimeSelect
          placeholderText={t('終了')}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('作成者')}:</Form.Label>
        <Form.Control
          size="sm"
          value={state.createdBy}
          onChange={(e): void => {
            dispatch({ type: 'UPDATE', payload: { createdBy: e.currentTarget.value } })
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('更新日時')}:</Form.Label>
        <DatePicker
          className="form-control"
          dateFormat="yyyy-MM-dd HH:mm"
          onChange={(date): void =>
            dispatch({
              type: 'UPDATE',
              payload: { updatedAtFrom: date }
            })
          }
          selected={state.updatedAtFrom}
          showTimeSelect
          placeholderText={t('開始')}
        />
        <DatePicker
          className="form-control"
          dateFormat="yyyy-MM-dd HH:mm"
          onChange={(date): void =>
            dispatch({
              type: 'UPDATE',
              payload: { updatedAtTo: date }
            })
          }
          selected={state.updatedAtTo}
          showTimeSelect
          placeholderText={t('終了')}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('更新者')}:</Form.Label>
        <Form.Control
          size="sm"
          value={state.updatedBy}
          onChange={(e): void => {
            dispatch({ type: 'UPDATE', payload: { updatedBy: e.currentTarget.value } })
          }}
        />
      </Form.Group>
    </div>
  )
}

export default DetailedConditions
