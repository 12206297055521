import { useCallback, useEffect, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'

import Project from '../../../lib/Project'

export type FormData = {
  showBundled: boolean
  ankenId: string
  srcLang: string
  tgtLang: string
  status: string
  categoryCode: string
  brandCode: string
  createdAtFrom: Date | null
  createdAtTo: Date | null
  showArchived: boolean
}

type Filters = {
  [key: string]: string
}

export type State = {
  loading: boolean
  message: {
    isError: boolean
    text: string
  }
  formData: FormData
}

export type Action =
  | { type: 'SET_FORM_VALUE'; payload: Partial<FormData> }
  | { type: 'CLEAR_FILTERS' }

export const initialState: State = {
  loading: false,
  message: {
    isError: false,
    text: ''
  },
  formData: {
    showBundled: true,
    ankenId: '',
    srcLang: '',
    tgtLang: '',
    status: '',
    categoryCode: '',
    brandCode: '',
    createdAtFrom: null,
    createdAtTo: null,
    showArchived: false
  }
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_FORM_VALUE': {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload
        }
      }
    }
    case 'CLEAR_FILTERS':
      return initialState
    default:
      return state
  }
}

export type DispatchType = React.Dispatch<Action>

const useJobFiltersDialog = (
  qs: string,
  project?: Project
): {
  state: State
  dispatch: DispatchType
  handleSubmit: () => Promise<void>
} => {
  const [state, dispatch] = useReducer(stateReducer, initialState)
  const history = useHistory()

  useEffect(() => {
    const query = queryString.parse(qs)

    dispatch({
      type: 'SET_FORM_VALUE',
      payload: {
        showBundled: !query['projectId']
          ? true
          : typeof query['projectId'] === 'string'
          ? false
          : true,
        ankenId: !query['[properties.anken_id][$regex]']
          ? ''
          : typeof query['[properties.anken_id][$regex]'] === 'string'
          ? query['[properties.anken_id][$regex]'].replace(/^\^/, '')
          : '',
        srcLang: !query['srcLang']
          ? ''
          : typeof query['srcLang'] === 'string'
          ? query['srcLang']
          : '',
        tgtLang: !query['tgtLang']
          ? ''
          : typeof query['tgtLang'] === 'string'
          ? query['tgtLang']
          : '',
        status: !query['status'] ? '' : typeof query['status'] === 'string' ? query['status'] : '',
        categoryCode: !query['[properties.category_code][$regex]']
          ? ''
          : typeof query['[properties.category_code][$regex]'] === 'string'
          ? query['[properties.category_code][$regex]'].replace(/^\^/, '')
          : '',
        brandCode: !query['[properties.brand_code][$regex]']
          ? ''
          : typeof query['[properties.brand_code][$regex]'] === 'string'
          ? query['[properties.brand_code][$regex]'].replace(/^\^/, '')
          : '',
        createdAtFrom: !query['createdAt[$gte]']
          ? null
          : typeof query['createdAt[$gte]'] === 'string'
          ? new Date(query['createdAt[$gte]'])
          : null,
        createdAtTo: !query['createdAt[$lte]']
          ? null
          : typeof query['createdAt[$lte]'] === 'string'
          ? new Date(query['createdAt[$lte]'])
          : null,
        showArchived: !query['archivedAt']
          ? true
          : typeof query['archivedAt'] === 'string'
          ? false
          : true
      }
    })
  }, [qs])

  const handleSubmit = useCallback(async () => {
    const filters: Filters = {
      ankenId: state.formData.ankenId,
      srcLang: state.formData.srcLang,
      tgtLang: state.formData.tgtLang,
      status: state.formData.status,
      categoryCode: state.formData.categoryCode,
      brandCode: state.formData.brandCode
    }
    const keys = [
      'projectId',
      'ankenId',
      'srcLang',
      'tgtLang',
      'status',
      'categoryCode',
      'brandCode'
    ]

    const query: { [key: string]: string | string[] | null | undefined } = {}

    keys
      .filter(key => filters[key] !== '')
      .forEach(key => {
        if (key === 'ankenId' || key === 'categoryCode' || key === 'brandCode') {
          query[
            '[properties.' +
              key.replace(/([A-Z])/g, (match, p1: string) => '_' + p1.toLowerCase()) +
              '][$regex]'
          ] = '^' + filters[key]
        } else {
          query[key] = filters[key]
        }
      })

    if (state.formData.showBundled === false) {
      query['projectId'] = 'null'
    }

    if (state.formData.showArchived === false) {
      query['archivedAt'] = 'null'
    }

    if (state.formData.createdAtFrom !== null) {
      query['createdAt[$gte]'] = state.formData.createdAtFrom.toISOString()
    }

    if (state.formData.createdAtTo !== null) {
      query['createdAt[$lte]'] = state.formData.createdAtTo.toISOString()
    }

    const oldQuery = queryString.parse(qs)
    if (Object.keys(oldQuery).length > 0) {
      Object.keys(oldQuery).forEach((key: string) => {
        if (key.startsWith('options[sort]')) {
          query[key] = oldQuery[key]
        }
      })
    }
    const newQs = queryString.stringify(query)

    if (project) {
      history.push({ pathname: `/projects/${project._id}`, search: newQs })
      return
    }
    history.push({ pathname: '/jobs', search: newQs })
  }, [history, project, qs, state.formData])

  return { state, dispatch, handleSubmit }
}

export default useJobFiltersDialog
