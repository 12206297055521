import React, { useReducer } from 'react'

import Vendor from '../../../lib/Vendor'

export type State = {
  selectedVendors: Vendor[]
  showAddVendorDialog: boolean
  refreshVendorList: boolean
}

export type Action =
  | { type: 'SET_SELECTED_VENDORS'; payload: { selectedVendors: Vendor[] } }
  | { type: 'SET_SHOW_ADD_VENDOR_DIALOG'; payload: { show: boolean } }
  | { type: 'SET_REFRESH_VENDOR_LIST'; payload: { refreshVendorList: boolean } }

export const initialState: State = {
  selectedVendors: [],
  refreshVendorList: true,
  showAddVendorDialog: false
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_SELECTED_VENDORS': {
      return {
        ...state,
        selectedVendors: action.payload.selectedVendors
      }
    }
    case 'SET_SHOW_ADD_VENDOR_DIALOG': {
      return {
        ...state,
        showAddVendorDialog: action.payload.show
      }
    }
    case 'SET_REFRESH_VENDOR_LIST': {
      return {
        ...state,
        refreshVendorList: action.payload.refreshVendorList
      }
    }
    default: {
      return state
    }
  }
}

export const useVendorsPage = (): {
  state: State
  dispatch: React.Dispatch<Action>
} => {
  const [state, dispatch] = useReducer(stateReducer, initialState)

  return { state, dispatch }
}
