import React, { useCallback, useEffect, useReducer } from 'react'
import axios from 'axios'
import useAxios from 'axios-hooks'

import config from '../../../config'
import OwnerGroup from '../../../lib/OwnerGroup'
import Vendor from '../../../lib/Vendor'
import { useLoginUser } from '../../../hooks/useLoginUser'
import { useTranslation } from 'react-i18next'

type FormData = {
  name: string
  ownerGroupId: string | null
  srcLang: string
  tgtLang: string
  unitPrice: number
  currency: string
  multipliers: {
    repetitions: number
    exact: number
    over95: number
    over85: number
    over75: number
    noMatch: number
  }
  isEnabled: boolean
}

export type State = {
  loading: boolean
  ownerGroups: OwnerGroup[]
  message: {
    isError: boolean
    text: string
  }
  formData: FormData
}

export type Action =
  | { type: 'SET_IS_LOADING'; payload: { isLoading: boolean } }
  | { type: 'OWNER_GROUPS_LOADED'; payload: { ownerGroups: OwnerGroup[] } }
  | { type: 'SET_MESSAGE'; payload: { message: string; isError?: boolean } }
  | { type: 'SET_FORM_VALUE'; payload: Partial<FormData> }

export const initialState: State = {
  loading: false,
  ownerGroups: [],
  message: {
    isError: false,
    text: ''
  },
  formData: {
    name: '',
    ownerGroupId: '',
    srcLang: 'en-US',
    tgtLang: 'en-US',
    unitPrice: 0,
    currency: 'JPY',
    multipliers: {
      repetitions: 0,
      exact: 0,
      over95: 0,
      over85: 0,
      over75: 0,
      noMatch: 0
    },
    isEnabled: true
  }
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_IS_LOADING': {
      return {
        ...state,
        loading: action.payload.isLoading
      }
    }
    case 'OWNER_GROUPS_LOADED': {
      const { ownerGroups } = action.payload
      return {
        ...state,
        loading: false,
        ownerGroups,
        formData: {
          ...state.formData,
          ownerGroupId: ownerGroups.length > 0 ? ownerGroups[0]._id : null
        }
      }
    }
    case 'SET_MESSAGE': {
      return {
        ...state,
        message: {
          text: action.payload.message,
          isError: action.payload.isError ?? false
        }
      }
    }
    case 'SET_FORM_VALUE': {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload
        }
      }
    }
    default: {
      return state
    }
  }
}

const useEditVendorDialog = (
  vendor?: Vendor
): {
  state: State
  dispatch: React.Dispatch<Action>
  handleSubmit: () => Promise<void>
} => {
  const loginUser = useLoginUser()
  const { t } = useTranslation()

  const [state, dispatch] = useReducer(stateReducer, initialState)

  const [, fetchOwnerGroups] = useAxios<OwnerGroup[]>(
    `${config[config.STAGE].endpoint}/api/v1/ownerGroups`,
    { manual: true }
  )

  useEffect(() => {
    if (!vendor && state.ownerGroups) {
      dispatch({
        type: 'SET_FORM_VALUE',
        payload: {
          ownerGroupId: state.ownerGroups.length > 0 ? state.ownerGroups[0]._id : ''
        }
      })
    }

    if (vendor) {
      dispatch({
        type: 'SET_FORM_VALUE',
        payload: {
          name: vendor.name,
          ownerGroupId: vendor.ownerGroupId,
          srcLang: vendor.srcLang,
          tgtLang: vendor.tgtLang,
          unitPrice: vendor.unitPrice,
          currency: vendor.currency,
          multipliers: vendor.multipliers,
          isEnabled: vendor.isEnabled
        }
      })
    }
  }, [vendor, loginUser, state.ownerGroups])

  useEffect(() => {
    // loginUserがcistateの場合、全owernGroupを設定する
    // loginUserがowner-groupの場合、自身のownerGroupを設定する
    const loadOwnerGroups = async (): Promise<void> => {
      if (!loginUser) {
        return
      }
      if (loginUser.organizationType !== 'cistate') {
        const ownerGroups: OwnerGroup[] = loginUser.organizations as OwnerGroup[]
        dispatch({ type: 'OWNER_GROUPS_LOADED', payload: { ownerGroups } })
      } else {
        dispatch({ type: 'SET_IS_LOADING', payload: { isLoading: true } })
        const response = await fetchOwnerGroups()
        dispatch({ type: 'OWNER_GROUPS_LOADED', payload: { ownerGroups: response.data ?? [] } })
      }
    }
    loadOwnerGroups()
  }, [fetchOwnerGroups, loginUser])

  const handleSubmit = useCallback(async () => {
    dispatch({ type: 'SET_IS_LOADING', payload: { isLoading: true } })
    dispatch({ type: 'SET_MESSAGE', payload: { message: '', isError: false } })

    if (vendor) {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { message: t('翻訳会社情報を更新しています'), isError: false }
      })
      await axios.patch(
        `${config[config.STAGE].endpoint}/api/v1/vendors/${vendor?._id}`,
        state.formData
      )
    } else {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { message: t('翻訳会社情報を登録しています'), isError: false }
      })
      await axios.post(`${config[config.STAGE].endpoint}/api/v1/vendors`, state.formData)
    }
  }, [state.formData, t, vendor])

  return {
    state,
    dispatch,
    handleSubmit
  }
}

export default useEditVendorDialog
