import { useCallback, useReducer } from 'react'
import axios from 'axios'

import config from '../../../config'
import Job from '../../../lib/Job'

type FormData = {
  status: string
  dueAt: Date | null
  startReviewAt: Date | null
  reviewDueAt: Date | null
}

type RequestBody = {
  dueAt?: Date | null
  startReviewAt?: Date | null
  reviewDueAt?: Date | null
  status?: string
}

export type State = {
  loading: boolean
  message: {
    isError: boolean
    text: string
  }
  formData: FormData
  dueAtDisabled: boolean
  startReviewAtDisabled: boolean
  reviewDueAtDisabled: boolean
}

export type Action =
  | { type: 'SET_IS_LOADING'; payload: { loading: boolean } }
  | { type: 'SET_MESSAGE'; payload: { message: string; isError?: boolean } }
  | { type: 'SET_FORM_VALUE'; payload: { [key: string]: unknown } }
  | { type: 'TOGGLE_DUE_AT_DISABLED' }
  | { type: 'TOGGLE_START_REVIEW_AT_DISABLED' }
  | { type: 'TOGGLE_REVIEW_DUE_AT_DISABLED' }

export const initialState: State = {
  loading: false,
  message: {
    isError: false,
    text: ''
  },
  formData: {
    status: '',
    dueAt: null,
    startReviewAt: null,
    reviewDueAt: null
  },
  dueAtDisabled: true,
  startReviewAtDisabled: true,
  reviewDueAtDisabled: true
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return {
        ...state,
        loading: action.payload.loading
      }
    case 'SET_MESSAGE':
      return {
        ...state,
        message: {
          text: action.payload.message,
          isError: action.payload.isError ?? false
        }
      }
    case 'SET_FORM_VALUE':
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload
        }
      }
    case 'TOGGLE_DUE_AT_DISABLED':
      if (state.dueAtDisabled === false) {
        return {
          ...state,
          dueAtDisabled: true,
          formData: {
            ...state.formData,
            dueAt: null
          }
        }
      }
      return {
        ...state,
        dueAtDisabled: false
      }
    case 'TOGGLE_START_REVIEW_AT_DISABLED':
      if (state.startReviewAtDisabled === false) {
        return {
          ...state,
          startReviewAtDisabled: true,
          formData: {
            ...state.formData,
            startReviewAt: null
          }
        }
      }
      return {
        ...state,
        startReviewAtDisabled: false
      }
    case 'TOGGLE_REVIEW_DUE_AT_DISABLED':
      if (state.reviewDueAtDisabled === false) {
        return {
          ...state,
          reviewDueAtDisabled: true,
          formData: {
            ...state.formData,
            reviewDueAt: null
          }
        }
      }
      return {
        ...state,
        reviewDueAtDisabled: false
      }
    default:
      return state
  }
}

const useEditJobsDialog = (
  jobs: Job[]
): {
  state: State
  dispatch: React.Dispatch<Action>
  handleSubmit: () => Promise<void>
} => {
  const [state, dispatch] = useReducer(stateReducer, initialState)

  const handleSubmit = useCallback(async () => {
    dispatch({ type: 'SET_IS_LOADING', payload: { loading: true } })
    dispatch({ type: 'SET_MESSAGE', payload: { message: '', isError: false } })

    const body: RequestBody = {}

    if (state.formData.status !== '') {
      body.status = state.formData.status
    }

    if (!state.dueAtDisabled) {
      body.dueAt = state.formData.dueAt
    }

    if (!state.startReviewAtDisabled) {
      body.startReviewAt = state.formData.startReviewAt
    }

    if (!state.reviewDueAtDisabled) {
      body.reviewDueAt = state.formData.reviewDueAt
    }

    await Promise.all(
      jobs.map(job => {
        return axios.patch(`${config[config.STAGE].endpoint}/api/v1/jobs/${job._id}`, body)
      })
    )
  }, [jobs, state])

  return { state, dispatch, handleSubmit }
}

export default useEditJobsDialog
