import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Alert, Breadcrumb } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { LinkContainer } from 'react-router-bootstrap'
import useAxios from 'axios-hooks'

import config from '../../config'
import Job from '../../lib/Job'
import Project from '../../lib/Project'
import { OrganizationType } from '../../lib/Utils'

import { useLoginUser } from '../../hooks/useLoginUser'
import {
  ProjectDetailPageContext,
  useProjectDetailPage,
  State,
  Action
} from './useProjectDetailPage'
import { useRecalculate } from '../../hooks/useRecalculate'

import Dialogs from './Dialogs'
import ProjectDetailCard from './ProjectDetailCard'
import Toolbar from './Toolbar'
import JobList from '../../components/JobList'
import { CenteredSpinner } from '../../components/common/CenteredSpinner'

type ContainerProps = RouteComponentProps<{ id: string }> & {
  className?: string
}

type Props = ContainerProps & {
  project: Project | null
  loading: boolean
  isVisible: () => boolean
  isEditorVisible: () => boolean
  updateSelection: (selected: Job[]) => void
  refreshJobList: () => void
  state: State
  dispatch: React.Dispatch<Action>
}

export const Component: React.FC<Props> = (props: Props) => {
  const {
    project,
    loading,
    isVisible,
    isEditorVisible,
    updateSelection,
    refreshJobList,
    state
  } = props
  const { t } = useTranslation()

  // projectの読み込みが完了していない段階では子コンポーネントを描画せず Spinner を表示させる
  if (!project || loading) {
    return <CenteredSpinner />
  }

  return (
    <div className="Page">
      {isVisible() && (
        <>
          <Breadcrumb>
            <LinkContainer to="/projects">
              <Breadcrumb.Item>{t('プロジェクト一覧')}</Breadcrumb.Item>
            </LinkContainer>
            <Breadcrumb.Item active>{project.name}</Breadcrumb.Item>
          </Breadcrumb>
          <div className="ml-3 mr-3 mb-3">
            {state.error.isError && <Alert variant="danger">{t(state.error.message)}</Alert>}
            <h2 data-testid="project-name-header">{project.name}</h2>
            <ProjectDetailCard project={project} refreshJobList={refreshJobList} />
          </div>
          <Toolbar project={project} />
          <JobList
            options={'projectId=' + project._id}
            showDownloadButtonColumn={true}
            showOpenEditorButtonColumn={isEditorVisible()}
            showProjectInfoColumns={false}
            updateSelection={updateSelection}
            toggleRefresh={state.toggleRefresh}
          />
          <Dialogs project={project} refreshJobList={refreshJobList} />
        </>
      )}
    </div>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { state, dispatch } = useProjectDetailPage()
  const projectId = props.match.params.id
  const loginUser = useLoginUser()
  const { project, loading } = useRecalculate({ projectId, toggleRefresh: state.toggleRefresh })
  const { t } = useTranslation()

  const refreshJobList = (): void => {
    dispatch({ type: 'TOGGLE_REFRESH' })
    dispatch({ type: 'SET_SELECTED_JOBS', payload: { selected: [] } })
  }

  const updateSelection = (selected: Job[]): void => {
    dispatch({ type: 'SET_SELECTED_JOBS', payload: { selected } })
  }

  const isVisible = (): boolean => {
    if (!loginUser || !project) {
      return false
    }
    if (loginUser.organizationType === OrganizationType.CISTATE) {
      return true
    }
    if (
      loginUser.organizationType === OrganizationType.OWNER_GROUP &&
      project.ownerGroupId &&
      loginUser.organizationIds?.includes(project.ownerGroupId)
    ) {
      return true
    }
    if (
      loginUser.organizationType === OrganizationType.VENDOR &&
      project.vendorId &&
      loginUser.organizationIds?.includes(project.vendorId)
    ) {
      return true
    }

    return false
  }

  const isEditorVisible = (): boolean => {
    if (!loginUser) return false
    // vendorユーザー以外にエディタボタンを表示
    return loginUser.organizationType !== OrganizationType.VENDOR
  }

  const [jobsResponse] = useAxios<Job[]>({
    url: `${config[config.STAGE].endpoint}/api/v1/jobs`,
    params: {
      projectId
    }
  })

  useEffect(() => {
    const jobs = jobsResponse.data

    if (jobs) {
      const errorCodes = jobs
        .filter((job: Job) => {
          return job.errorDetails !== null
        })
        .map((job: Job) => {
          return job.errorDetails?.code
        })
      if (errorCodes.includes('CREATE-TRANSLATION-KIT:FIND_CATEGORY_MAPPING')) {
        dispatch({
          type: 'SET_ERROR',
          payload: { isError: true, message: t('マッピング表にカテゴリコードが存在しません。') }
        })
      }
    }
  }, [dispatch, jobsResponse, t])

  const childProps: Props = {
    ...props,
    project,
    loading,
    isVisible,
    isEditorVisible,
    updateSelection,
    refreshJobList,
    state,
    dispatch
  }

  return (
    <ProjectDetailPageContext.Provider value={{ state, dispatch }}>
      <Component {...childProps} />
    </ProjectDetailPageContext.Provider>
  )
}

Container.displayName = 'ProjectDetail'
export default Container
