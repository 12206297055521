import React, { useContext } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Project from '../../../../lib/Project'
import { ProjectDetailPageContext } from '../../useProjectDetailPage'
import { useLoginUser } from '../../../../hooks/useLoginUser'
import { OverlayTriggerButton } from '../../../../components/common/OverlayTriggerButton'

type ContainerProps = {
  project: Project
  hasOrderApproved: () => boolean
  hasOrderCancelled: () => boolean
}

type Props = ContainerProps & {
  canAddOrRemoveJobs: () => boolean
  showSelectJobsDialog: () => void
  showRemoveJobsDialog: () => void
}

export const Component: React.FC<Props> = (props: Props) => {
  const { state } = useContext(ProjectDetailPageContext)
  const { canAddOrRemoveJobs, showSelectJobsDialog, showRemoveJobsDialog } = props
  const { t } = useTranslation()
  const loginUser = useLoginUser()

  return (
    <>
      {loginUser?.organizationType !== 'vendor' && (
        <>
          <Button
            disabled={!canAddOrRemoveJobs()}
            onClick={showSelectJobsDialog}
            size="sm"
            style={!canAddOrRemoveJobs() ? { pointerEvents: 'none' } : {}}
            data-testid="add-jobs-button"
          >
            {t('ジョブを追加')}
          </Button>
          <OverlayTriggerButton
            tooltipLabel={
              state.selectedJobs.length === 0
                ? t('ジョブが選択されていません')
                : t('ジョブを除外できません')
            }
            buttonLabel={t('ジョブをプロジェクトから除外')}
            isOverlayed={(): boolean => state.selectedJobs.length === 0 || !canAddOrRemoveJobs()}
            tooltipId="tooltip-job-not-selected"
            variant="secondary"
            buttonClassName="ml-2"
            onClick={showRemoveJobsDialog}
            size="sm"
            data-testid="remove-jobs-button"
          />
        </>
      )}
    </>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { dispatch } = useContext(ProjectDetailPageContext)
  const { project, hasOrderApproved, hasOrderCancelled } = props
  const loginUser = useLoginUser()

  const canAddOrRemoveJobs = (): boolean => {
    if (hasOrderApproved() || hasOrderCancelled()) {
      if (loginUser?.organizationType === 'cistate' && loginUser?.role === 'admin') {
        if (project.mdmJobsCount && project.mdmJobsCount > 0) {
          return false
        }
        return true
      }
      return false
    }
    return true
  }

  const showSelectJobsDialog = (): void => {
    dispatch({
      type: 'SET_SHOW_SELECT_JOBS_DIALOG',
      payload: { show: true }
    })
  }

  const showRemoveJobsDialog = (): void => {
    dispatch({
      type: 'SET_SHOW_REMOVE_JOBS_DIALOG',
      payload: { show: true }
    })
  }

  return (
    <Component
      {...props}
      canAddOrRemoveJobs={canAddOrRemoveJobs}
      showSelectJobsDialog={showSelectJobsDialog}
      showRemoveJobsDialog={showRemoveJobsDialog}
    />
  )
}

Container.displayName = 'JobActions'
export default Container
