import React, { useContext, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import queryString from 'query-string'
import useAxios from 'axios-hooks'
import Qs from 'qs'

import config from '../../../config'
import Project from '../../../lib/Project'
import ToggleSortOrderButton from '../../../components/ToggleSortOrderButton'
import useQueryString from '../../../hooks/useQueryString'
import { ProjectDetailPageContext } from '../useProjectDetailPage'

type ContainerProps = RouteComponentProps & {
  project: Project
}

type Props = ContainerProps & {
  count: number
  totalCount: number
  clearFilters: () => void
  onChangeSortBy: (value: string) => void
  toggleOrderByAsc: () => void
  showJobFiltersDialog: () => void
}

export const Component: React.FC<Props> = (props: Props) => {
  const {
    count,
    totalCount,
    clearFilters,
    onChangeSortBy,
    toggleOrderByAsc,
    showJobFiltersDialog
  } = props
  const { t } = useTranslation()
  const { sortBy, orderByAsc } = useQueryString(props.location.search)

  return (
    <>
      <Button className="ml-auto" disabled variant="link" data-testid="jobs-count">
        {count} / {totalCount}
      </Button>
      <Button className="ml-2" onClick={showJobFiltersDialog} variant="light">
        {t('絞り込み')}
      </Button>
      <Button className="ml-2" onClick={clearFilters} variant="light">
        {t('絞り込み解除')}
      </Button>
      <select
        className="ml-2"
        onChange={(e: React.FormEvent<HTMLSelectElement>): void =>
          onChangeSortBy(e.currentTarget.value)
        }
        value={sortBy ? sortBy : 'properties.anken_id'}
        data-testid="select-sort-by"
      >
        <option value="properties.anken_id">{t('案件ID')}</option>
        <option value="status">{t('ステータス')}</option>
        <option value="wordCount">{t('ワード数')}</option>
        <option value="weightedWordCount">{t('加重ワード数')}</option>
      </select>
      <ToggleSortOrderButton
        onClick={toggleOrderByAsc}
        orderByAsc={orderByAsc ? orderByAsc : false}
      />
    </>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { project } = props
  const { dispatch } = useContext(ProjectDetailPageContext)

  const [{ data: countData }, countRefetch] = useAxios({
    url: `${config[config.STAGE].endpoint}/api/v1/jobs/count`,
    params: {
      ...Qs.parse(props.location.search.substr(1)),
      projectId: project._id
    }
  })
  const [{ data: totalData }, totalRefetch] = useAxios({
    url: `${config[config.STAGE].endpoint}/api/v1/jobs/count`,
    params: { projectId: project._id }
  })

  const qs = props.location.search
  const { sortBy, orderByAsc } = useQueryString(qs)

  const clearFilters = (): void => {
    const query = queryString.parse(qs)
    Object.keys(query).forEach((key: string) => {
      if (!key.startsWith('options[sort]')) {
        delete query[key]
      }
    })

    props.history.push(
      `/projects/${project._id}${
        Object.keys(query).length > 0 ? '?' + queryString.stringify(query) : ''
      }`
    )
  }

  const onChangeSortBy = (value: string): void => {
    const queries = queryString.parse(qs)
    delete queries[`options[sort][${sortBy}]`]
    queries[`options[sort][${value}]`] = orderByAsc ? '1' : '-1'

    const options = queryString.stringify(queries)
    props.history.push(`/projects/${project._id}?${options}`)
  }

  const toggleOrderByAsc = (): void => {
    const queries = queryString.parse(qs)
    queries[`options[sort][${sortBy ? sortBy : 'properties.anken_id'}]`] = !orderByAsc ? '1' : '-1'
    const options = queryString.stringify(queries)
    props.history.push(`/projects/${project._id}?${options}`)
  }

  const showJobFiltersDialog = (): void => {
    dispatch({
      type: 'SET_SHOW_JOB_FILTERS_DIALOG',
      payload: { show: true }
    })
  }

  useEffect(() => {
    countRefetch()
    totalRefetch()
  }, [qs, countRefetch, totalRefetch])

  return (
    <Component
      {...props}
      count={countData?.count}
      totalCount={totalData?.count}
      clearFilters={clearFilters}
      onChangeSortBy={onChangeSortBy}
      toggleOrderByAsc={toggleOrderByAsc}
      showJobFiltersDialog={showJobFiltersDialog}
    />
  )
}

Container.displayName = 'FilterAndSort'
export default withRouter(Container)
