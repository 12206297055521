import { useEffect, useState } from 'react'
import {
  GridApi,
  GridOptions,
  GridReadyEvent,
  IDatasource,
  IGetRowsParams,
  RowDataChangedEvent,
  RowSelectedEvent
} from '@ag-grid-community/core'
import axios from 'axios'
import queryString from 'query-string'

import config from '../../config'
import VendorNameRenderer from './VendorNameRenderer'
import CustomHeader from '../CustomHeader'
import { useLoginUser } from '../../hooks/useLoginUser'
import Vendor from '../../lib/Vendor'
import RowData, { VendorToRowData } from './RowData'
import useColumnDefs from './useColumnDefs'

const useVendorList = ({
  qs,
  options,
  updateSelection,
  selected,
  enableEdit,
  showAllColumns,
  showOwnerGroupNameInNameColumn,
  toggleRefresh
}: {
  qs: string
  options?: string
  updateSelection?: (selected: Vendor[]) => void
  selected?: string[]
  enableEdit?: boolean
  showAllColumns?: boolean
  showOwnerGroupNameInNameColumn?: boolean
  toggleRefresh?: boolean
}): { gridOptions: GridOptions } => {
  const loginUser = useLoginUser()

  const { columnDefs, defaultColDef } = useColumnDefs(enableEdit, showAllColumns)

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)
  const [vendorsLoaded, setVendorsLoaded] = useState(false)

  useEffect(() => {
    if (gridApi) {
      const updateData = (): void => {
        const dataSource: IDatasource = {
          rowCount: undefined,
          getRows: (params: IGetRowsParams) => {
            setTimeout(async () => {
              const query = queryString.parse(qs)
              const defaultSort =
                Object.keys(query).filter(key => {
                  return key.startsWith('options')
                }).length > 0
                  ? false
                  : true

              const sort: {
                [key: string]: number
              } = {}
              if (defaultSort) {
                sort['name'] = -1
              } else {
                const sortBy = Object.keys(query)
                  .filter(key => {
                    return key.startsWith('options[sort]')
                  })
                  .map(key => {
                    return key.replace(/options\[sort\]\[([^\]]+)\]/, '$1')
                  })
                sortBy.forEach(key => {
                  if (Object.keys(sort).includes(key)) {
                    const sortOrder = query[`options[sort][${key}]`]
                    if (typeof sortOrder === 'string') {
                      sort[key] = parseInt(sortOrder)
                    }
                  }
                })
              }

              const vendorsResponse = await axios.get(
                `${config[config.STAGE].endpoint}/api/v1/vendors`,
                {
                  params: {
                    ...query,
                    showAll: loginUser?.organizationType === 'cistate',
                    options: {
                      limit: 100,
                      skip: params.startRow,
                      sort
                    }
                  }
                }
              )

              const vendors: Vendor[] = vendorsResponse.data

              const rowsThisPage: RowData[] = vendors.map(vendor =>
                VendorToRowData(vendor, true, showOwnerGroupNameInNameColumn || false)
              )

              const lastRow = vendors.length < 100 ? params.startRow + vendors.length : -1

              params.successCallback(rowsThisPage, lastRow)
              if (lastRow !== -1) {
                setVendorsLoaded(true)
              }
            }, 500)
          }
        }
        gridApi.setDatasource(dataSource)
      }

      updateData()
    }
  }, [gridApi, loginUser?.organizationType, options, qs, toggleRefresh])

  useEffect(() => {
    if (gridApi && vendorsLoaded) {
      gridApi.forEachNode(rowNode => {
        if (rowNode.data && selected && selected.includes(rowNode.data._id)) {
          rowNode.setSelected(true)
        }
      })
    }
  }, [gridApi, selected, vendorsLoaded])

  const onGridReady = (params: GridReadyEvent): void => {
    setGridApi(params.api)
  }

  const onRowDataChanged = (params: RowDataChangedEvent): void => {
    params.columnApi.autoSizeAllColumns(false)
  }

  const onRowSelected = (params: RowSelectedEvent): void => {
    const selected = params.api.getSelectedRows()
    if (updateSelection) {
      updateSelection(selected)
    }
  }

  const gridOptions: GridOptions = {
    cacheOverflowSize: 5,
    enableCellTextSelection: true,
    columnDefs: columnDefs,
    defaultColDef: defaultColDef,
    frameworkComponents: { agColumnHeader: CustomHeader, VendorNameRenderer: VendorNameRenderer },
    infiniteInitialRowCount: -1,
    isRowSelectable: node => {
      if (loginUser?.organizationType === 'cistate') {
        return true
      }
      if (selected) {
        if (node.data && loginUser?.organizationIds?.includes(node.data.ownerGroupId)) {
          return true
        }
        return false
      }
      return true
    },
    maxBlocksInCache: 10,
    maxConcurrentDatasourceRequests: 1,
    onGridReady: onGridReady,
    onRowDataChanged: onRowDataChanged,
    onRowSelected: onRowSelected,
    paginationPageSize: 100,
    rowBuffer: 0,
    rowModelType: 'infinite',
    rowMultiSelectWithClick: true,
    rowSelection: 'multiple'
  }

  return { gridOptions }
}

export default useVendorList
