import React from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useTMEntriesPage } from '../useTMEntriesPage'

import { useFilters } from './useFilters'

const BasicConditions: React.FC = () => {
  const { t } = useTranslation()

  const { state, dispatch } = useFilters()
  const { dispatch: pageDispatch } = useTMEntriesPage()

  const langOptions = (
    <>
      <option value="">({t('言語指定なし')})</option>
      <option value="de-DE">{t('German')}</option>
      <option value="en-US">{t('English (US)')}</option>
      <option value="es-MX">{t('Spanish (Mexico)')}</option>
      <option value="fr-FR">{t('French')}</option>
      <option value="it-IT">{t('Italian')}</option>
      <option value="ja-JP">{t('Japanese')}</option>
      <option value="ko-KR">{t('Korean')}</option>
      <option value="zh-CN">{t('Chinese (Simplified)')}</option>
      <option value="zh-TW">{t('Chinese (Taiwan)')}</option>
      <option value="th-TH">{t('Thai')}</option>
    </>
  )

  return (
    <>
      <span>{t('原文')}:</span>
      <Form.Group style={{ marginTop: '0.5rem' }}>
        <Form.Control
          size="sm"
          as="select"
          value={state.srcLang}
          onChange={(e): void => {
            dispatch({ type: 'UPDATE', payload: { srcLang: e.currentTarget.value } })
          }}
        >
          {langOptions}
        </Form.Control>
      </Form.Group>
      <Form.Group>
        <Form.Control
          as="textarea"
          value={state.srcTmx}
          onChange={(e): void => {
            dispatch({ type: 'UPDATE', payload: { srcTmx: e.currentTarget.value } })
          }}
        />
      </Form.Group>
      <span>{t('訳文')}:</span>
      <Form.Group style={{ marginTop: '0.5rem' }}>
        <Form.Control
          size="sm"
          as="select"
          value={state.tgtLang}
          onChange={(e): void => {
            dispatch({ type: 'UPDATE', payload: { tgtLang: e.currentTarget.value } })
          }}
        >
          {langOptions}
        </Form.Control>
      </Form.Group>
      <Form.Group>
        <Form.Control
          as="textarea"
          value={state.tgtTmx}
          onChange={(e): void => {
            dispatch({ type: 'UPDATE', payload: { tgtTmx: e.currentTarget.value } })
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('大文字小文字を区別する')}:</Form.Label>
        <Form.Check
          inline
          checked={!state.ignoreCase}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            dispatch({ type: 'UPDATE', payload: { ignoreCase: !e.currentTarget.checked } })
          }
        />
      </Form.Group>
      <Form.Group style={{ marginTop: '0.5rem' }}>
        <Form.Label>
          {t('検索モード')}{' '}
          <a
            href="#"
            onClick={(e): void => {
              e.preventDefault()
              pageDispatch({ type: 'UPDATE', payload: { showSearchModeHelpDialog: true } })
            }}
          >
            [?]
          </a>
          :
        </Form.Label>
        <Form.Control
          size="sm"
          as="select"
          value={state.mode}
          onChange={(e): void => {
            dispatch({ type: 'UPDATE', payload: { mode: e.currentTarget.value } })
          }}
        >
          <option value="phrase">{t('フレーズ検索')}</option>
          <option value="perfect">{t('完全一致検索')}</option>
          <option value="advanced">{t('詳細検索')}</option>
          <option value="fuzzy">{t('曖昧検索')}</option>
        </Form.Control>
      </Form.Group>
    </>
  )
}

export default BasicConditions
