import React, { useContext, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import useAxios from 'axios-hooks'
import queryString from 'query-string'

import config from '../../../config'
import ToggleSortOrderButton from '../../../components/ToggleSortOrderButton'
import useQueryString from '../../../hooks/useQueryString'
import { JobsPageContext } from '../useJobsPage'

type ContainerProps = RouteComponentProps

type Props = ContainerProps & {
  count: number
  totalCount: number
  clearFilters: () => void
  onChangeSortBy: (value: string) => void
  toggleOrderByAsc: () => void
}

export const Component: React.FC<Props> = (props: Props) => {
  const { count, totalCount, clearFilters, onChangeSortBy, toggleOrderByAsc } = props
  const { dispatch } = useContext(JobsPageContext)
  const qs = props.location.search
  const { sortBy, orderByAsc } = useQueryString(qs)
  const { t } = useTranslation()

  return (
    <>
      <Button className="ml-2" disabled variant="link">
        {count} / {totalCount}
      </Button>
      <Button
        className="ml-2"
        onClick={(): void =>
          dispatch({ type: 'SET_SHOW_JOB_FILTERS_DIALOG', payload: { show: true } })
        }
        variant="light"
        data-testid="job-filters-button"
      >
        {t('絞り込み')}
      </Button>
      <Button className="ml-2" onClick={clearFilters} variant="light">
        {t('絞り込み解除')}
      </Button>
      <select
        className="ml-2"
        onChange={(e: React.FormEvent<HTMLSelectElement>): void =>
          onChangeSortBy(e.currentTarget.value)
        }
        value={sortBy ? sortBy : 'createdAt'}
      >
        <option value="createdAt">{t('作成日時')}</option>
        <option value="updatedAt">{t('更新日時')}</option>
        <option value="name">{t('ファイル名')}</option>
        <option value="properties.anken_id">{t('案件ID')}</option>
        <option value="properties.category_code">{t('カテゴリコード')}</option>
        <option value="properties.brand_code">{t('ブランドコード')}</option>
        <option value="srcLang">{t('ソース言語')}</option>
        <option value="tgtLang">{t('ターゲット言語')}</option>
        <option value="status">{t('ステータス')}</option>
        <option value="project.name">{t('見積束ねプロジェクト名')}</option>
      </select>
      <ToggleSortOrderButton
        onClick={toggleOrderByAsc}
        orderByAsc={orderByAsc ? orderByAsc : false}
      />
    </>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const qs = props.location.search
  const { sortBy, orderByAsc } = useQueryString(qs)

  const [{ data: countData }, countRefetch] = useAxios(
    `${config[config.STAGE].endpoint}/api/v1/jobs/count${qs}`
  )
  const [{ data: totalData }, totalRefetch] = useAxios(
    `${config[config.STAGE].endpoint}/api/v1/jobs/count`
  )

  useEffect(() => {
    countRefetch()
    totalRefetch()
  }, [countRefetch, props, qs, totalRefetch])

  const clearFilters = (): void => {
    const query = queryString.parse(qs)
    Object.keys(query).forEach((key: string) => {
      if (!key.startsWith('options[sort]')) {
        delete query[key]
      }
    })

    props.history.push(
      `/jobs?archivedAt=null${
        Object.keys(query).length > 0 ? '&' + queryString.stringify(query) : ''
      }`
    )
  }

  const onChangeSortBy = (value: string): void => {
    const queries = queryString.parse(qs)
    delete queries[`options[sort][${sortBy}]`]
    queries[`options[sort][${value}]`] = orderByAsc ? '1' : '-1'

    const options = queryString.stringify(queries)
    props.history.push('/jobs?' + options)
  }

  const toggleOrderByAsc = (): void => {
    const queries = queryString.parse(qs)
    queries[`options[sort][${sortBy ? sortBy : 'createdAt'}]`] = !orderByAsc ? '1' : '-1'

    const options = queryString.stringify(queries)
    props.history.push('/jobs?' + options)
  }

  return (
    <Component
      {...props}
      count={countData?.count}
      totalCount={totalData?.count}
      clearFilters={clearFilters}
      onChangeSortBy={onChangeSortBy}
      toggleOrderByAsc={toggleOrderByAsc}
    />
  )
}

Container.displayName = 'FilterAndSort'
export default withRouter(Container)
