import { useCallback, useEffect, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import useAxios from 'axios-hooks'
import queryString from 'query-string'

import config from '../../../config'
import User from '../../../lib/User'

type FormData = {
  name: string
  srcLang: string
  tgtLang: string
  status: string
  vendorId: string
  ownerId: string
  ownerGroupId: string
  createdAtFrom: Date | null
  createdAtTo: Date | null
  showArchived: boolean
}

type Filters = {
  [key: string]: string
}

export type State = {
  users: User[]
  formData: FormData
}

export type Action =
  | { type: 'SET_FORM_VALUE'; payload: Partial<FormData> }
  | { type: 'USERS_LOADED'; payload: { users: User[] } }
  | { type: 'CLEAR_FILTERS' }

export const initialState: State = {
  users: [],
  formData: {
    name: '',
    srcLang: '',
    tgtLang: '',
    status: '',
    vendorId: '',
    ownerId: '',
    ownerGroupId: '',
    createdAtFrom: null,
    createdAtTo: null,
    showArchived: false
  }
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_FORM_VALUE': {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload
        }
      }
    }
    case 'USERS_LOADED': {
      const { users } = action.payload
      return {
        ...state,
        users: users,
        formData: {
          ...state.formData
        }
      }
    }
    case 'CLEAR_FILTERS':
      return initialState
    default:
      return state
  }
}

const useProjectFiltersDialog = (
  qs: string
): {
  state: State
  dispatch: React.Dispatch<Action>
  handleSubmit: () => Promise<void>
} => {
  const [state, dispatch] = useReducer(stateReducer, initialState)
  const history = useHistory()
  const [, fetchUsers] = useAxios<User[]>(`${config[config.STAGE].endpoint}/api/v1/users`, {
    manual: true
  })

  useEffect(() => {
    const query = queryString.parse(qs)

    dispatch({
      type: 'SET_FORM_VALUE',
      payload: {
        name: !query['name[$regex]']
          ? ''
          : typeof query['name[$regex]'] === 'string'
          ? query['name[$regex]'].replace('^', '')
          : '',
        srcLang: !query['srcLang']
          ? ''
          : typeof query['srcLang'] === 'string'
          ? query['srcLang']
          : '',
        tgtLang: !query['tgtLang']
          ? ''
          : typeof query['tgtLang'] === 'string'
          ? query['tgtLang']
          : '',
        status: !query['status'] ? '' : typeof query['status'] === 'string' ? query['status'] : '',
        vendorId: !query['vendorId']
          ? ''
          : typeof query['vendorId'] === 'string'
          ? query['vendorId']
          : '',
        ownerId: !query['ownerId']
          ? ''
          : typeof query['ownerId'] === 'string'
          ? query['ownerId']
          : '',
        ownerGroupId: !query['ownerGroupId']
          ? ''
          : typeof query['ownerGroupId'] === 'string'
          ? query['ownerGroupId']
          : '',

        createdAtFrom: !query['createdAt[$gte]']
          ? null
          : typeof query['createdAt[$gte]'] === 'string'
          ? new Date(query['createdAt[$gte]'])
          : null,
        createdAtTo: !query['createdAt[$lte]']
          ? null
          : typeof query['createdAt[$lte]'] === 'string'
          ? new Date(query['createdAt[$lte]'])
          : null,
        showArchived: !query['archivedAt']
          ? true
          : typeof query['archivedAt'] === 'string'
          ? false
          : true
      }
    })
  }, [qs])

  useEffect(() => {
    const loadUsers = async (): Promise<void> => {
      const response = await fetchUsers({
        params: {
          isEnabled: true
        }
      })
      dispatch({ type: 'USERS_LOADED', payload: { users: response.data ?? [] } })
    }
    loadUsers()
  }, [fetchUsers])

  const handleSubmit = useCallback(async () => {
    const filters: Filters = {
      name: state.formData.name,
      srcLang: state.formData.srcLang,
      tgtLang: state.formData.tgtLang,
      status: state.formData.status,
      vendorId: state.formData.vendorId,
      ownerId: state.formData.ownerId,
      ownerGroupId: state.formData.ownerGroupId,
      showArchived: state.formData.showArchived.toString()
    }
    const keys = ['name', 'srcLang', 'tgtLang', 'status', 'vendorId', 'ownerId', 'ownerGroupId']

    const query: { [key: string]: string | string[] | null | undefined } = {}

    keys
      .filter(key => filters[key] !== '')
      .forEach(key => {
        if (key === 'name') {
          query[key + '[$regex]'] = '^' + filters[key]
        } else {
          query[key] = filters[key]
        }
      })

    if (state.formData.showArchived === false) {
      query['archivedAt'] = 'null'
    }

    if (state.formData.createdAtFrom !== null) {
      query['createdAt[$gte]'] = state.formData.createdAtFrom.toISOString()
    }

    if (state.formData.createdAtTo !== null) {
      query['createdAt[$lte]'] = state.formData.createdAtTo.toISOString()
    }

    const oldQuery = queryString.parse(qs)
    if (Object.keys(oldQuery).length > 0) {
      Object.keys(oldQuery).forEach((key: string) => {
        if (key.startsWith('options[sort]')) {
          query[key] = oldQuery[key]
        }
      })
    }
    const newQs = queryString.stringify(query)

    history.push({ pathname: '/projects', search: newQs })
  }, [history, qs, state.formData])

  return { state, dispatch, handleSubmit }
}

export default useProjectFiltersDialog
