type Status =
  | 'initializing'
  | 'prepared'
  | 'quote_requested'
  | 'quote_ready'
  | 'order_confirmed'
  | 'translating'
  | 'translated'
  | 'reviewed'
  | 'completed'
  | 'cancelled'
  | 'completed_returned_to_mdm'
  | 'error'
  | 'rejected'

export const statusCodeToStatusName = (statusCode: string): string => {
  switch (statusCode) {
    case 'initializing':
      return '準備中'
    case 'prepared':
      return '準備処理完了'
    case 'quote_requested':
      return '見積依頼中'
    case 'quote_ready':
      return '見積提出済み'
    case 'approved':
      return '発注承認済み'
    case 'order_confirmed':
      return '発注確定'
    case 'translating':
      return '翻訳中'
    case 'translated':
      return '翻訳済み'
    case 'reviewed':
      return 'レビュー済み'
    case 'completed':
      return '完了'
    case 'cancelled':
      return 'キャンセル済み'
    case 'completed_returned_to_mdm':
      return '完了（MDM戻し済み）'
    case 'error':
      return 'エラー'
    case 'rejected':
      return '完了（否認）'
    default:
      return statusCode
  }
}

export default Status
