import useAxios from 'axios-hooks'

import config from '../config'
import { useLoginUser } from './useLoginUser'
import Vendor from '../lib/Vendor'

export const useVendors = (showAll?: boolean): Vendor[] | null => {
  const loginUser = useLoginUser()

  const [vendors] = useAxios({
    url: `${config[config.STAGE].endpoint}/api/v1/vendors`,
    params: {
      showAll: loginUser?.organizationType !== 'vendor' && showAll
    }
  })

  return vendors.data
}
