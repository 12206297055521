import React from 'react'
import { ICellRendererParams } from 'ag-grid-community'

import { useOwnerGroups } from '../../../hooks/useOwnerGroups'
import { lookupValue } from '../../../lib/Utils'

const OwnerGroupCellRenderer = (params: ICellRendererParams): JSX.Element => {
  const ownerGroups = useOwnerGroups(true)

  const ownerGroupMappings = ownerGroups?.reduce((acc, val) => {
    const result: { [key: string]: string } = acc
    result[val._id] = val.name
    return result
  }, {})

  return (
    <>
      <span>{ownerGroupMappings ? lookupValue(ownerGroupMappings, params.value) : ''}</span>
    </>
  )
}

export default OwnerGroupCellRenderer
