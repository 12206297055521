import { useEffect, useState } from 'react'
import {
  GridApi,
  GridOptions,
  GridReadyEvent,
  IDatasource,
  IGetRowsParams,
  RowSelectedEvent
} from '@ag-grid-community/core'
import axios from 'axios'
import queryString from 'query-string'

import config from '../../config'
import User from '../../lib/User'
import EmailRenderer from './EmailRenderer'
import CustomHeader from '../CustomHeader'
import RowData, { UserToRowData } from './RowData'
import useColumnDefs from './useColumnDefs'

const useUserList = ({
  qs,
  updateSelection,
  toggleRefresh
}: {
  qs: string
  updateSelection: (selected: User[]) => void
  toggleRefresh?: boolean
}): { gridOptions: GridOptions } => {
  const { columnDefs, defaultColDef } = useColumnDefs()
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)

  useEffect(() => {
    if (gridApi) {
      const updateData = async (): Promise<void> => {
        const ownerGroupsResponse = await axios.get(
          `${config[config.STAGE].endpoint}/api/v1/ownerGroups`,
          { params: { showAll: true } }
        )
        const ownerGroups = ownerGroupsResponse.data

        const dataSource: IDatasource = {
          rowCount: undefined,
          getRows: (params: IGetRowsParams) => {
            setTimeout(async () => {
              const query = queryString.parse(qs)
              const defaultSort =
                Object.keys(query).filter(key => {
                  return key.startsWith('options')
                }).length > 0
                  ? false
                  : true

              const usersResponse = await axios.get(
                `${config[config.STAGE].endpoint}/api/v1/users`,

                {
                  params: {
                    ...query,
                    options: {
                      limit: 100,
                      skip: params.startRow,
                      sort: defaultSort ? { createdAt: -1 } : undefined
                    }
                  }
                }
              )

              const users = usersResponse.data

              const rowsThisPage: RowData[] = users.map((user: User) =>
                UserToRowData(user, ownerGroups)
              )
              const lastRow = users.length < 100 ? params.startRow + users.length : -1

              params.successCallback(rowsThisPage, lastRow)
            }, 500)
          }
        }
        gridApi.setDatasource(dataSource)
      }

      updateData()
    }
  }, [gridApi, qs, toggleRefresh])

  const onGridReady = (params: GridReadyEvent): void => {
    setGridApi(params.api)
  }

  const onRowSelected = (params: RowSelectedEvent): void => {
    const selected = params.api.getSelectedRows()
    if (updateSelection) {
      updateSelection(selected)
    }
  }

  const gridOptions: GridOptions = {
    cacheOverflowSize: 5,
    enableCellTextSelection: true,
    columnDefs: columnDefs,
    defaultColDef: defaultColDef,
    frameworkComponents: { agColumnHeader: CustomHeader, EmailRenderer: EmailRenderer },
    infiniteInitialRowCount: -1,
    maxBlocksInCache: 10,
    maxConcurrentDatasourceRequests: 1,
    onGridReady: onGridReady,
    onRowSelected: onRowSelected,
    paginationPageSize: 100,
    rowBuffer: 0,
    rowModelType: 'infinite',
    rowMultiSelectWithClick: true,
    rowSelection: 'multiple'
  }

  return { gridOptions }
}

export default useUserList
