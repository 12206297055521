import React from 'react'

import { useTMEntriesPage } from '../../pages/TMEntries/useTMEntriesPage'

import './SelectAllHeader.css'

const SelectAllHeader: React.FC<any> = (props: any) => {
  const { state, dispatch } = useTMEntriesPage()

  return (
    <div className="ag-checkbox ag-input-field ag-header-select-all" role="presentation">
      <div
        className={
          state.indeterminate
            ? 'ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ag-indeterminate'
            : state.allChecked
            ? 'ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ag-checked'
            : 'ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper'
        }
      >
        <input
          id="select-all-checkbox"
          className="ag-input-field-input ag-checkbox-input"
          type="checkbox"
          checked={state.allChecked}
          onChange={(): void => {
            if (state.allChecked || state.indeterminate) {
              props.api.deselectAll()
              dispatch({ type: 'UPDATE', payload: { allChecked: false, indeterminate: false } })
            } else {
              props.api.selectAll()
              dispatch({ type: 'UPDATE', payload: { allChecked: true, indeterminate: false } })
            }
          }}
        />
      </div>
    </div>
  )
}

export default SelectAllHeader
