import React, { createRef, forwardRef, useImperativeHandle, useState } from 'react'
import Select, { SelectInstance } from 'react-select'
import { ICellEditorParams } from 'ag-grid-community'

type Params = ICellEditorParams & {
  values: { value: string; label: string }[]
}

const CategoryCellEditor = forwardRef(
  (params: Params, ref): JSX.Element => {
    const selectRef = createRef<SelectInstance>()

    const mappings = params.values.reduce((acc: { [key: string]: any }, val) => {
      const result = acc
      result[val.value] = val.label
      return result
    }, {})

    const [selected, setSelected] = useState({ value: params.value, label: mappings[params.value] })

    useImperativeHandle(ref, () => {
      return {
        getValue: (): string => {
          return selected.value
        }
      }
    })

    if (!params.data.editable) {
      return (
        <span className="ag-cell-value" style={{ textOverflow: 'hidden' }}>
          {params.formatValue(params.value)}
        </span>
      )
    }

    return (
      <Select
        options={params.values}
        onChange={(newValue): void => {
          setSelected(newValue as { value: string; label: string })
        }}
        value={selected}
        ref={selectRef}
        filterOption={({ label }, inputValue): boolean => {
          return label.toLowerCase().includes(inputValue.toLowerCase())
        }}
      />
    )
  }
)
CategoryCellEditor.displayName = 'CategoryCellEditor'

export default CategoryCellEditor
