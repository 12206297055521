import { LoginUser } from '../../hooks/useLoginUser'
import TM from '../../lib/TM'

export type RowData = {
  _id: string
  srcTmx: string
  tgtTmx: string
  srcLang: string
  tgtLang: string
  confidence: string
  ownerGroupId: string
  vendorId: string
  category: string
  tmName: string
  tmType: string
  active: boolean
  createdAt?: Date
  createdBy?: string
  updatedAt?: Date
  updatedBy?: string
  editable?: boolean
}

export const TMEntryToRowData = (tmEntry: TM, loginUser: LoginUser | null): RowData => {
  return {
    _id: tmEntry._id,
    srcTmx: tmEntry.srcTmx,
    tgtTmx: tmEntry.tgtTmx,
    srcLang: tmEntry.srcLang,
    tgtLang: tmEntry.tgtLang,
    confidence: tmEntry.confidence,
    ownerGroupId: tmEntry.ownerGroupId,
    vendorId: tmEntry.vendorId,
    category: tmEntry.properties.category,
    tmName: tmEntry.properties.tmName,
    tmType: tmEntry.properties.tmType,
    active: tmEntry.active,
    createdAt: tmEntry.createdAt,
    createdBy: tmEntry.createdBy,
    updatedAt: tmEntry.updatedAt,
    updatedBy: tmEntry.updatedBy,
    editable:
      loginUser !== undefined
        ? loginUser?.organizationType === 'cistate' ||
          tmEntry.ownerGroupId === loginUser?.organizationId ||
          loginUser?.organizationIds?.includes(tmEntry.ownerGroupId)
        : false
  }
}
