import React, { createContext, useState, useEffect } from 'react'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { HashRouter, Redirect, Route } from 'react-router-dom'
import Amplify, { Auth } from 'aws-amplify'
import { withAuthenticator } from 'aws-amplify-react'
import axios, { AxiosRequestConfig } from 'axios'
import Qs from 'qs'

import config from './config'

import { LoginUser, LoginUserProvider } from './hooks/useLoginUser'

import AppHeader from './components/AppHeader'
import Jobs from './pages/Jobs'
import ProjectDetail from './pages/ProjectDetail'
import Projects from './pages/Projects'
import TMEntries from './pages/TMEntries'
import Users from './pages/Admin/Users'
import Vendors from './pages/Admin/Vendors'

import enTranslation from './locales/en.json'
import jaTranslation from './locales/ja.json'

import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import 'react-datepicker/dist/react-datepicker.css'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: enTranslation
      },
      ja: {
        translation: jaTranslation
      }
    },
    lng: 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false
    },
    debug: false
  })

Amplify.configure({
  Auth: config[config.STAGE].Auth
})

export const CognitoUserContext = createContext(Amplify.Auth.user)

const isFile = (data: any): data is File =>
  data.size !== undefined && data.name !== undefined && data.type !== undefined

const isUploadToS3 = (config: AxiosRequestConfig): boolean =>
  config.method === 'put' && !!config.url && /\.s3\./.test(config.url) && isFile(config.data)

export const axiosRequestInterceptor = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const session = await Auth.currentSession()
  // s3へのPUTは Authorizationヘッダーを受け付けないらしい
  // https://stackoverflow.com/a/53031218
  if (isUploadToS3(config)) {
    delete config.headers.Authorization
    return config
  }

  if (session) {
    config.headers.Authorization = session.getIdToken().getJwtToken()
  }

  config.paramsSerializer = params => {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: true
    })
  }

  return config
}
axios.interceptors.request.use(axiosRequestInterceptor, e => Promise.reject(e))

const App: React.FC = () => {
  const [loginUser, setLoginUser] = useState<LoginUser | null>(null)

  useEffect(() => {
    const whoami = async () => {
      const loginUserResponse = await axios.get(`${config[config.STAGE].endpoint}/api/v1/whoami`)
      setLoginUser(loginUserResponse.data)
    }
    whoami()
  }, [])

  useEffect(() => {
    if (!localStorage.getItem('userLang')) {
      localStorage.setItem('userLang', i18n.language)
    } else {
      const userLangInLocalStorage = localStorage.getItem('userLang')
      if (userLangInLocalStorage === ('ja' || 'en')) {
        i18n.changeLanguage(userLangInLocalStorage)
      } else {
        localStorage.setItem('userLang', i18n.language)
      }
    }
  }, [])

  const cognitoUser = Amplify.Auth.user

  if (config.STAGE === 'development') {
    console.log(cognitoUser)
  }

  const signOut = (): void => {
    setLoginUser(null)
    Amplify.Auth.signOut()
  }

  return (
    <HashRouter>
      <CognitoUserContext.Provider value={cognitoUser}>
        <LoginUserProvider value={loginUser}>
          {loginUser && (
            <div className="App">
              <AppHeader signOut={signOut} />
              <Route exact path="/">
                {loginUser?.organizationType === 'vendor' && (
                  <Redirect to="/projects?archivedAt=null" />
                )}
                {(loginUser?.organizationType === 'cistate' ||
                  loginUser?.organizationType === 'owner-group') && (
                  <Redirect to="/jobs?archivedAt=null" />
                )}
              </Route>
              {loginUser?.organizationType !== 'vendor' && (
                <Route exact path="/jobs" component={Jobs} />
              )}
              <Route path="/projects/:id" component={ProjectDetail} />
              <Route exact path="/projects" component={Projects} />
              {loginUser?.organizationType !== 'vendor' && (
                <Route exact path="/tm-entries" component={TMEntries} />
              )}
              {loginUser?.role === 'admin' && <Route exact path="/admin/users" component={Users} />}
              {loginUser?.role === 'admin' && (
                <Route exact path="/admin/vendors" component={Vendors} />
              )}
            </div>
          )}
        </LoginUserProvider>
      </CognitoUserContext.Provider>
    </HashRouter>
  )
}

export default withAuthenticator(App)
