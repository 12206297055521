import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'

import { useJobsPage, State, Action, JobsPageContext } from './useJobsPage'
import Job from '../../lib/Job'
import Dialogs from './Dialogs'
import Toolbar from './Toolbar'
import JobList from '../../components/JobList'

type ContainerProps = RouteComponentProps & {
  className?: string
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  updateSelection: (selected: Job[]) => void
}

export const Component: React.FC<Props> = (props: Props) => {
  const { state, dispatch, updateSelection } = props
  const { t } = useTranslation()

  return (
    <JobsPageContext.Provider value={{ state, dispatch }}>
      <div className="Page">
        <Breadcrumb>
          <Breadcrumb.Item active data-testid="breadcrumb-job-list">
            {t('ジョブ一覧')}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Toolbar />
        <JobList
          showDownloadButtonColumn={true}
          showProjectInfoColumns={true}
          updateSelection={updateSelection}
          toggleRefresh={state.refreshJobList}
        />
        <Dialogs />
      </div>
    </JobsPageContext.Provider>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { state, dispatch } = useJobsPage()

  const updateSelection = (selected: Job[]): void => {
    dispatch({
      type: 'SET_SELECTED_JOBS',
      payload: { selectedJobs: selected }
    })
  }

  return (
    <Component {...props} state={state} dispatch={dispatch} updateSelection={updateSelection} />
  )
}

Container.displayName = 'Jobs'
export default Container
