import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import useAxios from 'axios-hooks'
import queryString from 'query-string'

import config from '../../../config'
import TM from '../../../lib/TM'
import { CenteredSpinner } from '../../common/CenteredSpinner'
import { OverlayTriggerButton } from '../../common/OverlayTriggerButton'
import { useLoginUser } from '../../../hooks/useLoginUser'

type Props = {
  allChecked: boolean
  excludeIds: string[]
  selectedTMEntries?: TM[]
  onClose: (updated: boolean) => void
}

const DeleteTMEntriesDialog: React.FC<Props> = (props: Props) => {
  const { allChecked, excludeIds, selectedTMEntries, onClose } = props
  const location = useLocation()
  const { t } = useTranslation()
  const loginUser = useLoginUser()

  const [completed, setCompleted] = useState(false)

  const ids = selectedTMEntries?.map(tmEntry => {
    return tmEntry._id
  })

  const query = queryString.parse(location.search)

  if (loginUser?.organizationType === 'owner-group') {
    if (!Object.keys(query).includes('ownerGroupId') && loginUser?.organizationIds) {
      query['ownerGroupId'] = loginUser?.organizationIds
    }
  }

  const baseUrl = config[config.STAGE].endpoint
  const [{ data, loading: loadingCount }] = useAxios<{ count: number }>(
    `${baseUrl}/api/v1/tmEntries/count?${queryString.stringify(query)}`
  )
  const [{ loading: loadingDeleteTMEntries }, deleteTMEntries] = useAxios(
    { url: `${baseUrl}/api/v1/tmEntries${location.search}`, method: 'DELETE' },
    { manual: true }
  )

  const loading = loadingCount || loadingDeleteTMEntries

  const handleSubmit = async (): Promise<void> => {
    if (allChecked) {
      await deleteTMEntries({ data: { excludeIds } })
    } else {
      await deleteTMEntries({ data: { ids } })
    }
    setCompleted(true)
  }

  if (completed) {
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>{t('TMエントリ削除')}</Modal.Header>
        <Modal.Body>
          <>
            <p>{t('TMエントリの削除を開始しました。削除が完了すると通知メールが送信されます。')}</p>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(): void => onClose(true)} disabled={loading}>
            {t('閉じる')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>{t('TMエントリ削除')}</Modal.Header>
      <Modal.Body>
        {loadingCount ? (
          <CenteredSpinner />
        ) : (
          <>
            <p>{t('選択したTMエントリを削除しますか？')}</p>
            <ul>
              <li>
                {t('削除するTMエントリの件数')}:{' '}
                {allChecked && data ? data?.count - excludeIds.length : selectedTMEntries?.length}
                {t('件')}
              </li>
            </ul>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <OverlayTriggerButton
          disabled={loading}
          isLoading={(): boolean => loadingDeleteTMEntries}
          isOverlayed={(): boolean => false}
          tooltipId=""
          tooltipLabel=""
          buttonLabel={t('OK')}
          variant="danger"
          onClick={handleSubmit}
        />
        <Button variant="secondary" onClick={(): void => onClose(false)} disabled={loading}>
          {t('キャンセル')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteTMEntriesDialog
