import React, { useState } from 'react'
import { ICellRendererParams } from 'ag-grid-community'

import EditVendorDialog from '../dialogs/EditVendorDialog'

const VendorNameRenderer = (params: ICellRendererParams): JSX.Element => {
  const [showEditVendorDialog, setShowEditVendorDialog] = useState(false)

  return (
    <>
      <span>
        <a
          href="#"
          onClick={(e: React.MouseEvent): void => {
            e.preventDefault()
            setShowEditVendorDialog(true)
          }}
        >
          {params.value}
        </a>
      </span>
      {showEditVendorDialog && (
        <EditVendorDialog
          close={(updated: boolean): void => {
            setShowEditVendorDialog(false)
            if (updated) {
              window.location.reload()
            }
          }}
          vendor={{
            _id: params.data._id,
            name: params.data.name,
            ownerGroupId: params.data.ownerGroupId,
            srcLang: params.data.srcLang,
            tgtLang: params.data.tgtLang,
            unitPrice: params.data.unitPrice,
            currency: params.data.currency,
            multipliers: {
              repetitions: params.data.multipliersRepetitions,
              exact: params.data.multipliersExact,
              over95: params.data.multipliersOver95,
              over85: params.data.multipliersOver85,
              over75: params.data.multipliersOver75,
              noMatch: params.data.multipliersNoMatch
            },
            isEnabled: params.data.isEnabled
          }}
        />
      )}
    </>
  )
}

export default VendorNameRenderer
