import React from 'react'
import { useTranslation } from 'react-i18next'

import Project from '../../../lib/Project'
import { OverlayTriggerButton } from '../../../components/common/OverlayTriggerButton'
import { useLoginUser } from '../../../hooks/useLoginUser'

type ContainerProps = {
  className?: string
  onClick: () => void
  projects: Project[]
}

type Props = ContainerProps & {
  canDeleteProjects: () => boolean
}

export const Component: React.FC<Props> = (props: Props) => {
  const { canDeleteProjects, onClick } = props
  const { t } = useTranslation()

  return (
    <OverlayTriggerButton
      buttonClassName={props.className}
      buttonLabel={t('束ね解除')}
      isOverlayed={(): boolean => {
        if (props.projects.length === 0 || canDeleteProjects() === false) {
          return true
        }
        return false
      }}
      onClick={onClick}
      tooltipId="cannot-delete-projects"
      tooltipLabel={
        props.projects.length === 0
          ? t('プロジェクトが選択されていません')
          : t('発注済みまたはキャンセル済みのプロジェクトが選択されています')
      }
      variant="danger"
      data-testid="delete-projects-button"
    />
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const loginUser = useLoginUser()

  const canDeleteProjects = (): boolean => {
    const approvedProjects = props.projects.filter(project => {
      return project.approvedAt ? true : false
    })
    const cancelledProjects = props.projects.filter(project => {
      return project.cancelledAt ? true : false
    })

    if (loginUser?.organizationType === 'cistate' && loginUser?.role === 'admin') {
      const filtered = props.projects.filter(project => {
        return project.mdmJobsCount && project.mdmJobsCount > 0
      })

      if (filtered.length > 0 && (approvedProjects.length > 0 || cancelledProjects.length > 0)) {
        return false
      } else {
        return true
      }
    }

    if (approvedProjects.length > 0 || cancelledProjects.length > 0) {
      return false
    }

    return true
  }

  return <Component {...props} canDeleteProjects={canDeleteProjects} />
}

Container.displayName = 'DeleteProjectsButton'
export default Container
