import { useCallback, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import axios, { AxiosResponse } from 'axios'

import config from '../../../config'
import Job from '../../../lib/Job'
import Project from '../../../lib/Project'
import { getAllJobs } from '../../../lib/Api'

export type State = {
  loading: boolean
  message: {
    isError: boolean
    text: string
  }
}

export type Action =
  | { type: 'SET_IS_LOADING'; payload: { loading: boolean } }
  | { type: 'SET_MESSAGE'; payload: { message: string; isError?: boolean } }

export const initialState: State = {
  loading: false,
  message: {
    isError: false,
    text: ''
  }
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return {
        ...state,
        loading: action.payload.loading
      }
    case 'SET_MESSAGE':
      return {
        ...state,
        message: {
          text: action.payload.message,
          isError: action.payload.isError ?? false
        }
      }
    default:
      return state
  }
}

const useDeleteProjectsDialog = (
  projects: Project[]
): {
  state: State
  dispatch: React.Dispatch<Action>
  handleSubmit: () => Promise<void>
} => {
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(stateReducer, initialState)

  const handleSubmit = useCallback(async () => {
    dispatch({ type: 'SET_IS_LOADING', payload: { loading: true } })
    dispatch({ type: 'SET_MESSAGE', payload: { message: '', isError: false } })
    await Promise.all(
      projects.map(
        async (project: Project): Promise<void> => {
          const jobs = await getAllJobs(project._id)
          dispatch({
            type: 'SET_MESSAGE',
            payload: { message: t('ジョブの束ねを解除しています') }
          })
          await Promise.all(
            jobs.map(
              async (job: Job): Promise<AxiosResponse> => {
                return await axios.patch(
                  `${config[config.STAGE].endpoint}/api/v1/jobs/${job._id}`,
                  {
                    projectId: null
                  }
                )
              }
            )
          )
          dispatch({
            type: 'SET_MESSAGE',
            payload: { message: t('プロジェクトを削除しています') }
          })
          return axios.delete(`${config[config.STAGE].endpoint}/api/v1/projects/${project._id}`)
        }
      )
    )
  }, [projects, t])

  return { state, dispatch, handleSubmit }
}

export default useDeleteProjectsDialog
