import useAxios from 'axios-hooks'

import config from '../config'
import { useLoginUser } from './useLoginUser'
import OwnerGroup from '../lib/OwnerGroup'

export const useOwnerGroups = (showAll?: boolean): OwnerGroup[] | null => {
  const loginUser = useLoginUser()

  const [ownerGroups] = useAxios({
    url: `${config[config.STAGE].endpoint}/api/v1/ownerGroups`,
    params: {
      showAll: loginUser?.organizationType !== 'vendor' && showAll
    }
  })

  return ownerGroups.data
}
