import React from 'react'
import { Button, ButtonToolbar, Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import ToggleSortOrderButton from '../../../components/ToggleSortOrderButton'
import { useTMEntriesPage } from '../useTMEntriesPage'
import { useLoginUser } from '../../../hooks/useLoginUser'
import { useToolbar } from './useToolbar'
import { OverlayTriggerButton } from '../../../components/common/OverlayTriggerButton'

const Toolbar: React.FC = () => {
  const {
    count,
    totalCount,
    sortBy,
    orderByAsc,
    onChangeSortBy,
    toggleOrderByAsc,
    conditionStrings
  } = useToolbar()
  const { state, dispatch } = useTMEntriesPage()
  const { t } = useTranslation()
  const loginUser = useLoginUser()

  return (
    <div>
      <ButtonToolbar className="ml-3 mr-3 mb-3">
        <Button
          onClick={(): void => dispatch({ type: 'UPDATE', payload: { showEditTMDialog: true } })}
        >
          {t('TMエントリ追加')}
        </Button>
        <Dropdown>
          <Dropdown.Toggle className="ml-2" id="import-and-export" variant="secondary">
            {t('インポート / エクスポート')}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={(): void =>
                dispatch({ type: 'UPDATE', payload: { showImportDialog: true } })
              }
            >
              {t('インポート')}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(): void =>
                dispatch({ type: 'UPDATE', payload: { showExportDialog: true } })
              }
            >
              {t('エクスポート')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {loginUser?.role === 'admin' && (
          <OverlayTriggerButton
            isOverlayed={(): boolean => state.selectedTMEntries.length === 0}
            buttonClassName="ml-2"
            buttonLabel={t('TMエントリ削除')}
            tooltipLabel={t('TMエントリが選択されていません')}
            tooltipId="no-tm-entries-selected-tooltip"
            variant="danger"
            onClick={(): void =>
              dispatch({ type: 'UPDATE', payload: { showDeleteTMEntriesDialog: true } })
            }
          />
        )}
        <Button className="ml-2" disabled variant="link">
          {t('インライン編集')}:
        </Button>
        <div className="toggle-switch ml-0 mr-auto" style={{ padding: 9 }}>
          <input
            id="toggle"
            className="toggle-input"
            type="checkbox"
            defaultChecked={false}
            onClick={(): void => dispatch({ type: 'TOGGLE_INLINE_EDIT' })}
          />
          <label htmlFor="toggle" className="toggle-label" />
        </div>
        <Button className="ml-2" disabled variant="link">
          {count} / {totalCount}
        </Button>
        <select
          className="ml-2"
          onChange={(e: React.FormEvent<HTMLSelectElement>): void =>
            onChangeSortBy(e.currentTarget.value)
          }
          value={sortBy ? sortBy : 'createdAt'}
        >
          <option value="createdAt">{t('作成日時')}</option>
          <option value="updatedAt">{t('更新日時')}</option>
          <option value="srcLang">{t('ソース言語')}</option>
          <option value="tgtLang">{t('ターゲット言語')}</option>
          <option value="confidence">{t('信頼度')}</option>
          <option value="properties.category">{t('カテゴリ')}</option>
          <option value="_fuzzyScore">{t('一致度')}</option>
        </select>
        <ToggleSortOrderButton
          onClick={toggleOrderByAsc}
          orderByAsc={sortBy === '_fuzzyScore' ? false : orderByAsc ? orderByAsc : false}
          disabled={sortBy === '_fuzzyScore'}
        />
      </ButtonToolbar>
      <div
        className="ml-3 mb-2"
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        }}
        title={conditionStrings}
      >
        {t('現在の絞り込み条件')}: {conditionStrings === '' ? t('なし') : conditionStrings}
      </div>
    </div>
  )
}

export default Toolbar
