import { ICellRendererParams } from 'ag-grid-community'
import React from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import Job from '../../lib/Job'
import config from '../../config'

const OpenEditorButtonRenderer = (params: ICellRendererParams): JSX.Element => {
  const job = params.data as Job & { unitsPerXliff: number }
  const editorUrl = config[config.STAGE].editorUrl
  if (!job) return <></>
  // unitsPerXliffが存在する(=翻訳キット生成済み)projectのみボタンを活性化する
  return (
    <Button
      href={`${editorUrl}/jobs/${job._id}/edit`}
      target="_blank"
      as="a"
      variant="link"
      disabled={!editorUrl || !job.unitsPerXliff}
    >
      <FontAwesomeIcon icon={faExternalLinkAlt} />
    </Button>
  )
}

export default OpenEditorButtonRenderer
