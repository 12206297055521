import React, { useCallback, useReducer } from 'react'
import axios from 'axios'

import config from '../../../config'
import Project from '../../../lib/Project'

type FormData = {
  quotedAmount: number
}

export type State = {
  loading: boolean
  message: {
    isError: boolean
    text: string
  }
  formData: FormData
}

export type Action =
  | { type: 'SET_IS_LOADING'; payload: { loading: boolean } }
  | { type: 'SET_MESSAGE'; payload: { message: string; isError?: boolean } }
  | { type: 'SET_FORM_VALUE'; payload: { quotedAmount: number } }

export const initialState: State = {
  loading: false,
  message: {
    isError: false,
    text: ''
  },
  formData: {
    quotedAmount: 0
  }
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_IS_LOADING': {
      return {
        ...state,
        loading: action.payload.loading
      }
    }
    case 'SET_MESSAGE': {
      return {
        ...state,
        message: {
          text: action.payload.message,
          isError: action.payload.isError ?? false
        }
      }
    }
    case 'SET_FORM_VALUE': {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload
        }
      }
    }
    default: {
      return state
    }
  }
}

export const useQuotedAmountDialog = (
  project: Project
): { state: State; dispatch: React.Dispatch<Action>; handleSubmit: () => Promise<void> } => {
  const [state, dispatch] = useReducer(stateReducer, initialState)

  const handleSubmit = useCallback(async () => {
    dispatch({ type: 'SET_IS_LOADING', payload: { loading: true } })
    dispatch({ type: 'SET_MESSAGE', payload: { message: '', isError: false } })

    await axios.patch(`${config[config.STAGE].endpoint}/api/v1/projects/${project._id}`, {
      quotedAmount: state.formData.quotedAmount,
      status: 'quote_ready'
    })
  }, [project, state])

  return { state, dispatch, handleSubmit }
}
