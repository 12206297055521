import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AllCommunityModules } from '@ag-grid-community/all-modules'
import { AgGridReact } from '@ag-grid-community/react'

import User from '../../lib/User'
import useUserList from './useUserList'

type Props = RouteComponentProps & {
  updateSelection: (selected: User[]) => void
  toggleRefresh?: boolean
}

const UserList: React.FC<Props> = (props: Props) => {
  const { updateSelection, toggleRefresh } = props
  const { search: qs } = props.location

  const { gridOptions } = useUserList({ qs, updateSelection, toggleRefresh })

  return (
    <div
      data-testid="user-list"
      className="ag-theme-balham"
      style={{ height: '100%', width: '100%' }}
    >
      <AgGridReact gridOptions={gridOptions} modules={AllCommunityModules} />
    </div>
  )
}

export default withRouter(UserList)
