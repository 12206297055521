import React, { useContext, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import useAxios from 'axios-hooks'

import config from '../../../../config'
import { ProjectDetailPageContext } from '../../useProjectDetailPage'
import { useLoginUser } from '../../../../hooks/useLoginUser'
import { OverlayTriggerButton } from '../../../../components/common/OverlayTriggerButton'
import Project from '../../../../lib/Project'

type ContainerProps = {
  project: Project
  hasOrderCancelled: () => boolean
  hasOrderApproved: () => boolean
}

type Props = ContainerProps & {
  objectExists: boolean
  downloadUrl: string
  downloadTranslationKit: () => Promise<void>
  showCreateTranslationKitDialog: () => void
  showUploadTranslatedXliffDialog: () => void
  showDownloadTranslatedFilesDialog: () => void
  showSendToMdmDialog: () => void
}

export const Component: React.FC<Props> = (props: Props) => {
  const {
    project,
    hasOrderApproved,
    hasOrderCancelled,
    objectExists,
    downloadTranslationKit,
    showCreateTranslationKitDialog,
    showUploadTranslatedXliffDialog,
    showDownloadTranslatedFilesDialog,
    showSendToMdmDialog
  } = props
  const { state } = useContext(ProjectDetailPageContext)
  const { t } = useTranslation()
  const loginUser = useLoginUser()

  return (
    <div className="ml-auto">
      <OverlayTriggerButton
        disabled={hasOrderCancelled() || !objectExists}
        tooltipLabel={t('翻訳キットを生成してください')}
        buttonLabel={t('翻訳キットをダウンロード')}
        isOverlayed={(): boolean => !objectExists}
        isLoading={(): boolean => state.downloadingTranslationKit}
        tooltipId="tooltip-cannot-download-translation-kit"
        variant="secondary"
        onClick={downloadTranslationKit}
        size="sm"
        data-testid="download-translation-kit-button"
      />
      <OverlayTriggerButton
        disabled={hasOrderCancelled()}
        tooltipLabel={
          state.canCreateTranslationKit === null ? t('ジョブがありません') : t('生成中です')
        }
        buttonLabel={t('翻訳キットを生成')}
        isOverlayed={(): boolean => !state.canCreateTranslationKit}
        isLoading={(): boolean =>
          state.canCreateTranslationKit !== null && !state.canCreateTranslationKit
        }
        tooltipId="tooltip-cannot-create-translation-kit"
        buttonClassName="ml-2"
        variant="secondary"
        onClick={showCreateTranslationKitDialog}
        size="sm"
        data-testid="create-translation-kit-button"
      />
      <Button
        disabled={hasOrderCancelled()}
        onClick={showUploadTranslatedXliffDialog}
        variant="secondary"
        className="ml-2"
        size="sm"
        style={hasOrderCancelled() ? { pointerEvents: 'none' } : {}}
        data-testid="upload-translated-xliff-button"
      >
        {t('翻訳済みXLIFFをアップロード')}
      </Button>
      <Button
        disabled={hasOrderCancelled()}
        onClick={showDownloadTranslatedFilesDialog}
        variant="secondary"
        className="ml-2"
        size="sm"
        style={hasOrderCancelled() ? { pointerEvents: 'none' } : {}}
        data-testid="download-translated-jobs-button"
      >
        {t('翻訳済みジョブをダウンロード')}
      </Button>
      {loginUser?.organizationType !== 'vendor' && (
        <OverlayTriggerButton
          buttonClassName="ml-2"
          tooltipLabel={
            hasOrderCancelled()
              ? t('キャンセル済みです')
              : project.status === 'completed'
              ? t('完了済みです')
              : !hasOrderApproved()
              ? t('発注承認がされていません')
              : ''
          }
          buttonLabel={t('ProjectDetail_Toolbar_完了')}
          isOverlayed={(): boolean =>
            !hasOrderApproved() || hasOrderCancelled() || project.status === 'completed'
          }
          isLoading={(): boolean => state.downloadingTranslationKit}
          tooltipId="tooltip-cannot-download-translation-kit"
          variant="secondary"
          onClick={showSendToMdmDialog}
          size="sm"
          data-testid="send-to-mdm-button"
        />
      )}
    </div>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { project } = props
  const { state, dispatch } = useContext(ProjectDetailPageContext)

  const [generateDownloadUrl, refetchDownloadUrl] = useAxios(
    {
      url: `${config[config.STAGE].endpoint}/api/v1/generateDownloadUrl`,
      data: {
        objectKey: `projects/${project.name}-${project._id}/${project.name}.zip`
      },
      method: 'POST'
    },
    { manual: true }
  )

  const objectExists = generateDownloadUrl.data?.exists
  const downloadUrl = generateDownloadUrl.data?.presignedUrl

  const downloadTranslationKit = async (): Promise<void> => {
    await refetchDownloadUrl()
    if (objectExists && downloadUrl) {
      dispatch({ type: 'SET_DOWNLOADING_TRANSLATION_KIT', payload: { downloading: true } })
      window.open(downloadUrl, '_blank')
      dispatch({ type: 'SET_DOWNLOADING_TRANSLATION_KIT', payload: { downloading: false } })
    }
  }

  const showCreateTranslationKitDialog = (): void => {
    dispatch({
      type: 'SET_SHOW_CREATE_TRANSLATION_KIT_DIALOG',
      payload: { show: true }
    })
  }

  const showUploadTranslatedXliffDialog = (): void => {
    dispatch({
      type: 'SET_SHOW_UPLOAD_TRANSLATED_XLIFF_DIALOG',
      payload: { show: true }
    })
  }

  const showDownloadTranslatedFilesDialog = (): void => {
    dispatch({
      type: 'SET_SHOW_DOWNLOAD_TRANSLATED_FILES_DIALOG',
      payload: { show: true }
    })
  }

  const showSendToMdmDialog = (): void => {
    dispatch({
      type: 'SET_SHOW_SEND_TO_MDM_DIALOG',
      payload: { show: true }
    })
  }

  useEffect(() => {
    refetchDownloadUrl()
  }, [state.canCreateTranslationKit, refetchDownloadUrl])

  return (
    <Component
      {...props}
      objectExists={objectExists}
      downloadUrl={downloadUrl}
      downloadTranslationKit={downloadTranslationKit}
      showCreateTranslationKitDialog={showCreateTranslationKitDialog}
      showUploadTranslatedXliffDialog={showUploadTranslatedXliffDialog}
      showDownloadTranslatedFilesDialog={showDownloadTranslatedFilesDialog}
      showSendToMdmDialog={showSendToMdmDialog}
    />
  )
}

Container.displayName = 'TranslationActions'
export default Container
