import { useCallback, useEffect, useReducer } from 'react'
import axios from 'axios'
import useAxios from 'axios-hooks'
import { useTranslation } from 'react-i18next'

import config from '../../../config'
import { useLoginUser } from '../../../hooks/useLoginUser'
import OwnerGroup from '../../../lib/OwnerGroup'
import TM from '../../../lib/TM'
import Vendor from '../../../lib/Vendor'
import CategoryMapping from '../../../lib/CategoryMapping'

type FormData = {
  srcTmx: string
  tgtTmx: string
  srcLang: string
  tgtLang: string
  confidence: string
  ownerGroupId: string | null
  vendorId: string | null
  properties: {
    category?: string
  }
  active: boolean
}

export type State = {
  loading: boolean
  message: {
    isError: boolean
    text: string
  }
  formData: FormData
  showConfirmationDialog: boolean
  ownerGroups: OwnerGroup[]
  vendors: Vendor[]
  categoryMappings: CategoryMapping[]
}

export type Action =
  | { type: 'SET_IS_LOADING'; payload: { loading: boolean } }
  | { type: 'SET_MESSAGE'; payload: { message: string; isError?: boolean } }
  | { type: 'SET_FORM_VALUE'; payload: Partial<FormData> }
  | { type: 'SET_SHOW_CONFIRMATION_DIALOG'; payload: { show: boolean } }
  | { type: 'OWNER_GROUPS_LOADED'; payload: { ownerGroups: OwnerGroup[]; tm?: TM } }
  | { type: 'VENDORS_LOADED'; payload: { vendors: Vendor[]; tm?: TM } }
  | { type: 'CATEGORY_MAPPINGS_LOADED'; payload: { categoryMappings: CategoryMapping[]; tm?: TM } }

export const initialState: State = {
  loading: false,
  message: {
    isError: false,
    text: ''
  },
  formData: {
    srcTmx: '',
    tgtTmx: '',
    srcLang: 'en-US',
    tgtLang: 'en-US',
    confidence: '100',
    ownerGroupId: '',
    vendorId: '',
    properties: {
      category: ''
    },
    active: true
  },
  showConfirmationDialog: false,
  ownerGroups: [],
  vendors: [],
  categoryMappings: []
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return {
        ...state,
        loading: action.payload.loading
      }
    case 'SET_MESSAGE':
      return {
        ...state,
        message: {
          text: action.payload.message,
          isError: action.payload.isError ?? false
        }
      }
    case 'SET_FORM_VALUE':
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload
        }
      }
    case 'SET_SHOW_CONFIRMATION_DIALOG':
      return {
        ...state,
        showConfirmationDialog: action.payload.show
      }
    case 'OWNER_GROUPS_LOADED': {
      const { ownerGroups, tm } = action.payload
      const ownerGroupId = tm ? tm.ownerGroupId : ownerGroups.length > 0 ? ownerGroups[0]._id : null
      return {
        ...state,
        loading: false,
        ownerGroups,
        formData: {
          ...state.formData,
          ownerGroupId
        }
      }
    }
    case 'VENDORS_LOADED': {
      const { vendors, tm } = action.payload
      const vendorId = tm ? tm.vendorId : ''
      return {
        ...state,
        loading: false,
        vendors,
        formData: {
          ...state.formData,
          vendorId
        }
      }
    }
    case 'CATEGORY_MAPPINGS_LOADED': {
      const { categoryMappings, tm } = action.payload
      const category = tm ? tm.properties.category : ''
      return {
        ...state,
        loading: false,
        categoryMappings,
        formData: {
          ...state.formData,
          properties: { category }
        }
      }
    }
    default:
      return state
  }
}

const useEditTMDialog = (
  tm?: TM
): {
  state: State
  dispatch: React.Dispatch<Action>
  handleSubmit: () => Promise<boolean>
} => {
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(stateReducer, initialState)
  const loginUser = useLoginUser()

  const [, fetchOwnerGroups] = useAxios<OwnerGroup[]>(
    `${config[config.STAGE].endpoint}/api/v1/ownerGroups`,
    { manual: true }
  )

  const [, fetchVendors] = useAxios<Vendor[]>(`${config[config.STAGE].endpoint}/api/v1/vendors`, {
    manual: true
  })

  const [, fetchCategoryMappings] = useAxios<CategoryMapping[]>(
    `${config[config.STAGE].endpoint}/api/v1/categoryMappings`,
    {
      manual: true
    }
  )

  useEffect(() => {
    if (tm) {
      const { srcTmx, tgtTmx, srcLang, tgtLang, confidence, ownerGroupId, vendorId, active } = tm
      const { category } = tm.properties
      dispatch({
        type: 'SET_FORM_VALUE',
        payload: {
          srcTmx,
          tgtTmx,
          srcLang,
          tgtLang,
          confidence,
          ownerGroupId,
          vendorId,
          properties: {
            category: category
          },
          active
        }
      })
    }
  }, [tm])

  useEffect(() => {
    // loginUserがcistateの場合、全owernGroupを設定する
    // loginUserがowner-groupの場合、自身のownerGroupを設定する
    const loadOwnerGroups = async (): Promise<void> => {
      if (!loginUser) {
        return
      }
      if (loginUser.organizationType !== 'cistate') {
        const ownerGroups: OwnerGroup[] = loginUser.organizations as OwnerGroup[]
        dispatch({ type: 'OWNER_GROUPS_LOADED', payload: { ownerGroups, tm } })
      } else {
        dispatch({ type: 'SET_IS_LOADING', payload: { loading: true } })
        const response = await fetchOwnerGroups()
        dispatch({ type: 'OWNER_GROUPS_LOADED', payload: { ownerGroups: response.data ?? [], tm } })
      }
    }
    loadOwnerGroups()
  }, [fetchOwnerGroups, loginUser, tm])

  useEffect(() => {
    const loadVendors = async (): Promise<void> => {
      if (!loginUser) {
        return
      }
      dispatch({ type: 'SET_IS_LOADING', payload: { loading: true } })
      const response = await fetchVendors()
      dispatch({ type: 'VENDORS_LOADED', payload: { vendors: response.data ?? [], tm } })
    }
    loadVendors()
  }, [fetchVendors, loginUser, tm])

  useEffect(() => {
    const loadCategoryMappings = async (): Promise<void> => {
      if (!loginUser) {
        return
      }
      dispatch({ type: 'SET_IS_LOADING', payload: { loading: true } })
      const response = await fetchCategoryMappings()
      dispatch({
        type: 'CATEGORY_MAPPINGS_LOADED',
        payload: { categoryMappings: response.data ?? [], tm }
      })
    }
    loadCategoryMappings()
  }, [fetchCategoryMappings, loginUser, tm])

  const handleSubmit = useCallback(async (): Promise<boolean> => {
    dispatch({ type: 'SET_IS_LOADING', payload: { loading: true } })
    dispatch({ type: 'SET_MESSAGE', payload: { message: '', isError: false } })

    const data = {
      ...state.formData,
      confidence: parseInt(state.formData.confidence)
    }

    if (tm) {
      dispatch({ type: 'SET_MESSAGE', payload: { message: t('TMエントリを更新しています') } })
      await axios.patch(`${config[config.STAGE].endpoint}/api/v1/tmEntries/${tm._id}`, data)
    } else {
      dispatch({ type: 'SET_MESSAGE', payload: { message: t('TMエントリを登録しています') } })
      await axios.post(`${config[config.STAGE].endpoint}/api/v1/tmEntries`, data)
    }
    return true
  }, [state.formData, tm, t])

  return {
    state,
    dispatch,
    handleSubmit
  }
}

export default useEditTMDialog
