import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AllCommunityModules } from '@ag-grid-community/all-modules'
import { AgGridReact } from '@ag-grid-community/react'

import useOwnerGroupList from './useOwnerGroupList'
import OwnerGroup from '../../lib/OwnerGroup'

type Props = RouteComponentProps & {
  options?: string
  selected?: string[]
  updateSelection?: (selected: OwnerGroup[]) => void
  visible?: boolean
}

const OwnerGroupList: React.FC<Props> = (props: Props) => {
  const { options, selected, updateSelection } = props
  const qs = props.location.search

  const { gridOptions } = useOwnerGroupList({
    qs,
    updateSelection,
    options,
    selected
  })

  return (
    <div
      className="ag-theme-balham"
      style={{
        height: '100%',
        width: '100%',
        display: props.visible === false ? 'none' : undefined
      }}
    >
      <AgGridReact gridOptions={gridOptions} modules={AllCommunityModules} />
    </div>
  )
}

export default withRouter(OwnerGroupList)
