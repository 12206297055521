import React from 'react'
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'

import { BackendError } from '../../../lib/BackendError'
import { OverlayTriggerButton } from '../../common/OverlayTriggerButton'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useExportDialog, State, Action } from './useExportDialog'

type ContainerProps = RouteComponentProps & {
  close: () => void
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  onKeyPress: (e: React.KeyboardEvent<HTMLFormElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
}

export const Component: React.FC<Props> = (props: Props) => {
  const { close: closeDialog, handleSubmit, onKeyPress, state, dispatch } = props
  const { t } = useTranslation()

  return (
    <Modal onHide={closeDialog} show={true} size="lg">
      <Form onKeyPress={onKeyPress} onSubmit={handleSubmit}>
        <Modal.Header>{t('エクスポート')}</Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} controlId="exportFormat">
            <Form.Label column sm={4}>
              {t('エクスポート形式')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: { exportFormat: e.currentTarget.value as 'xlsx' | 'tmx' }
                  })
                }
                value={state.formData.exportFormat}
                data-testid="select-export-format"
              >
                <option value="xlsx">XLSX</option>
                <option value="tmx">TMX</option>
              </Form.Control>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <span style={state.message.isError ? { color: 'red' } : undefined}>
            {state.message.text}
          </span>
          <OverlayTriggerButton
            type="submit"
            isOverlayed={(): boolean => state.loading}
            isLoading={(): boolean => state.loading}
            buttonLabel={t('OK')}
            tooltipLabel={t('エクスポートの開始中')}
            tooltipId="tooltip-starting-export"
            variant="primary"
            data-testid="submit-button"
            disabled={state.message.isError}
          />
          <Button
            disabled={state.loading}
            onClick={closeDialog}
            style={state.loading ? { pointerEvents: 'none' } : {}}
            variant="secondary"
          >
            {t('キャンセル')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { state, dispatch, handleSubmit } = useExportDialog(props.location.search)
  const { t } = useTranslation()

  const onKeyPress = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' /* Enter */) {
      e.preventDefault()
    }
  }

  const close = (): void => {
    if (!state.loading) {
      props.close()
    }
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    try {
      dispatch({ type: 'SET_IS_LOADING', payload: { loading: true } })
      dispatch({
        type: 'SET_MESSAGE',
        payload: { message: t('エクスポートの開始中') }
      })
      await handleSubmit()
      close()
    } catch (err) {
      const error = err as AxiosError<BackendError>
      if (error.response) {
        const errorInfo: BackendError = error.response.data
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: errorInfo.message, isError: true }
        })
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: error.message, isError: true }
        })
      }
    } finally {
      dispatch({ type: 'SET_IS_LOADING', payload: { loading: false } })
    }
  }

  return (
    <Component
      {...props}
      close={close}
      state={state}
      dispatch={dispatch}
      handleSubmit={onSubmit}
      onKeyPress={onKeyPress}
    />
  )
}

Container.displayName = 'ExportDialog'
export default withRouter(Container)
