import React, { ReactNode } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import useOKCancelDialog, { State, Action } from './useOKCancelDialog'
import { OverlayTriggerButton } from '../../common/OverlayTriggerButton'
import { BackendError } from '../../../lib/BackendError'

type ContainerProps = {
  body: ReactNode
  buttonVariant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'
    | 'link'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-success'
    | 'outline-danger'
    | 'outline-warning'
    | 'outline-info'
    | 'outline-dark'
    | 'outline-light'
  close: (updated: boolean) => void
  onClickOK: () => Promise<void>
  title: string
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  handleSubmit: () => Promise<void>
}

export const Component: React.FC<Props> = (props: Props) => {
  const { close, handleSubmit, state, title } = props
  const { t } = useTranslation()

  return (
    <Modal onHide={(): void => close(false)} show={true} size="lg">
      <Modal.Header closeButton>{t(title)}</Modal.Header>
      <Modal.Body>{props.body}</Modal.Body>
      <Modal.Footer>
        <span style={state.message.isError ? { color: 'red' } : undefined}>
          {state.message.text}
        </span>
        <OverlayTriggerButton
          isOverlayed={(): boolean => state.loading}
          isLoading={(): boolean => state.loading}
          buttonLabel={t('OK')}
          variant={props.buttonVariant ? props.buttonVariant : 'primary'}
          tooltipLabel={t('処理中')}
          tooltipId={'tooltip-ok-button'}
          onClick={handleSubmit}
          data-testid="ok-button"
        ></OverlayTriggerButton>
        <Button
          disabled={state.loading}
          onClick={(): void => props.close(false)}
          style={state.loading ? { pointerEvents: 'none' } : {}}
          variant="secondary"
        >
          {t('キャンセル')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { state, dispatch } = useOKCancelDialog()

  const close = (updated: boolean): void => {
    if (!state.loading) {
      props.close(updated)
    }
  }

  const handleSubmit = async (): Promise<void> => {
    dispatch({ type: 'SET_IS_LOADING', payload: { isLoading: true } })
    dispatch({ type: 'SET_MESSAGE', payload: { message: '', isError: false } })
    try {
      await props.onClickOK()
      close(true)
    } catch (error) {
      if (error.response) {
        const errorInfo: BackendError = error.response.data
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: errorInfo.message, isError: true }
        })
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: error.message, isError: true }
        })
      }
    } finally {
      dispatch({ type: 'SET_IS_LOADING', payload: { isLoading: false } })
    }
  }

  return (
    <Component
      {...props}
      close={close}
      state={state}
      dispatch={dispatch}
      handleSubmit={handleSubmit}
    />
  )
}

Container.displayName = 'OKCancelDialog'
export default Container
