import React from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import queryString from 'query-string'

import User from '../../../../lib/User'
import ToggleSortOrderButton from '../../../../components/ToggleSortOrderButton'
import useQueryString from '../../../../hooks/useQueryString'

type ContainerProps = RouteComponentProps & {
  selectedUsers: User[]
  showAddUserDialog: Function
}

type Props = ContainerProps & {
  onChangeSortBy: (value: string) => void
  toggleOrderByAsc: () => void
}

export const Component: React.FC<Props> = (props: Props) => {
  const { onChangeSortBy, toggleOrderByAsc } = props

  const { t } = useTranslation()
  const { sortBy, orderByAsc } = useQueryString(props.location.search)

  return (
    <div>
      <ButtonToolbar className="ml-3 mr-3 mb-3">
        <Button onClick={(): void => props.showAddUserDialog()} data-testid="add-user-button">
          {t('ユーザー追加')}
        </Button>
        <select
          className="ml-auto"
          onChange={(e: React.FormEvent<HTMLSelectElement>): void =>
            onChangeSortBy(e.currentTarget.value)
          }
          value={sortBy ? sortBy : 'createdAt'}
        >
          <option value="createdAt">{t('作成日時')}</option>
          <option value="updatedAt">{t('更新日時')}</option>
          <option value="email">{t('メールアドレス')}</option>
          <option value="username">{t('ユーザー名')}</option>
          <option value="organizationType">{t('所属先区分')}</option>
          <option value="organization.name">{t('所属先名')}</option>
        </select>
        <ToggleSortOrderButton
          onClick={toggleOrderByAsc}
          orderByAsc={orderByAsc ? orderByAsc : false}
        />
      </ButtonToolbar>
    </div>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { sortBy, orderByAsc } = useQueryString(props.location.search)

  const onChangeSortBy = (value: string): void => {
    const qs = props.location.search
    const queries = queryString.parse(qs)
    delete queries[`options[sort][${sortBy}]`]
    queries[`options[sort][${value}]`] = orderByAsc ? '1' : '-1'

    const options = queryString.stringify(queries)
    props.history.push('/admin/users?' + options)
  }

  const toggleOrderByAsc = (): void => {
    const qs = props.location.search
    const queries = queryString.parse(qs)
    queries[`options[sort][${sortBy ? sortBy : 'createdAt'}]`] = !orderByAsc ? '1' : '-1'

    const options = queryString.stringify(queries)
    props.history.push(`/admin/users?${options}`)
  }

  return (
    <Component {...props} onChangeSortBy={onChangeSortBy} toggleOrderByAsc={toggleOrderByAsc} />
  )
}

export default withRouter(Container)
