import axios from 'axios'
import config from '../config'
import Job from './Job'
import { sleep } from './Utils'

export enum ExecutionStatus {
  RUNNING = 'RUNNING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  TIMED_OUT = 'TIMED_OUT',
  ABORTED = 'ABORTED'
}

export const waitUntilAsyncRequestIsCompleted = async (executionArn: string): Promise<void> => {
  let status = ExecutionStatus.RUNNING
  while (status === ExecutionStatus.RUNNING) {
    const result = await axios.post(config[config.STAGE].endpoint + '/api/v1/async/status', {
      executionArn
    })
    if (!result || result.status !== 200 || !result.data) {
      status = ExecutionStatus.FAILED
      break
    }
    status = result.data.status
    if (status !== ExecutionStatus.RUNNING) {
      break
    }
    await sleep(500)
  }
}

export const waitUntilAllAsyncRequestsAreCompleted = async (
  executionArns: string[]
): Promise<void> => {
  for (const executionArn of executionArns) {
    await waitUntilAsyncRequestIsCompleted(executionArn)
  }
}

export const getAllJobs = async (
  projectId: string,
  queryParams: { [key: string]: unknown } = {}
): Promise<Job[]> => {
  delete queryParams.projectId
  delete queryParams.limit
  delete queryParams.offset
  let baseParamStr = Object.entries(queryParams)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')

  if (baseParamStr.length > 0) {
    baseParamStr += '&'
  }
  const limit = 100
  let skip = 0
  baseParamStr += `projectId=${projectId}&options[limit]=${limit}`
  const jobs = []
  for (;;) {
    const paramStr = `?${baseParamStr}&options[skip]=${skip}`
    const response = await axios.get<Job[]>(
      `${config[config.STAGE].endpoint}/api/v1/jobs${paramStr}`
    )
    if (response.data.length === 0) {
      break
    }
    jobs.push(...response.data)
    skip += limit
  }

  return jobs
}
