import React, { createContext, useReducer } from 'react'

import Job from '../../lib/Job'

export type State = {
  selectedJobs: Job[]
  showEditJobsDialog: boolean
  showJobFiltersDialog: boolean
  showRegisterProjectDialog: boolean
  showRegisterJobsDialog: boolean
  showDeleteJobsDialog: boolean
  refreshJobList: boolean
}

export type Action =
  | { type: 'SET_SELECTED_JOBS'; payload: { selectedJobs: Job[] } }
  | { type: 'SET_SHOW_EDIT_JOBS_DIALOG'; payload: { show: boolean } }
  | { type: 'SET_SHOW_JOB_FILTERS_DIALOG'; payload: { show: boolean } }
  | { type: 'SET_SHOW_REGISTER_PROJECT_DIALOG'; payload: { show: boolean } }
  | { type: 'SET_SHOW_REGISTER_JOBS_DIALOG'; payload: { show: boolean } }
  | { type: 'SET_SHOW_DELETE_JOBS_DIALOG'; payload: { show: boolean } }
  | { type: 'TOGGLE_REFRESH' }

export const initialState: State = {
  selectedJobs: [],
  showEditJobsDialog: false,
  showJobFiltersDialog: false,
  showRegisterProjectDialog: false,
  showRegisterJobsDialog: false,
  showDeleteJobsDialog: false,
  refreshJobList: true
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_SELECTED_JOBS': {
      return {
        ...state,
        selectedJobs: action.payload.selectedJobs
      }
    }
    case 'SET_SHOW_EDIT_JOBS_DIALOG': {
      return {
        ...state,
        showEditJobsDialog: action.payload.show
      }
    }
    case 'SET_SHOW_JOB_FILTERS_DIALOG': {
      return {
        ...state,
        showJobFiltersDialog: action.payload.show
      }
    }
    case 'SET_SHOW_REGISTER_PROJECT_DIALOG': {
      return {
        ...state,
        showRegisterProjectDialog: action.payload.show
      }
    }
    case 'SET_SHOW_REGISTER_JOBS_DIALOG': {
      return {
        ...state,
        showRegisterJobsDialog: action.payload.show
      }
    }
    case 'SET_SHOW_DELETE_JOBS_DIALOG': {
      return {
        ...state,
        showDeleteJobsDialog: action.payload.show
      }
    }
    case 'TOGGLE_REFRESH': {
      return {
        ...state,
        refreshJobList: !state.refreshJobList
      }
    }
    default: {
      return state
    }
  }
}

export const useJobsPage = (): {
  state: State
  dispatch: React.Dispatch<Action>
} => {
  const [state, dispatch] = useReducer(stateReducer, initialState)

  return { state, dispatch }
}

export const JobsPageContext = createContext(
  {} as { state: State; dispatch: React.Dispatch<Action> }
)
