import { ColDef } from '@ag-grid-community/all-modules'

import { EmptyCellFormatter } from '../../lib/ValueFormatters'

const useColumnDefs = (): { columnDefs: ColDef[]; defaultColDef: ColDef } => {
  const defaultColDef: ColDef = {
    flex: 1,
    lockVisible: true,
    minWidth: 100,
    resizable: true,
    sortable: false
  }

  const columnDefs: ColDef[] = [
    {
      headerName: '',
      field: '_id',
      checkboxSelection: true,
      maxWidth: 50,
      minWidth: 50,
      resizable: false,
      valueFormatter: EmptyCellFormatter,
      width: 50
    },
    {
      headerName: '名称',
      field: 'name'
    }
  ]

  return { columnDefs, defaultColDef }
}

export default useColumnDefs
