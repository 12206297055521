import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ColumnResizedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IDatasource,
  IGetRowsParams,
  RowSelectedEvent
} from '@ag-grid-community/core'
import axios from 'axios'
import queryString from 'query-string'

import config from '../../config'
import DownloadButtonRenderer from './DownloadButtonRenderer'
import OpenEditorButtonRenderer from './OpenEditorButtonRenderer'
import CustomHeader from '../CustomHeader'
import RowData, { JobToRowData } from './RowData'
import Job from '../../lib/Job'
import useColumnDefs from './useColumnDefs'

const useJobList = ({
  showCheckBoxes,
  showJobNameAsLink,
  showDownloadButtonColumn,
  showOpenEditorButtonColumn,
  showProjectInfoColumns,
  showUnitStatisticsColumns,
  qs,
  pathname,
  options,
  toggleRefresh,
  updateSelection
}: {
  showCheckBoxes: boolean
  showJobNameAsLink?: boolean
  showDownloadButtonColumn?: boolean
  showOpenEditorButtonColumn?: boolean
  showProjectInfoColumns?: boolean
  showUnitStatisticsColumns?: boolean
  qs: string
  pathname: string
  options?: string
  toggleRefresh?: boolean
  updateSelection?: (selected: Job[]) => void
}): { gridOptions: GridOptions } => {
  const { i18n } = useTranslation()

  const { columnDefs, defaultColDef } = useColumnDefs({
    showCheckBoxes,
    showDownloadButtonColumn,
    showOpenEditorButtonColumn,
    showProjectInfoColumns,
    showUnitStatisticsColumns
  })

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)

  useEffect(() => {
    if (gridApi) {
      const updateData = (): void => {
        const dataSource: IDatasource = {
          rowCount: undefined,
          getRows: (params: IGetRowsParams) => {
            setTimeout(async () => {
              const query = queryString.parse(qs)
              const defaultSort =
                Object.keys(query).filter(key => {
                  return key.startsWith('options')
                }).length > 0
                  ? false
                  : true

              const sort: {
                [key: string]: number
              } = {}
              if (defaultSort) {
                if (pathname.startsWith('/projects')) {
                  sort['properties.anken_id'] = -1
                } else {
                  sort['createdAt'] = -1
                }
              } else {
                const sortBy = Object.keys(query)
                  .filter(key => {
                    return key.startsWith('options[sort]')
                  })
                  .map(key => {
                    return key.replace(/options\[sort\]\[([^\]]+)\]/, '$1')
                  })
                sortBy.forEach(key => {
                  if (Object.keys(sort).includes(key)) {
                    const sortOrder = query[`options[sort][${key}]`]
                    if (typeof sortOrder === 'string') {
                      sort[key] = parseInt(sortOrder)
                    }
                  }
                })
              }

              if (options) {
                const parsed = queryString.parse(options)
                Object.keys(parsed).forEach(key => {
                  query[key] = parsed[key]
                })
              }

              const jobsResponse = await axios.get(`${config[config.STAGE].endpoint}/api/v1/jobs`, {
                params: {
                  ...query,
                  options: {
                    limit: 100,
                    skip: params.startRow,
                    sort: sort
                  }
                }
              })

              const jobs = jobsResponse.data

              const rowsThisPage: RowData[] = await Promise.all(
                jobs.map((job: Job) => JobToRowData(job, showJobNameAsLink))
              )
              const lastRow = jobs.length < 100 ? params.startRow + jobs.length : -1

              params.successCallback(rowsThisPage, lastRow)
            }, 500)
          }
        }
        gridApi.setDatasource(dataSource)
      }

      updateData()
    }
  }, [gridApi, options, qs, toggleRefresh, showJobNameAsLink, i18n.language, pathname])

  const onGridReady = (params: GridReadyEvent): void => {
    setGridApi(params.api)

    if (localStorage.getItem('jobListColumnWidths') !== null) {
      const columnWidthsString = localStorage.getItem('jobListColumnWidths')
      if (typeof columnWidthsString === 'string') {
        const columnWidths = JSON.parse(columnWidthsString)
        Object.keys(columnWidths).forEach(key => {
          params.columnApi.setColumnWidth(key, columnWidths[key])
        })
      }
    }
    if (process.env.REACT_APP_E2E) {
      params.api.sizeColumnsToFit()
    }
  }

  const onRowSelected = (params: RowSelectedEvent): void => {
    const selected = params.api.getSelectedRows()
    if (updateSelection) {
      updateSelection(selected)
    }
  }

  const onColumnResized = (params: ColumnResizedEvent): void => {
    if (localStorage.getItem('jobListColumnWidths') !== null) {
      const columnWidthsString = localStorage.getItem('jobListColumnWidths')
      if (typeof columnWidthsString === 'string') {
        const columnWidths = JSON.parse(columnWidthsString)
        if (columnWidths) {
          const key = params.column?.getColDef().colId
          if (key !== undefined) {
            columnWidths[key] = params.column?.getActualWidth()
            localStorage.setItem('jobListColumnWidths', JSON.stringify(columnWidths))
          }
        }
      }
    } else {
      const key = params.column?.getColDef().colId
      if (key !== undefined) {
        const columnWidths: Record<string, any> = {}
        columnWidths[key] = params.column?.getActualWidth()
        localStorage.setItem('jobListColumnWidths', JSON.stringify(columnWidths))
      }
    }
  }

  const gridOptions: GridOptions = {
    cacheOverflowSize: 5,
    enableCellTextSelection: true,
    columnDefs: columnDefs,
    defaultColDef: defaultColDef,
    frameworkComponents: {
      agColumnHeader: CustomHeader,
      DownloadButtonRenderer: DownloadButtonRenderer,
      OpenEditorButtonRenderer
    },
    infiniteInitialRowCount: -1,
    maxBlocksInCache: 10,
    maxConcurrentDatasourceRequests: 1,
    onColumnResized: onColumnResized,
    onGridReady: onGridReady,
    onRowSelected: onRowSelected,
    rowMultiSelectWithClick: true,
    paginationPageSize: 100,
    rowBuffer: 0,
    rowModelType: 'infinite',
    rowSelection: 'multiple'
  }

  return { gridOptions }
}

export default useJobList
