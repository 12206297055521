import React from 'react'
import { Button, Modal, Form, Row, Col, Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { BackendError } from '../../../lib/BackendError'
import Project from '../../../lib/Project'
import { OverlayTriggerButton } from '../../common/OverlayTriggerButton'
import useDeleteProjectsDialog, { State, Action } from './useDeleteProjectsDialog'

type ContainerProps = {
  close: (updated: boolean) => void
  projects: Project[]
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
}

export const Component: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { close, handleSubmit, projects, state } = props

  return (
    <Modal
      onHide={(): void => close(false)}
      show={true}
      size="lg"
      data-testid="delete-projects-dialog"
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>{t('束ね解除')}</Modal.Header>
        <Modal.Body>
          <Alert variant="danger">{t('以下のプロジェクトを削除します。よろしいですか？')}</Alert>
          <Form.Group as={Row} controlId="projects">
            <Form.Label column sm={4} data-testid="projects-list-label">
              {t('削除するプロジェクト')} ({projects.length}):
            </Form.Label>
            <Col sm={8}>
              <Form.Control readOnly as="select" multiple data-testid="projects-list">
                {projects.map(project => (
                  <option key={project._id}>{project.name}</option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {state.message.isError ? (
            <span style={{ color: 'red' }}>{state.message.text}</span>
          ) : (
            <span>{state.message.text}</span>
          )}
          <OverlayTriggerButton
            tooltipLabel={t('削除中')}
            buttonLabel={t('OK')}
            isOverlayed={(): boolean => state.loading}
            isLoading={(): boolean => state.loading}
            tooltipId="tooltip-deleting-project-in-progress"
            variant="danger"
            type="submit"
            data-testid="delete-projects-button"
          />
          <Button
            disabled={state.loading}
            onClick={(): void => props.close(false)}
            style={state.loading ? { pointerEvents: 'none' } : {}}
            variant="secondary"
          >
            {t('キャンセル')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { projects } = props
  const { state, dispatch, handleSubmit: deleteProjects } = useDeleteProjectsDialog(projects)

  const close = (updated: boolean): void => {
    if (!state.loading) {
      props.close(updated)
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    try {
      await deleteProjects()
      close(true)
    } catch (error) {
      if (error.response) {
        const errorInfo: BackendError = error.response.data
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: errorInfo.message, isError: true }
        })
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: error.message, isError: true }
        })
      }
    } finally {
      dispatch({ type: 'SET_IS_LOADING', payload: { loading: false } })
    }
  }

  return (
    <Component
      {...props}
      close={close}
      state={state}
      dispatch={dispatch}
      handleSubmit={handleSubmit}
    />
  )
}

Container.displayName = 'DeleteProjectsDialog'
export default Container
