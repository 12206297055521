import React from 'react'
import { ButtonToolbar } from 'react-bootstrap'

import Project from '../../../../lib/Project'

import JobActions from './JobActions'
import TranslationActions from './TranslationActions'

type ContainerProps = {
  project: Project
}

type Props = ContainerProps & {
  hasOrderApproved: () => boolean
  hasOrderCancelled: () => boolean
}

export const Component: React.FC<Props> = (props: Props) => {
  const { hasOrderApproved, hasOrderCancelled } = props

  return (
    <ButtonToolbar className="ml-3 mr-3 mb-3">
      <JobActions
        project={props.project}
        hasOrderApproved={hasOrderApproved}
        hasOrderCancelled={hasOrderCancelled}
      />
      <TranslationActions
        project={props.project}
        hasOrderApproved={hasOrderApproved}
        hasOrderCancelled={hasOrderCancelled}
      />
    </ButtonToolbar>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { project } = props

  const hasOrderCancelled = (): boolean => {
    if (project.cancelledAt || project.cancelledBy) {
      return true
    }
    return false
  }

  const hasOrderApproved = (): boolean => {
    if (project.approvedAt || project.approvedBy) {
      return true
    }
    return false
  }

  return (
    <Component
      {...props}
      hasOrderApproved={hasOrderApproved}
      hasOrderCancelled={hasOrderCancelled}
    />
  )
}

export default Container
